import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import * as viewActions from "../../../store/actions/view";
import { default as PickupPage } from './Pickup';

function mapStateToProps(state) {
    return {
        isLoadPage: state.view.isLoadPage,
        categories: state.settings.productCategories,
        pickupForm: state.settings.pickupForm,
    };
}

const mapDispatchToProps = {
    ...settingsActions,
    ...viewActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupPage);
