import produce from 'immer';

import { UPDATE_LOAD_MODAL_EDITION_CREATE } from '../constants/loading';

const initialState = {
    loadModalEdition: {
        create: false,
    }
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case UPDATE_LOAD_MODAL_EDITION_CREATE:
            return {
                ...draft,
                loadModalEdition: { ...draft.loadModalEdition, ...{ create: payload } }
            }

        default:
            break;
    }
}, initialState);
