import PropTypes from "prop-types";
import {
    NavLink,
    useRouteMatch
} from "react-router-dom";

import styles from "./scss/menuPage.module.scss";

const MenuGeneral = ({
    //props
    items = {},
}) => {
    let { path } = useRouteMatch();

    return (
        <div className={styles.wr_menu}>
            <ul className={styles.ul}>
                {
                    Object.keys(items).map((key, index) => (
                        <li key={index} className={styles.li}>
                            <NavLink
                                exact={(key === 'common') && true}
                                className={styles.a}
                                activeClassName={styles.active}
                                to={`${path}${items[key].path}`}
                            >
                                {
                                    items[key]?.icon && (
                                        <span className={styles.icon}>{items[key]?.icon}</span>
                                    )
                                }
                                {items[key].title}
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

MenuGeneral.prototype = {
    items: PropTypes.object,
}

export default MenuGeneral