import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { isArray } from "lodash";

import Table from "./Table";
import Loader from "components/ui-kit/Spiner";

import { statsDeliveries } from "constants/tables";
import { mappedDeliveries } from "utils/mappers/statistic";

const RenderHeader = (scheme) => {
  const HTMLelement = scheme.map((item, index) => {
    const { title, width } = item;

    return (
      <HeadCell key={index} style={{ width }}>
        {title}
      </HeadCell>
    );
  });

  return <HeadRow>
    <HeadCell style={{ width: "40px" }}></HeadCell>
    {HTMLelement}
  </HeadRow>;
};

const RenderCells = (item, scheme, onClick) => {
  let isOpen = isArray(item?.deliveries) && (item?.deliveries.length) > 0;
  const [isLoad, setLoad] = useState(false);

  const handlerOpen = (item) => {
    onClick(item, setLoad, isOpen);

    if (!isOpen)
      setLoad(true);
  }

  return (
    <>
      <Cell>
        {
          (item['orders_within_city'] || item['orders_outside_city']) && (
            <Btn onClick={() => handlerOpen(item)}>
              {isOpen ? "-" : "+"}
              {isLoad && <Loader />}
            </Btn>
          )
        }
      </Cell>
      {
        scheme.map((cell, index) => {
          if (cell.type === "list")
            return (
              <Cell key={index}>{RenderList(item[cell.key], cell.items)}</Cell>
            );
          else if (cell.type === "obj") {
            return <Cell key={index}>{item[cell.key]?.[cell?.item]}</Cell>;
          }
          else if (cell.type === "img") {
            return <Cell key={index}>
              <IMG src={item[cell.key]} />
            </Cell>;
          }
          else if (item[cell.key] || item[cell.key] == 0) {
            let text = item[cell.key];

            if (cell.key == 'status') {
              text = text ? "Актив" : "Не актив.";
            }

            return <Cell key={index}>{text}</Cell>;
          }

          else return <Cell key={index}></Cell>;
        })
      }
    </>
  )
}

const RenderBody = (data, scheme, onClick) => {

  const HTMLelement = data.map((item, index) => {
    return (
      <>
        <Row key={index} >
          {RenderCells(item, scheme, onClick)}
        </Row>
        {
          isArray(item?.deliveries) && (item?.deliveries.length) > 0 && (
            <tr>
              <Cell style={{ width: "40px", borderRight: 0, }}></Cell>
              <td colSpan="6">
                <Table data={mappedDeliveries(item.deliveries)} scheme={statsDeliveries} />
              </td>
            </tr>
          )
        }
      </>
    );
  });

  return HTMLelement;
};

const RenderList = (list, items) => {
  const HTMLelement = list.map((item, index) => {
    return (
      <Item key={index}>
        {item[items[0]]} {item[items[1]]}
      </Item>
    );
  });
  return <List>{HTMLelement}</List>;
};

const TableCouriers = ({
  scheme,
  data,
  onClick,
  isStictyhead = false
}) => {
  const theme = useTheme();

  return (
    <>
      {!!data && data.length ? (
        <Wrapper theme={theme}>
          <Thead isStictyhead={isStictyhead}>{RenderHeader(scheme)}</Thead>
          <Tbody>{RenderBody(data, scheme, onClick)}</Tbody>
        </Wrapper>
      ) : null}
    </>
  );
};

const Wrapper = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead`
  position: ${(p) => p.isStictyhead ? "sticky" : "inherit"};
  top: 49px;
  z-index: 5;
`;

const Tbody = styled.tbody``;

const HeadRow = styled.tr``;

const Row = styled.tr`
`;

const HeadCell = styled.th`
  padding: 3px;
  background-color: ${(p) => p.theme.lightAccentColor};
  border: 1px solid ${(p) => p.theme.lightAccentColor};
  color: ${(p) => p.theme.secondaryColor};
  font-weight: 400;
  text-align: left;
`;

const Cell = styled.td`
  padding: 5px;
  border: 1px solid ${(p) => p.theme.maskColor};
`;

const List = styled.ul``;

const Item = styled.li``;

const IMG = styled.img`
  max-height: 40px;
`;

const Btn = styled.button`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  color: #FFF;
  background-color: #EDA240;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  padding-bottom: 4px;
  cursor: pointer;
  transition: 0.4s;
  position: relative;

  &:hover {
    color: #000;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000036;
  }
`;

export default React.memo(TableCouriers);
