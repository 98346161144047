import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";

import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Select from "components/ui-kit/Select";

import { citiesMapped } from "utils/mappers/cities";
import { kitchensMapped } from "utils/mappers/kitchens";
import { saveData, removeItem, getData } from "utils/helpers/localStorage";
import { removeEmpty } from "utils/helpers/removeEmptyFromObj";
import { rangeConverDate, rangesDate } from "utils/helpers/date";
import { saleMetodMappedSelectRes } from "utils/mappers/restaurants";
import CalendarRange from "components/ui-kit/CalendarRange";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

const keyLocalStorageFilter = 'dataFilterCallCenter';

const EditorCallcenter = ({
    isOpen,
    kitchens,
    restaurants,
    cities,
    // mapDispatch
    closeModalFilter,
    getAllActiveOrders,
}) => {
    const theme = useTheme();

    const [selectCities, setSelectCities] = useState("");
    const [selectKitchen, setSelectKitchen] = useState("");
    const [selectRestaurant, setSelectRestaurant] = useState([{ label: "Всі", value: 'all' }])
    const [dateCal, setCalDate] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    }]);

    let citiesConvert = [...[{ title: "Всі", value: '' }], ...citiesMapped(cities)];
    let kitchensKovert = [...[{ title: "Всі", value: '' }], ...kitchensMapped(kitchens)];
    let restoransKovert = [...[{ label: "Всі", value: 'all' }], ...saleMetodMappedSelectRes(restaurants)];

    const handleCloseWindow = () => {
        closeModalFilter();
    }

    const updateRestaurant = (value) => {
        if (value[value.length - 1]?.value == 'all') {
            setSelectRestaurant([value[value.length - 1]]);
            return;
        }

        let indexAll = value.findIndex(el => (el.value == "all"));

        if (indexAll != -1) {
            value.splice(indexAll, 1);
        }

        setSelectRestaurant(value);
    }

    const clearState = () => {
        setSelectRestaurant([{ label: "Всі", value: 'all' }]);
        setSelectCities(citiesConvert[0].value);
        setSelectKitchen(kitchensKovert[0].value);
        setCalDate([{
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        }]);

        getAllActiveOrders({}, true);
    }

    const handleSubmit = () => {
        let { date_from, date_to } = rangeConverDate(dateCal);

        let code = '';

        if (selectRestaurant.length > 0) {
            if (selectRestaurant[0].value == 'all') {
                code = restaurants.map(el => el.code).join();
            }

            if (selectRestaurant[0].value !== 'all') {
                code = selectRestaurant.map(el => el.value).join();
            }
        }

        let params = {
            kitchen_code: selectKitchen,
            city_sync_id: selectCities,
            restaurant: code
        };

        params = removeEmpty(params);

        if (Object.keys({ ...params, ...{ dateCal: dateCal } }).length) {
            saveData(keyLocalStorageFilter, { ...params, code_list: selectRestaurant, ...{ dateCal: dateCal } });
        } else {
            handleSubmitRemove();
        }

        params = { ...params, date_from, date_to };

        getAllActiveOrders(params, true);
    }

    const handleSubmitRemove = () => {
        removeItem(keyLocalStorageFilter);
        clearState();
    }

    useEffect(() => {
        let dataFilterParamsLocalStorage = getData(keyLocalStorageFilter);

        if (dataFilterParamsLocalStorage?.code_list) {
            setSelectRestaurant(dataFilterParamsLocalStorage?.code_list);
        }

        if (dataFilterParamsLocalStorage?.city_sync_id) {
            setSelectCities(dataFilterParamsLocalStorage?.city_sync_id);
        }

        if (dataFilterParamsLocalStorage?.kitchen_code) {
            setSelectKitchen(dataFilterParamsLocalStorage?.kitchen_code);
        }

        if (dataFilterParamsLocalStorage?.dateCal) {
            setCalDate(rangesDate(dataFilterParamsLocalStorage.dateCal));
        }
    }, []);

    return (
        <>
            {
                isOpen && (
                    <BgModal onClick={handleCloseWindow} />
                )
            }

            <Wrapper theme={theme} isOpen={isOpen}>
                <Header>
                    <div style={{ color: "#fff" }}>Фільтр</div>
                    <CloseButton onClick={handleCloseWindow}>
                        <CloseIcon style={{ fontSize: 36 }} />
                    </CloseButton>
                </Header>

                <Block>
                    <WrIn>
                        <Select
                            selectOptions={restoransKovert}
                            selectedOption={selectRestaurant}
                            valueKey='restaurant'
                            label='Ресторан'
                            isMulti
                            onChange={({ value }) => updateRestaurant(value)}
                        />
                    </WrIn>
                    <WrIn>
                        <Dropdown
                            title='Місто'
                            type='text'
                            onChange={(e) => setSelectCities(e.target.value)}
                            value={selectCities}
                            list={citiesConvert}
                        />
                    </WrIn>
                    <WrIn>
                        <Dropdown
                            title='Кухня'
                            type='text'
                            onChange={(e) => setSelectKitchen(e.target.value)}
                            value={selectKitchen}
                            list={kitchensKovert}
                        />
                    </WrIn>
                    <CalendarRange
                        title="Період з - по"
                        valueIn={dateCal}
                        ranges={dateCal}
                        onChangeCal={(date) => setCalDate([date.selection])}
                    />
                </Block>

                <Menu>
                    <Item>
                        <ButtonIcon
                            onClick={handleSubmit}
                            title='Застосувати'
                            icon={<SaveIcon />}
                        />
                    </Item>
                    <Item>
                        <ButtonIcon
                            onClick={handleSubmitRemove}
                            title='Скинути'
                            icon={<CloseIcon />}
                        />
                    </Item>
                </Menu>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
  position: fixed;
  right: ${(p) => (p.isOpen ? "0" : "-1000px")};
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  padding-bottom: 30px;
  background-color: ${(p) => p.theme.background};
  transition: 200ms right;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px 20px;
  border-bottom: 1px solid ${(p) => p.theme.secondaryColor};
  margin-bottom: 15px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 15px;
  margin-bottom: 40px;
  
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrIn = styled.div`
  &:not(:first-child) {
      margin-top: 15px;
  }
`;

const Menu = styled.ul`
  position: fixed;
  bottom: 0;
  display: flex;
`;

const Item = styled.li`
  margin: 0 20px 15px 0;
  &:last-child {
    margin-right: 0;
  }
`;

const BgModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
`;


export default EditorCallcenter;
