export const Pagination = ({ colPage = 1, active = 1, onClick = () => { } }) => {
    return (
        <div className="wr-pagin">
            {
                [...new Array(colPage)].map((item, index) => (
                    <div key={index}>
                        <button className={`btn-pages ${(active == index + 1) ? "active" : ""}`} onClick={() => { onClick(index + 1) }}>{index + 1}</button>
                    </div>
                ))
            }
        </div>
    )
}