import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { isArray } from "lodash";

import {
  removeOrderItem,
  increaseOrderItem,
  decreaseOrderItem,
  updateOrderItemComment,
  updateDopAdd,
  removeDopAll,
  removeDopItem,
  searchDopCard,
  oneAddDop,
  oneSubtractDop,
  updateOrderData,
  updateCutlery,
  removeCutleryItem
} from "store/actions/order";

import { TabProducts } from "./TabProducts";
import { IconButton } from "components/ui-kit/buttons/IconButton";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import Radio from "components/ui-kit/Radio";
import ModalDelete from "components/modals/ModalDelete";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import { TextInput } from "components/ui-kit/inputs/TextInput";

import { priceOrCity, searchPriceTypeDelivery } from "utils/helpers/price";
import { deliveresMapped } from "utils/mappers/delivery";
import { calculateSumItems, searchPaymentCodeAndReturn } from "utils/helpers/payment";

import { defaultPriceActionsOrder } from "constants/price";
import { RADIO_SCHEME_IGNORE_STOCK, BLACK_LIST } from "constants/schemes";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { removeCutleryCard } from "utils/helpers/cutlery";

const RenderDops = ({
  idCard = 0,
  dopsList = [],
  activeDopsList = [],
  indexProduct = 0,
  isAdmin = false,
  numberQuantity = 1,
}) => {
  const dispatch = useDispatch();
  const orderState = useSelector(state => state.order.data);
  const city = useSelector((state) => state.order.data.address.city_sync_id);

  const [isOpenDop, setIsOpenDop] = useState(false);
  const [isRadion, setIsRadion] = useState(-1);

  const handlerAddDop = (type, dop) => {
    let price = null;

    price = priceOrCity(orderState.address.city_sync_id, dop.prices);

    if (price !== null) {
      if (type === 'radio') {
        if (isRadion === -1) {
          dispatch(updateDopAdd(indexProduct, dop.id, dop.name, price, type, numberQuantity));

          setIsRadion(dop.id);
        }
        return;
      }

      dispatch(updateDopAdd(indexProduct, dop.id, dop.name, price, type, numberQuantity));
    }
  }

  const handlerRemoveDopItem = (item) => {

    setIsRadion((prev) => {
      return prev === item.addition_id ? -1 : prev;
    });

    dispatch(removeDopItem(indexProduct, item.addition_id, item.price))
  }

  const handlerDeleteAllDop = () => {
    dispatch(removeDopAll(indexProduct));
    setIsRadion(-1);
  }

  const handlerOpenDops = () => {
    setIsOpenDop(prev => !prev);

    if (orderState.status !== "draft") {
      dispatch(searchDopCard(idCard));
    }
  }

  const addDop = (indexProduct, indexDop) => {
    dispatch(oneAddDop(indexProduct, indexDop, numberQuantity));
  }

  const subtractDop = (indexProduct, indexDop) => {
    dispatch(oneSubtractDop(indexProduct, indexDop, numberQuantity));
  }

  return (
    <QuantityDop>
      {
        !isAdmin && isArray(dopsList) && (activeDopsList.length > 0) && (
          <WrDop>
            <TitleDop>Допи: </TitleDop>
          </WrDop>
        )
      }
      {
        isAdmin && isArray(activeDopsList) && (activeDopsList?.length > 0) && (
          <WrDop>
            <TitleDop>Допи: </TitleDop>
          </WrDop>
        )
      }
      {
        isArray(activeDopsList) && (activeDopsList?.length > 0) && (
          <UlDops>
            <RowDopHead>
              <DopName isAdmin={isAdmin}>Назва</DopName>
              <DopCol isAdmin={isAdmin}>Кіл.</DopCol>
              {
                isArray(activeDopsList) && !isAdmin && (activeDopsList.length > 0) && (
                  <DopDelete>
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={() => handlerDeleteAllDop()}
                    />
                  </DopDelete>
                )
              }
            </RowDopHead>
            {
              isArray(activeDopsList) && (activeDopsList.length > 0) && activeDopsList.map((item, index) => (
                <RowDop key={index}>
                  <DopName isAdmin={isAdmin} title={item?.addition?.name}>{`${item?.addition?.name} - Ціна: ${(item.price * item.quantity) * numberQuantity} грн.`}</DopName>
                  <DopCol isAdmin={isAdmin}>
                    {
                      !isAdmin && (
                        <IconButton
                          icon={<RemoveIcon />}
                          onClick={() => subtractDop(indexProduct, index)}
                          disabled={1 >= item.quantity}
                        />
                      )
                    }
                    {item.quantity}
                    {
                      !isAdmin && (
                        <IconButton
                          icon={<AddIcon />}
                          onClick={() => addDop(indexProduct, index)}
                          disabled={orderState?.id ? item?.addition_group?.[0]?.type === "radio" : item?.type === "radio"}
                        />
                      )
                    }
                  </DopCol>
                  {
                    !isAdmin && (
                      <DopDelete>
                        <IconButton
                          icon={<DeleteIcon />}
                          onClick={() => handlerRemoveDopItem(item)}
                        />
                      </DopDelete>
                    )
                  }
                </RowDop>
              ))
            }
          </UlDops>
        )
      }

      {
        !isAdmin && (isArray(dopsList) && (dopsList.length > 0)) && (
          <WrDopBot>
            <TitleDopBtn>Додати доп: </TitleDopBtn>
            <IconButton
              icon={<AddIcon />}
              onClick={() => handlerOpenDops()}
            />
          </WrDopBot>
        )
      }

      {
        isOpenDop && !isAdmin && (
          <>
            <OpasDops onClick={() => setIsOpenDop(prev => !prev)} />
            <Dops>
              <DopsHead>
                <span>Доступні допи: </span>
                <CloseButton onClick={() => setIsOpenDop(prev => !prev)}>
                  <CloseIcon style={{ fontSize: 28 }} />
                </CloseButton>
              </DopsHead>
              <Oldops>
                {
                  isArray(dopsList) && dopsList.map((itemDop, index) => {
                    return (
                      <div key={index}>
                        <ItemOld>
                          <span style={{ color: '#EDA240', fontSize: 14, paddingBottom: 10 }}>{itemDop.name}:</span>
                        </ItemOld>
                        {
                          isArray(itemDop.additions) && itemDop.additions.map((dop, index) => {
                            const { price } = dop.prices ? dop.prices.find((el) => el.city_sync_id === city) : dop;

                            return (
                              <ItemOld key={index}>
                                <SpanTitleDop>{`${dop.name} - ціна: ${price} грн.`}</SpanTitleDop>
                                <IconButton
                                  icon={<AddIcon />}
                                  disabled={(itemDop.type === 'radio') && (isRadion != -1)}
                                  onClick={() => handlerAddDop(itemDop.type, dop)}
                                />
                              </ItemOld>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </Oldops>
            </Dops></>
        )
      }
    </QuantityDop>
  )
}

const RenderItemList = ({
  products,
  city,
  order,
  isAdmin,
  onOpenDeleteModal,
  typeListDeliver,
}) => {
  const [indexOpenMain, setIndexOpenMain] = useState({
    index: undefined,
    id: undefined
  });

  return products.map((item, index) => {
    let element = item.prices ? item.prices.find((el) => el.city_sync_id === city) : item;
    let price = element?.price || 0;
    let priceDops = 0;
    let pricePeint = 0;

    if (isArray(item?.additions)) {
      item?.additions.map((add) => {
        priceDops += (add.price * add.quantity);
      });

      priceDops = priceDops * item.quantity;
    }

    price = searchPriceTypeDelivery(order, element?.delivery_type_prices, element, typeListDeliver);

    if (item.quantity > 1) {
      if (item.stock >= 1) {
        pricePeint = (+price * (item.quantity - item.stock)) + (defaultPriceActionsOrder * item.stock);
      } else {
        pricePeint = parseInt(price * item.quantity);
      }
    } else {
      pricePeint = parseInt(price * item.quantity);
    }

    return (
      <RenderItem
        key={index}
        index={index}
        id={item.id}
        item={item}
        isAdmin={isAdmin}
        order={order}
        price={price}
        pricePeint={pricePeint}
        priceDops={priceDops}
        onOpenDeleteModal={onOpenDeleteModal}
        indexOpenMain={indexOpenMain}
        setIndexOpenMain={setIndexOpenMain}
        products={products}
      />
    );
  });
};

const RenderItem = ({
  index,
  id,
  item,
  isAdmin,
  order,
  price,
  pricePeint,
  priceDops,
  onOpenDeleteModal,
  indexOpenMain,
  setIndexOpenMain,
  products
}) => {
  const dispatch = useDispatch();

  const [isOpenComment, setIsOpenComment] = useState(false);
  const [isOpenMain, setIsOpenMain] = useState(false);

  useEffect(() => {
    setIsOpenComment(!!item.comment);

    setIsOpenMain((prev) => {
      let status = false;

      if (index == 0) {
        status = true;
      } else {
        if (indexOpenMain.id == id && indexOpenMain.index == index) {
          status = true;
        }
      }

      return status;
    });
  }, [indexOpenMain, products]);

  const hadlOpenMain = () => {
    setIndexOpenMain((prev) => {
      if (prev.id == id)
        return { index: undefined, id: undefined };

      return { index, id };
    });
  }

  return (
    <Item isGift={item?.is_gift} isStrock={item?.stock || false}>
      {
        index != 0 && (
          <RowBtnClos onClick={hadlOpenMain}>
            {
              (indexOpenMain.id == id) && (indexOpenMain.index == index) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
            }
          </RowBtnClos>
        )
      }

      <Roew>
        <Title>{order.id ? item?.product?.title_ua ? item?.product?.title_ua : item?.title : item?.title || item?.title_ua}</Title>
        <RowArt>
          {
            !isAdmin && (<Text><span>Ціна:</span> {price} грн.</Text>)
          }
          {
            !isAdmin && (<Text><span>Арт:</span> {order.id ? item?.product?.article ? item?.product?.article : item?.article : item?.article}</Text>)
          }
        </RowArt>
        {
          !isAdmin && (
            <ButtonHolder>
              <IconButton
                icon={<DeleteIcon />}
                onClick={() => onOpenDeleteModal(item)}
              />
            </ButtonHolder>
          )
        }

        {/* {
          !isAdmin && !item.prices && (
            <Warning>
              <WarningIcon />
            </Warning>
          )
        } */}

        {
          isOpenMain && (
            <MainWrap>
              <Quantity>
                <ButtonBar>
                  <div>Кількість:</div>
                  {
                    !isAdmin && (
                      <IconButton
                        icon={<RemoveIcon />}
                        disabled={(isArray(item?.additions) && item.additions.length > 0)}
                        onClick={() => dispatch(decreaseOrderItem(index, item.quantity, item))}
                      />
                    )
                  }
                  <div> {item.quantity} шт.</div>
                  {
                    !isAdmin && (
                      <IconButton
                        icon={<AddIcon />}
                        disabled={(isArray(item?.additions) && item.additions.length > 0)}
                        onClick={() => dispatch(increaseOrderItem(index, item.quantity, item))}
                      />
                    )
                  }
                </ButtonBar>
                <div>Всього: {pricePeint + priceDops} грн.</div>
              </Quantity>

              {
                (item.quantity == 1) && (
                  <RenderDops
                    idCard={item.id}
                    activeDopsList={item?.additions}
                    dopsList={item?.addition_groups}
                    indexProduct={index}
                    isAdmin={isAdmin}
                    numberQuantity={item.quantity}
                  />
                )
              }

              {
                (isArray(item?.addition_groups) && item?.addition_groups.length > 0) && (item.quantity > 1) && (
                  <ErMin>Якщо кількість більше ніж 1 то блокуються допи</ErMin>
                )
              }

              {
                isArray(item?.additions) && (item.additions.length > 0) && (
                  <ErMin>Якщо задіяні допи то блокується додавання кількості</ErMin>
                )
              }

              <RowCom>
                {
                  !isAdmin ? "Коментар" : !!item.comment && "Коментар"
                }
                {
                  !isAdmin && (
                    !!!item.comment && (
                      <div>
                        <button onClick={() => setIsOpenComment(prev => !prev)}>{isOpenComment ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} </button>
                      </div>
                    )
                  )
                }
              </RowCom>

              {
                isOpenComment && (
                  <TextArea
                    onChange={(e) =>
                      dispatch(updateOrderItemComment(index, e.target.value))
                    }
                    value={item.comment}
                  />
                )
              }
            </MainWrap>
          )
        }
      </Roew>
    </Item>
  )
}

const CutleryItem = ({ data, index }) => {
  const dispatch = useDispatch();

  return (
    <Item>
      <Roew>
        <RowArtJus>
          <Title>{data.name}</Title>
          <RowArt>
            {
              data.price && (<Text><span>Ціна:</span> {data.price} грн.</Text>)
            }
            <Text><span>Арт: </span>{data.code}</Text>
          </RowArt>
          <ButtonHolder>
            <IconButton
              icon={<DeleteIcon />}
              onClick={() => dispatch(removeCutleryItem(index))}
            />
          </ButtonHolder>
        </RowArtJus>
        <RowBordTop>
          <Radio
            colors={"#ffffff"}
            items={BLACK_LIST}
            label="Потрібні столові прибори ? "
            valueKey="status"
            value={data.status || 1}
            onChange={(e) => {
              dispatch(updateCutlery(data.cutlery_set_id, "status", e.value.value));
            }}
          />
        </RowBordTop>
        {
          (data.status == 1) && (
            <RowArtJusNe>
              <Quantity>
                <ButtonBar>
                  <div>Кількість:</div>
                  <IconButton
                    icon={<RemoveIcon />}
                    onClick={() => dispatch(updateCutlery(data.cutlery_set_id, "quantity", data.quantity - 1))}
                    disabled={data.quantity <= 1}
                  />
                  <div className="int">
                    <TextInput
                      width="68px"
                      type="number"
                      min={1}
                      max={100}
                      value={data.quantity}
                      onChange={(e) => {
                        dispatch(updateCutlery(data.cutlery_set_id, "quantity", e.target.value));
                      }}
                    />
                  </div>
                  <IconButton
                    icon={<AddIcon />}
                    onClick={() => dispatch(updateCutlery(data.cutlery_set_id, "quantity", data.quantity + 1))}
                    disabled={data.quantity >= 100}
                  />
                </ButtonBar>
                {
                  data.price && (
                    <div>Всього: {data.quantity * data.price} грн.</div>
                  )
                }
              </Quantity>
            </RowArtJusNe>
          )
        }
      </Roew>
    </Item>
  )
}

const Cutlery = ({ data }) => {
  return (
    <WrCutlery>
      <TitleCutlery>Столові прибори</TitleCutlery>

      <ListCutlery>
        {
          data.map((item, index) => (
            <CutleryItem
              index={index}
              key={index}
              data={item}
            />
          ))
        }
      </ListCutlery>
    </WrCutlery>
  )
}

export const TabOrder = ({
  isEdit,
  isAdmin = false
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isOpenModalDelete, setIsOpenModalDelete] = useState({
    isOpen: false,
    id: undefined,
  })

  const order = useSelector((state) => state.order.data);
  const products = useSelector((state) => state.order.data.items);
  const city = useSelector((state) => state.order.data.address.city_sync_id);
  const typeListDeliver = useSelector((store) => store.settings.typesOfDelivery.list);

  const {
    settings: {
      typesOfDelivery
    }
  } = useSelector(state => state);

  useEffect(() => {
    removeCutleryCard(order, dispatch);
  }, [order.items]);

  const onOpenDeleteModal = (item) => {
    setIsOpenModalDelete({
      isOpen: true,
      item
    });
  }

  const clearStateDeleteModal = () => {
    setIsOpenModalDelete({
      isOpen: false,
      item: undefined,
    });
  }

  const onCloseModal = () => {
    clearStateDeleteModal();
  }

  const onDeleteItem = () => {
    dispatch(removeOrderItem(isOpenModalDelete.item));
    onCloseModal();
  }

  return (
    <Wrapper theme={theme} isEdit={isEdit}>
      <Wr>
        <Radio
          colors={"#ffffff"}
          items={RADIO_SCHEME_IGNORE_STOCK}
          label="Ігнорувати акції ?"
          valueKey="status"
          value={order?.ignore_stock}
          disabled={!isEdit}
          onChange={(e) => {
            dispatch(updateOrderData(
              "ignore_stock",
              e.value.value,
              true
            ));
          }}
        />
        <WrSel>
          <Dropdown
            title="Тип доставки"
            width="185px"
            onChange={(e) => {
              dispatch(updateOrderData("delivery_type_id", e.target.value));
              dispatch(updateOrderData("payments", [searchPaymentCodeAndReturn(e.target.value, typesOfDelivery.list)]));
            }}
            value={order.delivery_type_id}
            list={deliveresMapped(typesOfDelivery.list)}
            disabled={!isEdit}
          />

          <TextInput
            width="100px"
            title="Персон"
            type="number"
            disabled={!isEdit}
            onChange={(e) => {
              dispatch(updateOrderData("persons", e.target.value));
            }}
            value={order.persons}
          />
        </WrSel>
      </Wr>

      <WrPrices>Загальна сума: <span>{order?.items?.length > 0 ? calculateSumItems(order?.payments, null, order?.items, order?.address.city_sync_id, order, typeListDeliver, false) : 0}</span> грн.</WrPrices>
      <List>
        {products.length > 0 ? (
          <RenderItemList
            products={products}
            city={city}
            order={order}
            isAdmin={isAdmin}
            onOpenDeleteModal={onOpenDeleteModal}
            typeListDeliver={typeListDeliver}
          />
        ) : (
          <Subtext>Немає позицій</Subtext>
        )}
      </List>

      {
        isArray(order.cutlery_sets) && (order.cutlery_sets.length > 0) && (
          <Cutlery data={order.cutlery_sets} />
        )
      }

      {
        isEdit && (
          <TabProducts />
        )
      }

      <ModalDelete
        title={'Видалення позиції'}
        text={`Ви підтверджуєте видалення ?`}
        isOpen={isOpenModalDelete.isOpen}
        funDelete={onDeleteItem}
        onCloseModal={onCloseModal}
      />
    </Wrapper>
  );
};

const RowBordTop = styled.div`
  border-top: 1px solid #393C49;
  padding: 8px 0 0 0;
  margin: 4px 0 0 0 ;
`;

const RowArtJusNe = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > div {
    flex: 1 1 auto;
  }
`;

const RowArtJus = styled.div`
  width: 100%;
`;

const TitleCutlery = styled.div`
  font-size: 14px;
  color: #fff;
`;

const WrCutlery = styled.div`
  margin-top: 16px;
  border-top: 1px solid #EDA240;
  padding-top: 6px;
`;

const ListCutlery = styled.ul``;

const ErMin = styled.div`
  padding: 0 0 6px 0;
  color: red;
  font-size: 12px;
`;

const RowBtnClos = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0;
  margin-right: 10px;
  color: ${(p) => p.theme.accentColor};
  border: 1px solid ${(p) => p.theme.accentColor};
  font-size: 14px;
    &:hover {
      border-color: #fff;
    }
`;

const MainWrap = styled.div``;

const RowCom = styled.div`
  margin-left: 4px;
  color: #FFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
  button {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.accentColor};
    svg {
      font-size: 16px;
    }
  }
`;

const Wr = styled.div`
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border-bottom: ${(p) => p.theme.inputBorder};
  display: flex;
  align-items: center;
`;

const WrPrices = styled.div`
  color: #fff;
  text-aling: center;
  border-bottom: ${(p) => p.theme.inputBorder};
  padding-bottom: 5px;
  margin-bottom: 5px;
  span {
    color: #EDA240;
  }
`;

const WrSel = styled.div`
  margin-left: 10px;
  display: flex;
  span {
    padding-bottom: 0;
  }
  &>div {
    margin: 0;
    padding-top: 0;
    &:nth-child(2) {
      margin-left: 8px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = styled.ul``;

const Item = styled.li`
  position: relative;
  margin: 5px 5px 8px 5px;
  padding: 10px;
  background-color:  ${(p) => p.isGift ? "#244b4a" : p.isStrock ? "#b64e50" : p.theme.backgroundLight};
  border: ${(p) => p.theme.inputBorder};
  border-radius: 10px;
  display: flex;
`;
const Roew = styled.div`
  flex: 1 1 auto;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
  color: ${(p) => p.theme.accentColor};
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  margin: 4px 0;
  border-top: ${(p) => p.theme.inputBorder};
  border-bottom: ${(p) => p.theme.inputBorder};
  color: ${(p) => p.theme.secondaryColor};
`;

const QuantityDop = styled.div`
  color: ${(p) => p.theme.secondaryColor};
  position: relative;
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  .int {
    div {
      margin: 0;
      padding: 0;
    }
    span {
      0
    }
  }
  input {
    margin: 0;
  }
`;

const ButtonHolder = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const RowArt = styled.div`
  display: flex;
  align-items: center;
  & > div {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

const Text = styled.div`
  margin-bottom: 5px;
  color: ${(p) => p.theme.secondaryColor};
  & > span {
    color: ${(p) => p.theme.accentColor};
  }
`;

const Subtext = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.lightAccentColor};
  padding-top: 10px;
`;

const Warning = styled.div`
  position: absolute;
  right: 10px;
  bottom: 2px;
  color: #f90716;
`;

const WrDop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrDopBot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleDop = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.accentColor};
  padding-bottom: 5px;
`;

const UlDops = styled.div`
  margin: 0 -8px 0 -8px;
  padding: 2px 0;
`;

const RowDop = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px 0 8px;
  border-bottom: ${(p) => p.theme.inputBorder};
`;

const RowDopHead = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => p.theme.inputBorder};
  margin: 0 8px 0 8px;
  color: #EDA240;
`;

const DopName = styled.div`
  padding: 0 8px;
  width: ${(p) => p.isAdmin ? "calc(80% - 0px)" : "calc(65% - 8px)"} ;
  border-left: ${(p) => p.theme.inputBorder};
  border-right: ${(p) => p.theme.inputBorder};
`;

const DopCol = styled.div`
  padding: 0 8px;
  width: ${(p) => p.isAdmin ? "calc(20% - 0px)" : "calc(29% - 16px)"};
  border-right: ${(p) => p.theme.inputBorder};
  display: flex;
  align-items: center;
`;

const DopDelete = styled.div`
  padding: 0 8px;
  width: calc(6% - 16px);
`;

const TitleDopBtn = styled.div`
  font-size: 12px;
`;

const Dops = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #2D303E;
  border: 1px solid #393C49;
  border-radius: 10px;
  padding: 10px;
  z-index: 99;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const DopsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(p) => p.theme.inputBorder};
  padding-bottom: 5px;
`;

const OpasDops = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
`;

const Oldops = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
`;

const ItemOld = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    flex: 1 1 auto;
    padding-right: 20px;
  }
`;

const SpanTitleDop = styled.span`
    flex: 1 1 auto;
    padding-right: 20px;
    transition: 0.4s;
`;