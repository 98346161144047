import HomeIcon from '@mui/icons-material/Home';

export const dataItemsMenu = {
    common: {
        title: 'Готуються',
        icon: <HomeIcon />,
    },
    preparing: {
        title: 'Пакуються',
    },
    for_delivery: {
        title: 'Очікують доставки',
    },
    on_way: {
        title: 'Доставляються',
    },
    pending: {
        title: 'Попередні',
    },
    rejected: {
        title: 'Відмінені',
    },
    delivered: {
        title: 'Доставлені',
    },
    closed: {
        title: 'Закриті',
    },
}
