import {
  CLOSE_ALL_MODALS,
  CLOSE_MODAL, OPEN_MODAL,
  OPEN_EDITORCALLCENTER,
  CLOSE_EDITORCALLCENTER,
  OPEN_MODAL_FILTER,
  CLOSE_MODAL_FILTER,
  IS_REDACTION_ORDER,
  IS_SET_ID_COOK,
  OPEN_MODAL_CLIENTS,
  CLOSE_MODAL_CLIENTS,
  IS_LOAD_MODAL_CLIENTS,
  UPDATE_LOAD_PAGE,
  OPEN_MODAL_CLIENTS_ADD,
  CLOSE_MODAL_CLIENTS_ADD,
  UPDATE_MODAL_COURIRERS,
  UPDATE_MODAL_DELETE,
  UPDATE_LOAD_MODAL_PRODUCTS,
  UPDATE_MODAL_STREET,
  UPDATE_LOAD_MODAL_USER,
  UPDATE_LOAD_BTN_RESOURCE,
  UPDATE_LOAD_EDIT_RESOURCE,
  UPDATE_LOAD_TABLE_RESOURCE,
  UPDATE_LOAD_EDIT_PAYMENT,
  UPDATE_ITEM_LOAD_BY_NAME
} from "../constants/view";

export function updateItemLoadByName(item, status = false) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ITEM_LOAD_BY_NAME,
      payload: { item, status },
    });
  }
}

export function updateLoadEditPayment(status = false) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_LOAD_EDIT_PAYMENT,
      payload: status,
    });
  }
}

export function updateLoadTableResource(status = false) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_LOAD_TABLE_RESOURCE,
      payload: status,
    });
  }
}

export function updateLoadEDITResource(status = false) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_LOAD_EDIT_RESOURCE,
      payload: status,
    })
  }
}

export function updateLoadBtnResource(status = false) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_LOAD_BTN_RESOURCE,
      payload: status,
    })
  }
}

export function updateLoadModalUser(status) {
  return {
    type: UPDATE_LOAD_MODAL_USER,
    payload: status,
  }
}

export function openModal(name, props = {}) {
  return {
    type: OPEN_MODAL,
    payload: {
      name,
      props,
    },
  };
}

export function closeModal(name) {
  return async (dispatch, getState) => {
    const { modals } = getState().view;
    const newModals = modals.filter((el) => el.name !== name);

    dispatch({
      type: CLOSE_MODAL,
      payload: { modals: newModals },
    });
  };
}

export function closeAllModals() {
  return { type: CLOSE_ALL_MODALS };
}

export function openEditorCallcenter(data) {
  return {
    type: OPEN_EDITORCALLCENTER,
    payload: data,
  };
}

export function closeEditorCallcenter() {
  return {
    type: CLOSE_EDITORCALLCENTER,
  };
}

export function openModalFilter() {
  return {
    type: OPEN_MODAL_FILTER,
  }
}

export function closeModalFilter() {
  return {
    type: CLOSE_MODAL_FILTER,
  }
}

export function openModalClients() {
  return {
    type: OPEN_MODAL_CLIENTS,
  }
}

export function closeModalClients() {
  return {
    type: CLOSE_MODAL_CLIENTS,
  }
}

export function openModalClientsAdd() {
  return {
    type: OPEN_MODAL_CLIENTS_ADD,
  }
}

export function closeModalClientsAdd() {
  return {
    type: CLOSE_MODAL_CLIENTS_ADD,
  }
}

export function redactionOrder(status = true) {
  return {
    type: IS_REDACTION_ORDER,
    payload: status
  }
}

export function setIdOrderCook(id = null) {
  return {
    type: IS_SET_ID_COOK,
    payload: { id }
  }
}

export function loadModalClients(status) {
  return {
    type: IS_LOAD_MODAL_CLIENTS,
    payload: status,
  }
}

export function updateLoadModalProducts(status) {
  return {
    type: UPDATE_LOAD_MODAL_PRODUCTS,
    payload: status,
  }
}

export function updadeLoadPageClients(status) {
  return {
    type: UPDATE_LOAD_PAGE,
    payload: status,
  }
}


export const updateModalCourirers = (item, data) => {
  return {
    type: UPDATE_MODAL_COURIRERS,
    payload: {
      data,
      item
    }
  }
}

export const updateModalDelete = (item, data) => {
  return {
    type: UPDATE_MODAL_DELETE,
    payload: {
      data,
      item
    }
  }
}

export const updateLoadModalStreet = (item, data) => {
  return {
    type: UPDATE_MODAL_STREET,
    payload: {
      data,
      item
    }
  }
}

