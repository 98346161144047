import { isArray } from "lodash";
import React from "react";
import styled, { useTheme } from "styled-components";

import { ItemOrder } from './ItemOrder';

const RenderOrders = ({
    //states
    data = [],
    isPrepared = false,
    idLoad,
    //actions
    handlerPreparing,
}) => {
    const theme = useTheme();

    return (
        <Wrapper theme={theme}>
            <Items>
                {
                    isArray(data) && (data.length > 0) ? (
                        data.map((item) => (
                            <ItemOrder
                                key={item.id}
                                isPrepared={isPrepared}
                                data={item}
                                idLoad={idLoad}
                                handlerPatchStatus={handlerPreparing}
                            />
                        ))
                    ) : (
                        <TextBody>Відсутні</TextBody>
                    )
                }
            </Items>
        </Wrapper>
    )
}

const Wrapper = styled.div``;

const Items = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1300px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const TextBody = styled.div`
    color: #fff;
`;

export default React.memo(RenderOrders);