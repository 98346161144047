import { connect } from 'react-redux';

import { default as BlackListPage } from './BlackList';

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BlackListPage);
