import { isString } from "lodash";
import api from "../../apiSingleton";

import {
  ACTIVE_ORDERS_CALLCENTER,
  ACTIVE_ORDERS_KITCHEN,
  ACTIVE_ORDERS_COURIER,
  UPDATE_FORM_SEARCH_ORDERS,
  UPDATE_STATUS_SEARCH,
  UPDATE_LOAD_PAGE,
  UPDATE_FILTER_PAGE_KITCHEN,
} from "../constants/listings";

import {
  updadeLoadPageClients,
  closeModalFilter
} from './view';

export function getAllActiveOrders(params = {}, isFilter = false, status = null) {
  return async (dispatch, state) => {
    const { listings: { searchValue, statusSearch } } = state();

    let dataParams = {
      ...params,
    }

    if (statusSearch) {
      dataParams.q = searchValue;
    } else {
      delete dataParams.q;
    }

    isString(status) && (dataParams.status = status);

    isFilter && (dispatch(closeModalFilter(false)));
    isFilter && dispatch(updadeLoadPageClients(true));

    await dispatch(updatePageLoad(true));

    const { data: orders } = await api.listings.getAllActiveOrders({
      ...dataParams
    });

    await dispatch({
      type: ACTIVE_ORDERS_CALLCENTER,
      payload: { orders },
    });

    await dispatch(updatePageLoad(false));

    isFilter && dispatch(updadeLoadPageClients(false));
  };
}

let statusRepeat = false;
export function downloadExcelOrder(params = {}, status = null, stateLoda = () => { }) {
  return async (dispatch, state) => {
    let dataParams = {
      ...params,
    }

    isString(status) && (dataParams.status = status);

    await stateLoda(true);

    const res = await api.listings.downloadExcelOrder(dataParams);

    if (res.status == "Ok") {
      const link = document.createElement('a');

      link.href = res.data.file;
      link.setAttribute(
        'download',
        `statistics.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      await stateLoda(false);
    } else {
      if (!statusRepeat) {
        setTimeout(() => {
          dispatch(downloadExcelOrder());
          statusRepeat = true;
        }, 2000);
      } else {
        await stateLoda(false);
      }
    }
  }
}

export function getCouriersOrders() {
  return async (dispatch) => {
    const data = await api.listings.getCouriersOrders();

    await dispatch({
      type: ACTIVE_ORDERS_COURIER,
      payload: data,
    });
  };
}

export const getOrderItems = () => {
  return async (dispatch) => {
    const data = await api.listings.getOrderItems();

    await dispatch({
      type: ACTIVE_ORDERS_KITCHEN,
      payload: { data },
    });
  };
};

export const updateFormSearchOrders = (value) => {
  return {
    type: UPDATE_FORM_SEARCH_ORDERS,
    payload: value,
  }
}

export const handleІSybmitSearchOrders = (value) => {
  return {
    type: UPDATE_STATUS_SEARCH,
    payload: value,
  }
}

export const updatePageLoad = (status) => {
  return ({
    type: UPDATE_LOAD_PAGE,
    payload: status,
  })
}

export const updateFilter = (item, data) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_FILTER_PAGE_KITCHEN,
      payload: { item, data }
    });
  }
}