import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import * as viewActions from "../../../store/actions/view";
import { default as RolePage } from './Roles';

function mapStateToProps(state) {
    return {
        view: state.view,
        roles: state.settings.roles,
        isOpenModalDelete: state.view.modalDelete.isOpen,
        isLoadModalDelete: state.view.modalDelete.loadingsModal,
    };
}

const mapDispatchToProps = {
    ...settingsActions,
    ...viewActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolePage);
