import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

import { Dropdown } from "components/ui-kit/inputs/Dropdown";

import { rolesMapped } from "utils/mappers/roles";
import { kitchensMapped } from "utils/mappers/kitchens";
import { removeEmpty } from "utils/helpers/removeEmptyFromObj";
import { citiesMapped } from "utils/mappers/cities";
import Radio from "components/ui-kit/Radio";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import {
    RADIO_SCHEME_ACTIONS_STATUS
} from "constants/schemes";

const keyLocalStorageFilter = 'dataFilterUsers';

const ModalFilterUsers = ({
    isOpen,
    kitchens,
    roles,
    cities,
    // mapDispatch
    closeModalFilter,
    getUsers,
    updateUserSearchForm,
}) => {
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const [role, setRole] = useState('');
    const [kitchen, setKitchen] = useState('');
    const [city, setCity] = useState('');
    const [status, setStatus] = useState('active');

    let kitchensKovert = [...[{ title: "Всі", value: '' }], ...kitchensMapped(kitchens)];
    let rolesKovert = [...[{ title: "Всі", value: '' }], ...rolesMapped(roles)];
    let citiesConvert = [...[{ title: "Всі", value: '' }], ...citiesMapped(cities)];

    const clearState = () => {
        setRole('');
        setKitchen('');
        setCity('');
        setStatus('active');

        getUsers(1);
    }

    const handleSubmit = () => {
        updateUserSearchForm('phone', '');
        updateUserSearchForm('name', '');

        let params = {
            kitchen_code: kitchen,
            role_name: role,
            city,
            status,
        };

        params = removeEmpty(params);

        let paramStr = '';
        paramStr += `status=${status}&`;

        if (!!params?.kitchen_code?.length) {
            paramStr += `kitchen_code=${params.kitchen_code}&`;
        }

        if (!!params?.role_name?.length) {
            paramStr += `role_name=${params.role_name}&`;
        }

        if (!!params?.city?.length) {
            paramStr += `city=${params.city}&`;
        }

        paramStr = paramStr.slice(0, -1);

        history.push(`?page=1&${paramStr}`);

        if (!Object.keys(params).length) {
            handleSubmitRemove();
        }

        getUsers(1, params);
    }

    const handleSubmitRemove = () => {
        history.push(`?page=1`);
        clearState();
    }

    useEffect(() => {
        if (location.query?.kitchen_code) {
            setKitchen(location.query?.kitchen_code);
        }

        if (location.query?.role_name) {
            setRole(location.query?.role_name);
        }

        if (location.query?.city) {
            setCity(location.query?.city);
        }

        if (location.query?.status) {
            setStatus(location.query?.status);
        }
    }, []);

    return (
        <>
            {
                isOpen && (
                    <BgModal onClick={() => closeModalFilter()} />
                )
            }

            <Wrapper theme={theme} isOpen={isOpen}>
                <Header>
                    <div style={{ color: "#fff" }}>Фільтр</div>
                    <CloseButton onClick={() => closeModalFilter()}>
                        <CloseIcon style={{ fontSize: 36 }} />
                    </CloseButton>
                </Header>
                <Block>
                    <WrIn>
                        <Dropdown
                            title='Посада'
                            type='text'
                            onChange={(e) => setRole(e.target.value)}
                            value={role}
                            list={rolesKovert}
                        />
                    </WrIn>
                    <WrIn>
                        <Dropdown
                            title='Кухня'
                            type='text'
                            onChange={(e) => setKitchen(e.target.value)}
                            value={kitchen}
                            list={kitchensKovert}
                        />
                    </WrIn>
                    <WrIn>
                        <Dropdown
                            title='Місто'
                            type='text'
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            list={citiesConvert}
                        />
                    </WrIn>
                    <WrIn>
                        <Radio
                            colors={"#ffffff"}
                            items={RADIO_SCHEME_ACTIONS_STATUS}
                            label="Активні"
                            valueKey="status"
                            value={status}
                            onChange={(e) => {
                                setStatus(e.value.value)
                            }}
                        />
                    </WrIn>
                </Block>
                <Menu>
                    <Item>
                        <ButtonIcon
                            onClick={handleSubmit}
                            title='Застосувати'
                            icon={<SaveIcon />}
                        />
                    </Item>
                    <Item>
                        <ButtonIcon
                            onClick={handleSubmitRemove}
                            title='Скинути'
                            icon={<CloseIcon />}
                        />
                    </Item>
                </Menu>
            </Wrapper >
        </>

    );
};

const Wrapper = styled.div`
  position: fixed;
  right: ${(p) => (p.isOpen ? "0" : "-1000px")};
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  padding-bottom: 30px;
  background-color: ${(p) => p.theme.background};
  transition: 200ms right;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px 20px;
  border-bottom: 1px solid ${(p) => p.theme.secondaryColor};
  margin-bottom: 15px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 15px;
  margin-bottom: 40px;
  
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrIn = styled.div`
  &:not(:first-child) {
      margin-top: 15px;
  }
`;

const Menu = styled.ul`
  position: fixed;
  bottom: 0;
  display: flex;
`;

const Item = styled.li`
  margin: 0 20px 15px 0;
  &:last-child {
    margin-right: 0;
  }
`;

const BgModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
`;


export default ModalFilterUsers;
