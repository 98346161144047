import React, { useEffect } from "react";
import { ReportFilter } from "./filter";
import { ReportsTable } from "./table";

import { itemListHeight } from "../../utils/helpers/reports";
import { seveExel } from "../../utils/exel";

const Reports = ({
    listSelect,
    activeSelect,
    listReports,
    filter,
    isLoadPage,
    getReportsList,
    updateActiveSelect,
    updateFilterAction,
    updateFilterArr,
    getReportsOfType,
    kitchens,
    restaurants,
}) => {
    const handleSelect = (value) => {
        updateActiveSelect(value);
    }

    const updateFilter = (name, value) => {
        updateFilterAction(name, value)
    }

    const handlerSubmitRange = () => {
        getReportsOfType();
    }

    const handleSeveExel = () => {
        seveExel(activeSelect, listReports);
    }

    useEffect(() => {
        getReportsList();
    }, []);

    useEffect(() => {
        getReportsOfType();
    }, [activeSelect]);

    useEffect(() => {
        itemListHeight();
    }, [listReports, activeSelect, isLoadPage]);

    return (
        <div>
            <ReportFilter
                listSelect={listSelect}
                activeSelect={activeSelect}
                filter={filter}
                kitchens={kitchens}
                restaurants={restaurants}

                handleSelect={handleSelect}
                updateFilter={updateFilter}
                updateFilterArr={updateFilterArr}
                handlerSubmitRange={handlerSubmitRange}
                seveExel={handleSeveExel}
            />
            <div className="row-me ">
                <div className="wr-table-me">
                    <ReportsTable
                        data={listReports}
                        activeSelect={activeSelect}
                        isLoadPage={isLoadPage}
                    />
                </div>
            </div>
        </div>
    )
}

export default Reports;