import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useLocation } from 'react-router-dom';

import { Header } from "./Header";
import ModalEditor from "components/modals/ModalEditor";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import Pagination from "components/ui-kit/Pagination";
import OrdersList from "./OrdersList";

const ClientOrders = ({
    //state
    extensive,
    view,
    //redux
    openEditorCallcenter,
    updateOrder,
    getClientExtensiveAndOrder,
    getOrdersAllClient,
    orders
}) => {
    const theme = useTheme();
    const location = useLocation();

    const handleCreateOrder = () => {
        updateOrder();
        openEditorCallcenter();
    };

    useEffect(() => {
        let id = location.pathname.split('/')[2];

        if (id) {
            getClientExtensiveAndOrder(id);
            getOrdersAllClient(id);
        }
    }, []);

    return (
        <Wrapper theme={theme}>
            <Header
                name={extensive?.name}
                handlerOpenModalAdd={handleCreateOrder}
            />

            <ViewRow>
                {
                    !view?.isLoadPage ? (
                        orders.length > 0 ? (
                            <OrdersList data={orders} />
                        ) : (
                            <TextNone>Пусто</TextNone>
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </ViewRow>

            {
                orders.length > 0 && (
                    <Pagination
                        // onClick={setPage}
                        currentPage={1}
                        lastPage={1}
                    />
                )
            }

            <ModalEditor />

        </Wrapper>
    )
}

const Wrapper = styled.div`
`;

const ViewRow = styled.div`
    margin-top: 24px;
`;

const TextNone = styled.div`
    text-align: center;
    color: #9D9D9D;
`;

export default React.memo(ClientOrders);