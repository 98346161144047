import React from "react";
import { Marker } from '@react-google-maps/api';

import icon from "assets/icons/marker.svg";

export const MapMarker = ({
  onChange,
  position,
  icone = null,
  clickable = true,
  label = ''
}) => {
  return <Marker
    position={position}
    icon={icone ? icone : icon}
    onClick={(e) => onChange(e.latLng.lat(), e.latLng.lng())}
    clickable={clickable}
    label={label}
  />;
};
