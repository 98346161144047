import { useState } from "react";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import { isArray } from "lodash";
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import SaveIcon from "@mui/icons-material/Save";

const ModalListOfCouriers = ({
    // state
    isKitchen = false,
    isOpen,
    loadingsModal,
    couriersList,
    order,
    // reducer
    updateModalCourirers,
    updateOrderData,
    orderUpdate,
    getOrderPrint,
    activeCourier,
    setActiveCourier,
    handlePrint = () => { }
}) => {
    const theme = useTheme();
    // const [activeCourier, setActiveCourier] = useState(null);

    const handlerCloseModal = async () => {
        await updateModalCourirers('isOpen', false);
    }

    const handlerItemCourier = (item) => {
        updateOrderData("courier_id", item.id);
    }

    const handleSubmit = async () => {
        await orderUpdate(order, "for_delivery", isKitchen);
        await handlerCloseModal();
        await setActiveCourier(null);
        // await getOrderPrint();
        // await handlePrint();
    }

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={handlerCloseModal}
        >
            <Title>Кур'єри</Title>

            {
                (isArray(couriersList) && !loadingsModal) && (
                    <>
                        {
                            activeCourier && (
                                <ActiveCourirers>
                                    <span>Обраний кур'єр:</span> {activeCourier?.first_name} {activeCourier?.last_name} - {activeCourier?.phone}
                                </ActiveCourirers>
                            )
                        }

                        {
                            (couriersList.length > 0) && (
                                <UlList>
                                    {
                                        couriersList.map((item) => {
                                            if ((order.courier_id === item.id) && (item.id !== activeCourier?.id)) {
                                                setActiveCourier(item);
                                            }

                                            return (
                                                <ItemLi key={item.id} active={order.courier_id === item.id} onClick={() => handlerItemCourier(item)}>
                                                    <Id>{item.id}</Id>
                                                    <Name>{item?.first_name} {item?.last_name}</Name>
                                                    <Phone>{item?.phone}</Phone>
                                                </ItemLi>
                                            )
                                        })
                                    }
                                </UlList>
                            )
                        }

                        <WrVtn>
                            <ButtonIcon
                                onClick={handleSubmit}
                                title='Зберегти'
                                icon={<SaveIcon />}
                            />
                        </WrVtn>

                    </>
                )
            }

            {
                loadingsModal && (
                    <LoadingCircl mins={true} />
                )
            }
        </ModalLayout>
    )
}

const Title = styled.div`
    text-align: center;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const UlList = styled.ul`
    max-height: 300px;
    overflow: auto;
    margin-top: 20px;
`;

const ItemLi = styled.li`
    padding: 10px 0;
    font-size: 16px;
    display: flex;
    border-bottom: ${(p) => p.theme.inputBorder};
    cursor: pointer;
    margin-right: 10px;
    transition: 0.4s;

    color: ${(p) => p.active && p.theme.accentColor};

    &:hover {
        color: ${(p) => p.theme.accentColor};
    }
`;

const Id = styled.span`
    padding-left: 3px;
    min-width: 40px;
    border-right: ${(p) => p.theme.inputBorder};
`;

const Name = styled.span`
    padding-left: 15px;
    padding-right: 15px;
    border-right: ${(p) => p.theme.inputBorder};
    width: calc(50% - 20px);
`;

const Phone = styled.span`
    padding-left: 15px;
    width: calc(50% - 20px);
`;

const ActiveCourirers = styled.div`
    display: flex;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    padding-bottom: 12px;

    span {
        font-weight: 700;
        padding-right: 10px;
    }
`;

const WrVtn = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export default ModalListOfCouriers;