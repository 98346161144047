import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { isArray } from "lodash";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { typePrice } from "utils/helpers/price";

const OrdersList = ({
  isHidePrice = false,
  data,
  title,
  openEditorCallcenter,
  getCallcenterOrderItem,
  idOrder,
}) => {
  const theme = useTheme();
  const [isExpand, setExpand] = useState(true);

  const handleOpenEditor = async (item) => {
    await openEditorCallcenter(item);
    await getCallcenterOrderItem(item.id);
  };

  const renderBody = () => {
    return data.map((item, index) => {
      let floor = item.address.floor ? `, кв. ${item.address.floor}` : '';
      let price = '***';
      let bonus = '***';
      let typePayment = '***';

      if (!isHidePrice) {
        let prises = typePrice(item.payments, false);

        price = prises.price;
        bonus = prises.bonus;
        typePayment = prises.typePayment;
      }

      let timeCook = item.time_flags?.end_cooking ? item.time_flags?.end_cooking : { time: item.time_flags.time, class: "green" }
      let isTechnical = (item?.technical == 1);

      return (
        <div key={`orderItem_${index}`}>
          <Wr
            isOpen={idOrder == item.id}
          >
            <Rows theme={theme}>
              {
                isTechnical && (
                  <div className="technical-info">Технічне замовлення</div>
                )
              }
              <Item width={7} color={timeCook.class} onClick={() => handleOpenEditor(item)}>
                {
                  item?.status == "closed" ? (
                    <div className="closed_time">
                      <i>Закрите: </i>
                      <div>
                        {
                          item.history.find(el => (el.status == "closed"))?.set_at
                        }
                      </div>
                    </div>
                  ) : (
                    <Time>{timeCook.time}</Time>
                  )
                }
              </Item>
              <Item width={5} onClick={() => handleOpenEditor(item)}>
                <Name>{item.id}</Name>
              </Item>
              <Item width={6} onClick={() => handleOpenEditor(item)}>{item.restaurant_info.name || "Не вибрано"}</Item>
              <Item width={12} onClick={() => handleOpenEditor(item)}>
                <Name>{item.client.name}</Name>
              </Item>
              <Item width={14} >
                <a className="tel" href={`tel:${item.client.phone}`}>{item.client.phone}</a>
              </Item>
              <Item width={18} onClick={() => handleOpenEditor(item)}>{`${item.address.city.name_ua}, ${item.address.street}, ${item.address.house_number} ${floor}`} </Item>
              <Item width={15} onClick={() => handleOpenEditor(item)}>{item?.operator_full_name}</Item>
              <Item isList={true} width={12} onClick={() => handleOpenEditor(item)}>
                <ListPris>
                  {
                    isArray(typePayment) && typePayment.map((itemPa, i) => (
                      <span key={i}>{itemPa}</span>
                    ))
                  }
                </ListPris>
              </Item>
              <Item width={7} onClick={() => handleOpenEditor(item)}>-{item?.discount}</Item>
              <Item width={7} onClick={() => handleOpenEditor(item)}>{item?.to_pay}</Item>
            </Rows>
          </Wr>
        </div>
      );
    });
  };

  return (
    <Wrapper theme={theme}>
      <Titles onClick={() => {
        if (data.length > 0) {
          setExpand(!isExpand)
        }
      }} isExpand={isExpand}>
        {title}
        {
          (data.length > 0) && (
            <IconWr isExpand={isExpand}>
              <ArrowDownwardIcon />
            </IconWr>
          )
        }
      </Titles>

      {data.length ? (
        <Table isExpand={isExpand}>
          {renderBody()}
        </Table>
      ) : (
        <Text>Немає замовлень у цьому статусі</Text>
      )}

    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin-bottom: 15px;
                `;

const Text = styled.p`
                text-align: center;
                color: ${(p) => p.theme.lightAccentColor};
                `;

const Table = styled.div`
                display: ${(p) => (p.isExpand ? "table" : "none")};
                width: 100%;
                border-collapse: collapse;
                `;

const Wr = styled.div`
                margin-top: 16px;
                position: relative;
                z-index: ${(p) => p.isOpen ? 99 : 0}
                `;

const Titles = styled.div`
                font-weight: 600;
                font-size: 20px;
                color: ${(p) => p.theme.color25};
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: 0.4s;
                &:hover {
                  color: ${(p) => p.theme.accentColor};
    }
                `;

const Rows = styled.div`
                display: flex;
                positione: relative;
                align-items: center;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                margin-top: 16px;
                cursor: pointer;
                transition: 0.4s;
                &:hover {
                  background - color: #E6E6E6;
    }
                `;

const Item = styled.div`
                min-height: 56px;
                width: ${(p) => p.width}%;
                font-size: 14px;
                color: ${(p) => p.theme.color42};
                padding: 5px 5px 5px 5px;
                background: ${(p) => p.color};
                white-space: nowrap;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                &:first-child {
                  border-radius: 8px 0px 0px 8px;
    }
                &:last-child {
                  border-radius: 0px 8px 8px 0px;
    }

    .tel {
      padding: 8px 0;
      &:hover {
        color: red;
      }
    }

    .closed_time {
      display: flex;
      flex-direction: column;
      i {
        color: green;
      }
    }
                `;

const ListPris = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Time = styled.div`
                width: 100%;
                height: 100%;
                border-radius: inherit;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                `;

const Name = styled.div`
               padding-left: 10px;
                `;



const IconWr = styled.span`
                svg {
                  transform: rotate(${(p) => p.isExpand ? "-180deg" : "0deg"});
  }
                margin-left: 20px;
                `;

export default React.memo(OrdersList);
