import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import ButtonIcon from "components/ui-kit/ButtonIcon";

const ModalTypeCheck = ({
    // props
    isOpen = false,
    onCloseModal = () => { },
    handleCmc = () => { },
    isLoadCmc = false,
    handlePrint = () => { },
    isLoadPrint = false
}) => {
    const theme = useTheme();

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={onCloseModal}
        >
            <Text>Оберіть один із варіантів</Text>

            <RowBtn>
                <Item>
                    <ButtonIcon
                        onClick={handleCmc}
                        title={'CMC'}
                        colorBg="green"
                        isLoad={isLoadCmc}
                    />
                </Item>
                <Item>
                    <ButtonIcon
                        onClick={handlePrint}
                        title={'Друкувати'}
                        colorBg="#58bee1"
                        isLoad={isLoadPrint}
                    />
                </Item>
            </RowBtn>
        </ModalLayout>
    )
}

const Title = styled.div`
    text-align: center;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const Text = styled.div`
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
    padding: 0 20px;
`;

const RowBtn = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
`;

const Item = styled.div`
    padding: 0 10px;
`;


export default ModalTypeCheck;