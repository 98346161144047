import { isArray } from "lodash"

export const mapperCoveInValue = (arr = []) => {
    if (!isArray(arr))
        return [];

    let newArr = [];

    arr.map(item => {
        newArr.push({ title: item.title, value: item.code })
    });

    return newArr;
}

export const mapperCoveInlabel = (arr = []) => {
    if (!isArray(arr))
        return [];

    let newArr = [];

    arr.map(item => {
        newArr.push({ label: item.title, value: item.code })
    });

    return newArr;
}