import styles from "./scss/menuPage.module.scss";

export const MenuItem = ({
    //props
    icon,
    title,
    inKey,
    isActive = false,
    onHandle = () => { },
}) => {
    let className = `${styles.icon}`;

    title.length == 0 && (className += ` ${styles.icon_marg}`)

    return (
        <li className={styles.li}>
            <button
                className={`${styles.a} ${isActive ? styles.active : ''}`}
                onClick={() => onHandle(inKey)}
            >
                {
                    icon && (
                        <span className={className}>{icon}</span>
                    )
                }
                {
                    title.length > 0 && <span className={styles.title}>{title}</span>
                }
            </button>
        </li>
    )
}