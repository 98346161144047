import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { isArray, isObject } from "lodash";

import { LoadingModal } from "../../loadingOrder/loading";
import CalendarRange from "components/ui-kit/CalendarRange";

import { diffDateMonth } from "utils/helpers/date";

const Call = ({
    // state
    isOpen = false,
    calls = {},
    telestatSettings = {},
    // dispatch
    updateFilterCalls = () => { },
    getClientCallByPhoneNumber = () => { },
}) => {
    const theme = useTheme();

    return (
        <BlockCalls
            theme={theme}
            isOpen={isOpen}>
            <Header>
                <div style={{ color: "#fff" }}>Дзвінки</div>
            </Header>
            <WrCalendar>
                <CalendarRange
                    title="Період з - по"
                    valueIn={calls.filter.date}
                    ranges={calls.filter.date}
                    isAbs={true}
                    isBtn={true}
                    minDate={diffDateMonth(1)}
                    onChangeCal={(date) => updateFilterCalls("date", [date.selection])}
                    handlerSubmit={getClientCallByPhoneNumber}
                />
            </WrCalendar>
            <BodyCalls>
                {
                    isArray(calls?.list) && (calls.list.length > 0) ? (
                        <UlList>
                            {
                                calls.list.map((itemCall, index) => {
                                    let dateTel = itemCall.calldate.split(' ')[0].replaceAll('-', '/');

                                    return (
                                        <UlLi key={index}>
                                            <TalkTime>Час розмови: {itemCall.calldate}</TalkTime>
                                            <audio controls="controls">
                                                <source src={`${telestatSettings.audio_link}/${dateTel}/${itemCall.record}`} />
                                            </audio>
                                        </UlLi>
                                    )
                                })
                            }
                        </UlList>
                    ) : (
                        <Empty>Пусто</Empty>
                    )
                }

            </BodyCalls>

            {
                calls.isLoad && (
                    <LoadingModal />
                )
            }
        </BlockCalls>
    )
}

const TalkTime = styled.div`
    padding: 0 0 5px 20px;
    color: #fff;
    font-size: 13px;
`;

const Empty = styled.div`
    text-align: center;
    color: #fff;
`;

const UlList = styled.ul``;

const UlLi = styled.li`
    &:not(first-child) {
        margin-top: 10px;
    }
    audio {
        width: 100%;
    }
`;

const BlockCalls = styled.div`
    position: fixed;
    right: ${(p) => (p.isOpen ? "460px" : "-1000px")};
    top: 0;
    height: 100%;
    width: 360px;
    z-index: 20000;
    background-color: ${(p) => p.theme.background};
    border-right: 1px solid #EDA240;
    transition: 200ms right;
`;

const BodyCalls = styled.div`
    height: calc(100% - 127px);
    overflow: auto;
    padding: 15px 10px 10px 10px;
`;

const WrCalendar = styled.div`
    padding: 0 10px 0 10px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;
    border-bottom: ${(p) => p.theme.border39};
    height: 56px;
`;

export default Call;