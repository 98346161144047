/*  camelcase */
import { connect } from "react-redux";

import * as listingsActions from "store/actions/listings";
import * as kitchenActions from "store/actions/kitchen";
import * as viewActions from "store/actions/view";
import * as orderActions from "store/actions/order";
import * as actionNotifications from "store/actions/notifications";

import { default as KitchenPage } from "./KitchenPage";

function mapStateToProps(state) {
  const { user, order } = state;
  const { orders, loadPage, filter } = state.listings;

  return {
    order: order.data,
    print: order.check,
    dataPrintAll: state.kitchens.dataPrintAll,
    filter,
    user,
    orders,
    kitchens: state.settings.kitchens,
    isOpenModal: state.view.modalCallCenter.isOpen,
    loadPage,
    profile: state.user.user
  };
}

const mapDispatchToProps = {
  ...listingsActions,
  ...viewActions,
  ...orderActions,
  ...actionNotifications,
  ...kitchenActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(KitchenPage);