import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import * as ProductsActions from 'store/actions/products';
import * as viewActions from "../../../store/actions/view";

import { default as PromocodesPage } from './Promocodes';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        promocodes: settings.promocodes,
        promocodesEdit: settings.promocodesEdit,
        categories: settings.productCategories,
        isOpenModalDelete: view.modalDelete.isOpen,
        isLoadModalDelete: view.modalDelete.loadingsModal,
        products: state.products.list,
    };
}

const mapDispatchToProps = {
    ...settingsActions,
    ...viewActions,
    ...ProductsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromocodesPage);
