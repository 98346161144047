import api from "../../apiSingleton";

import {
    GET_MAGAZINE_COMPLAINT,
    UPDATE_FILTER_MAGAZINE_COMPLAINTS,
} from "../constants/settignsMagazine";

import { updadeLoadPageClients } from "./view";

import { getRangeDate } from "utils/helpers/date";

export function updateFilterMagazineComplaints(item, data) {
    return async (dispatch) => {
        await dispatch({
            type: UPDATE_FILTER_MAGAZINE_COMPLAINTS,
            payload: { item, data }
        });
    }
}

export function getMagazineComplaints() {
    return async (dispatch, state) => {
        try {
            const {
                settingsMagazine: {
                    complain: {
                        filter
                    }
                }
            } = state();

            const params = { ...getRangeDate(filter.date) };

            await dispatch(updadeLoadPageClients(true));
            const res = await api.settingsMagazine.getMagazineComplaints(params);

            await dispatch({
                type: GET_MAGAZINE_COMPLAINT,
                payload: res.data
            });

            await dispatch(updadeLoadPageClients(false));
            
        } catch (e) {
            console.log("errro getMagazineComplaints: ", e);
        }
    };
}

