import { useState } from "react";

import Resource from "./Resource";
import ModalDelete from "components/modals/ModalDelete";

import styles from "./../scss/settings.module.scss";

const Home = ({
    // state
    isOpenModalDelete,
    isLoadModalDelete,
    // dispatch
    deleteResource,
    updateModalDelete,
}) => {
    const [idDelete, setIdDelete] = useState({
        id: undefined,
        type: undefined,
    });

    const onDeleteResource = (data, type) => {
        setIdDelete({ id: data.id, type });
        updateModalDelete('isOpen', true);
    }

    const handleDelete = () => {
        switch (idDelete.type) {
            case "resource":
                deleteResource(idDelete.id);
                break;
        }
    }

    return (
        <div className={styles.bloks}>
            <div className={`${styles.d_max_768}`}>
                <Resource
                    onDeleteResource={onDeleteResource}
                />
            </div>

            <ModalDelete
                title={'Видалити'}
                text={`Ви підтверджуєте видалення ?`}
                isOpen={isOpenModalDelete}
                isLoad={isLoadModalDelete}
                funDelete={() => handleDelete()}
                updateModalDelete={updateModalDelete}
            />
        </div>
    )
}

export default Home;