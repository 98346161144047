import PropTypes from "prop-types";
import { useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Radio from "components/ui-kit/Radio";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingModal } from "components/loadingOrder/loading";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from '@mui/icons-material/Close';
import styles from "../../scss/settings.module.scss";

import { validateResource } from "utils/validation";

import { RADIO_SCHEME } from "constants/schemes";

export const EditResource = ({
    resourceEdit,
    handleSubmitClose,
    updateFormResource,
    handleSubmitUpdate,
    handleSubmitСreate,
    view,
}) => {
    const [error, setErrors] = useState({
        name: "",
    });

    let titleButton = resourceEdit.id ? 'Змінити' : 'Додати';

    const onSubmit = () => {
        validateResource({
            data: {
                ...resourceEdit,
            },

            onSuccess: async () => {
                if (resourceEdit?.id) {
                    handleSubmitUpdate(resourceEdit.id);
                } else {
                    handleSubmitСreate();
                }

                setErrors({
                    name: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    return (
        <div className={`${styles.block_edit}`}>
            <TextInput
                title="Назва"
                value={resourceEdit.name}
                isStyle={true}
                error={error.name}
                onChange={(e) => updateFormResource("name", e.target.value)}
            />
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME}
                    label="Активність"
                    valueKey="status"
                    value={resourceEdit.status}
                    onChange={(e) => updateFormResource("status", +e.value.value)}
                />
            </div>
            <div className={styles.row_btn_tow}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={onSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                        isLoad={view.isLoadBtnResource}
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmitClose}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>

            {
                view.isLoadEditResource && (
                    <LoadingModal />
                )
            }
        </div>
    )
}

EditResource.propTypes = {
    handleSubmitClose: PropTypes.func,
    updateFormResource: PropTypes.func,
    handleSubmitСreate: PropTypes.func,
    handleSubmitUpdate: PropTypes.func,
    view: PropTypes.object,
};

export default EditResource;
