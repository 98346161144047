import { forwardRef, useEffect } from "react";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const ModalContent = forwardRef((props, ref) => {
    const { data } = props;

    useEffect(() => {
        let docTable = document.querySelectorAll('.js_tables');

        docTable.forEach((item) => {
            let btnВ = item.querySelector('.btn-shows');

            btnВ.addEventListener('click', (e) => handleClickShow(e, item));
        });

        const handleClickShow = (e, item) => {
            let isClassClose = item.classList.contains('close-table');

            if (isClassClose) item.classList.remove('close-table');
            if (!isClassClose) item.classList.add('close-table');
        }

        return () => {
            docTable.forEach((item) => {
                let btnВ = item.querySelector('.btn-shows');

                btnВ.addEventListener('click', (e) => handleClickShow(e, item));
            });
        }
    }, [data]);

    return (
        <div className="cont-modal-info" ref={ref}>
            <table>
                <tbody>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'center' }}><span>Загальне</span></td>
                    </tr>
                    <tr>
                        <td><span>Місто:</span></td>
                        <td>{data?.overall?.city}</td>
                    </tr>
                    <tr>
                        <td><span>Термінал:</span></td>
                        <td>{data?.overall?.terminal}</td>
                    </tr>
                    <tr>
                        <td><span>Касова зміна:</span></td>
                        <td>{data?.overall?.cash_register_shift}</td>
                    </tr>
                    <tr>
                        <td><span>Зміна відкрита:</span></td>
                        <td>{data?.overall?.opened_from}</td>
                    </tr>
                    <tr>
                        <td><span>By manager (Адмін):</span></td>
                        <td>{data?.overall?.admin}</td>
                    </tr>
                </tbody>
            </table>
            {
                data?.all_sales && (
                    <>
                        <br />
                        <table className="js_tables">
                            <tbody>
                                <tr>
                                    <td>
                                        <button className="btn-shows"><ArrowDropDownIcon /></button>
                                        <span>Сума Продажу</span>
                                    </td>
                                    <td><span>Сума</span></td>
                                    <td><span>Бонуси</span></td>
                                </tr>
                                {
                                    Object.keys(data.all_sales.list).map((item, index) => {
                                        return (
                                            <>
                                                <tr key={item}>
                                                    <td><span>{item}</span></td>
                                                    <td>{data.all_sales.list[item].sum}</td>
                                                    <td>{data.all_sales.list[item].bonus}</td>
                                                </tr>

                                                {
                                                    (Object.keys(data.all_sales.list).length - 1 == index) && (
                                                        <tr key={item + 1}>
                                                            <td><span>Всього</span></td>
                                                            <td>{Object.keys(data.all_sales.list).reduce((accumulator, currentValue) => accumulator + data.all_sales.list[currentValue].sum, 0)}</td>
                                                            <td>{Object.keys(data.all_sales.list).reduce((accumulator, currentValue) => accumulator + data.all_sales.list[currentValue].bonus, 0)}</td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                )
            }

            {
                data?.restourant_payments.list && (
                    Object.keys(data?.restourant_payments.list).map((item, index) => {
                        return (
                            <>
                                <br />
                                <table className="js_tables" key={index}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <button className="btn-shows"><ArrowDropDownIcon /></button>
                                                <span>Ресторан: {item}</span>
                                            </td>
                                            <td><span>Сума</span></td>
                                            <td><span>Бонуси</span></td>
                                        </tr>
                                        {
                                            Object.keys(data?.restourant_payments.list[item].list).map((itemRow, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td><span>{itemRow}</span></td>
                                                        <td>{data?.restourant_payments.list[item].list[itemRow].sum}</td>
                                                        <td>{data?.restourant_payments.list[item].list[itemRow].bonus}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </>
                        )
                    })
                )
            }

            {
                data?.payment_type.list && (
                    <>
                        <br />
                        <table className="js_tables">
                            <tbody>
                                <tr>
                                    <td>
                                        <button className="btn-shows"><ArrowDropDownIcon /></button>
                                        <span>Для всіх брендів</span>
                                    </td>
                                    <td><span>По типам</span></td>
                                    <td><span>Бонуси</span></td>
                                </tr>
                                {
                                    Object.keys(data.payment_type.list).map((item, index) => {
                                        return (
                                            <>
                                                <tr key={item}>
                                                    <td><span>{item}</span></td>
                                                    <td>{data.payment_type.list[item].sum}</td>
                                                    <td>{data.payment_type.list[item].bonus}</td>
                                                </tr>

                                                {
                                                    (Object.keys(data.payment_type.list).length - 1 == index) && (
                                                        <tr key={item + 1}>
                                                            <td><span>Всього</span></td>
                                                            <td>{Object.keys(data.payment_type.list).reduce((accumulator, currentValue) => accumulator + data.payment_type.list[currentValue].sum, 0)}</td>
                                                            <td>{Object.keys(data.payment_type.list).reduce((accumulator, currentValue) => accumulator + data.payment_type.list[currentValue].bonus, 0)}</td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                )
            }

            {
                data?.cash_payments.list && (
                    <>
                        <br />
                        <table className="js_tables">
                            <tbody>
                                <tr>
                                    <td>
                                        <button className="btn-shows"><ArrowDropDownIcon /></button>
                                        <span>Всього "Готівка"</span>
                                    </td>
                                    <td><span>Бренд</span></td>
                                    <td><span>Час закриття замовлення</span></td>
                                    <td><span>Сума</span></td>
                                    <td><span>К оплате (сума зі знижкою)</span></td>
                                    <td><span>Курʼєр</span></td>
                                    <td><span>оператор</span></td>
                                </tr>
                                {
                                    Object.keys(data.cash_payments.list).map((item, index) => {
                                        return (
                                            <>
                                                <tr key={item}>
                                                    <td><span>№ {data.cash_payments.list[item].id}</span></td>
                                                    <td>{data.cash_payments.list[item].brand}</td>
                                                    <td>{data.cash_payments.list[item].closed_from}</td>
                                                    <td>{data.cash_payments.list[item].sum}</td>
                                                    <td>{data.cash_payments.list[item].to_pay}</td>
                                                    <td>{data.cash_payments.list[item].courier}</td>
                                                    <td>{data.cash_payments.list[item].operator}</td>
                                                </tr>

                                                {
                                                    (Object.keys(data.cash_payments.list).length - 1 == index) && (
                                                        <tr key={item + 1}>
                                                            <td><span>Всього</span></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{Object.keys(data.cash_payments.list).reduce((accumulator, currentValue) => accumulator + Number(data.cash_payments.list[currentValue].sum || 0), 0)}</td>
                                                            <td>{Object.keys(data.cash_payments.list).reduce((accumulator, currentValue) => accumulator + (data.cash_payments.list[currentValue].to_pay || 0), 0)}</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                )
            }

            {
                data?.online_payments.list && (
                    <>
                        <br />
                        <table className="js_tables">
                            <tbody>
                                <tr>
                                    <td>
                                        <button className="btn-shows"><ArrowDropDownIcon /></button>
                                        <span>Всього "Безготівка"</span>
                                    </td>
                                    <td><span>Бренд</span></td>
                                    <td><span>Час закриття замовлення</span></td>
                                    <td><span>Сума</span></td>
                                    <td><span>К оплате (сума зі знижкою)</span></td>
                                    <td><span>Курʼєр</span></td>
                                    <td><span>оператор</span></td>
                                </tr>
                                {
                                    Object.keys(data.online_payments.list).map((item, index) => {
                                        return (
                                            <>
                                                <tr key={item}>
                                                    <td><span>№ {data.online_payments.list[item].id}</span></td>
                                                    <td>{data.online_payments.list[item].brand}</td>
                                                    <td>{data.online_payments.list[item].closed_from}</td>
                                                    <td>{data.online_payments.list[item].sum}</td>
                                                    <td>{data.online_payments.list[item].to_pay}</td>
                                                    <td>{data.online_payments.list[item].courier}</td>
                                                    <td>-</td>
                                                </tr>

                                                {
                                                    (Object.keys(data.online_payments.list).length - 1 == index) && (
                                                        <tr key={item + 1}>
                                                            <td><span>Всього</span></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{Object.keys(data.online_payments.list).reduce((accumulator, currentValue) => accumulator + Number(data.online_payments.list[currentValue].sum || 0), 0)}</td>
                                                            <td>{Object.keys(data.online_payments.list).reduce((accumulator, currentValue) => accumulator + (data.online_payments.list[currentValue].to_pay || 0), 0)}</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                )
            }

            {
                data?.courier_payments.list && (
                    <>
                        {
                            Object.keys(data?.courier_payments.list).map((item, index) => {
                                return (
                                    <>
                                        <br />
                                        <table className="js_tables" key={index}>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <button className="btn-shows"><ArrowDropDownIcon /></button>
                                                        <span>Кур. {item}</span>
                                                    </td>
                                                </tr>
                                                {
                                                    Object.keys(data?.courier_payments.list[item].list).map((itemRow, indexR) => {
                                                        return (
                                                            <>
                                                                <tr key={indexR}>
                                                                    <td>Тип оплати: <span>{itemRow}</span></td>
                                                                    <td>
                                                                        <span>Всього: </span> <br />
                                                                        Сума: {data?.courier_payments.list[item].list[itemRow]?.total?.sum || ''} <br />
                                                                        Кількість: {data?.courier_payments.list[item].list[itemRow]?.total?.amount || ''}
                                                                    </td>
                                                                </tr>

                                                                {
                                                                    Object.keys(data?.courier_payments.list[item].list[itemRow].list).map((itemOrder, indexOeder) => {
                                                                        const itemO = data?.courier_payments.list[item].list[itemRow].list[itemOrder];

                                                                        return (
                                                                            <tr key={indexOeder}>
                                                                                <td>№ <span>{itemO.id}</span></td>
                                                                                <td>
                                                                                    <span>Бренд: </span> {itemO.brand}<br />
                                                                                    <span>Закриття: </span> {itemO.closed_from}<br />
                                                                                    <span>Оператор: </span> {itemO.operator}<br />
                                                                                    <span>Сума: </span> {itemO.sum}<br />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </>
                                )
                            })
                        }
                        <br />
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={3}><span>Всього по КУРʼЄРАМ</span></td>
                                </tr>
                                <tr>
                                    <td><span>Заказов</span></td>
                                    <td><span>Сума</span></td>
                                    <td><span>Бонуси</span></td>
                                </tr>
                                <tr>
                                    <td>{data?.courier_payments.total?.amount || ''}</td>
                                    <td>{data?.courier_payments.total.sum}</td>
                                    <td>{data?.courier_payments.total?.bonus || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )
            }
        </div>
    )
});