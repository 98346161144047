import { connect } from "react-redux";

import * as statisticActions from "store/actions/statistic";
import { default as SelfPickupPage } from "./SelfPickup";

function mapStateToProps(state) {
    const {
        statistic: {
            self: {
                filter,
                orders,
            }
        },
        user: {
            user
        },
        settings: {
            kitchens,
        },
        view,
    } = state;

    return {
        user,
        filter,
        orders,
        kitchens,
        view,
    };
}

const mapDispatchToProps = {
    ...statisticActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfPickupPage);
