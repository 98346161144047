import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import history from "constants/history";

import Navigation from "./navigation/Navigation";
import Notifications from "components/notifications";

const CommonLayout = ({
  children,
  user,
  tabs,
  logoutButton
}) => {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(null);

  useEffect(
    () => setActiveTab(history?.location.pathname),
    [history?.location?.pathname]
  );

  return (
    <>
      <Navigation
        user={user}
        tabs={tabs}
        activeTab={activeTab}
        logout={logoutButton}
      />
      <Container theme={theme}>{children}</Container>
      <Notifications />
    </>
  );
};

const Container = styled.section`
  padding: 58px 14px 14px 14px;
  min-height: calc(100vh - 50px);
`;

export default React.memo(CommonLayout);
