import api from "../../apiSingleton";

import {
    updateModalCourirers
} from "./view";

import {
    GET_COURIERS
} from "../constants/couriers";

import {
    addNotification
} from './notifications';

export function getCouriers() {
    return async (dispatch, state) => {
        const { user: {
            user: {
                kitchen_code
            }
        } } = state();

        await dispatch(updateModalCourirers('loadingsModal', true));

        const res = await api.couriers.getCouriers(kitchen_code);

        if (res?.errors) {
            await dispatch(addNotification(res.errors, 'error'));
        } else {
            await dispatch({
                type: GET_COURIERS,
                payload: res.data
            });
        }

        await await dispatch(updateModalCourirers('loadingsModal', false));
    };
}