import { useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingModal } from "components/loadingOrder/loading";

import { validateBalckReason } from "utils/validation";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from '@mui/icons-material/Close';
import styles from "../../scss/settings.module.scss";

export const ReasonEdit = ({
    data,
    view,
    onSubmit,
    onSubmitUpdate,
    handleSubmitClose,
    updateFormReason,
}) => {
    const [error, setErrors] = useState({
        title: "",
    });

    let titleButton = data?.id ? 'Змінити' : 'Додати';
    let title = data?.id ? `Редагування "${data.title}"` : "Строврення нового";

    const handlerSubmit = () => {
        validateBalckReason({
            data: {
                ...data
            },

            onSuccess: async () => {
                if (data?.id) {
                    onSubmitUpdate(data?.id);
                } else {
                    onSubmit();
                }

                setErrors({
                    name: "",
                });
            },

            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    return (
        <div className={styles.row_mrt}>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>{title}</div>
            <div className={styles.wr_drop}>
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.title}
                    isStyle={true}
                    error={error.title}
                    onChange={(e) => updateFormReason("title", e.target.value)}
                />
            </div>
            <div className={styles.row_btn_tow}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handlerSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmitClose}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>
            {
                view.isLoadGeneral && (
                    <LoadingModal />
                )
            }
        </div>
    )
}

