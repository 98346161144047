import { set } from 'lodash';
import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as TypesOfDeliveryPage } from './TypesOfDelivery';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        list: settings.typesOfDelivery.list || [],
        fotmEditTypeOfDelivery: settings.editTypeOfDelivery,
        view,
        payments: settings.payment,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TypesOfDeliveryPage);
