import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import ButtonIconLink from "components/ui-kit/ButtonIconLink"

const ModalDetalClient = ({
    // props
    isOpen = false,
    isLoad = false,
    data = {},
    onCloseModal = () => { },
}) => {
    const theme = useTheme();

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={onCloseModal}
        >

            {
                !isLoad && (
                    <>
                        <Title>Клієнт №{data.id}</Title>
                        <TextInput
                            title="Ім'я"
                            value={data.name}
                            disabled={true}
                            isStyle={true}
                        />
                        <TextInput
                            title="Телефон"
                            value={data.phone}
                            disabled={true}
                            isStyle={true}
                        />
                        <TextArea
                            title="Коментар"
                            disabled={true}
                            isStyle={true}
                            value={data.comment || ""}
                        />
                        <br />
                        <ButtonIconLink
                            title="Всі замовлення"
                            href={`/client-orders/${data.id}`}
                            target="_blank"
                        />
                    </>
                )
            }

            {
                isLoad && (
                    <LoadingCircl mins={true} />
                )
            }
        </ModalLayout>
    )
}

const Title = styled.div`
    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

export default ModalDetalClient;