/*  camelcase */
import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as orderActions from "store/actions/order";
import * as listingsActions from "store/actions/listings";

import { default as CallcenterPageClosed } from "./Closed";

function mapStateToProps(state) {
  const { user, view } = state;
  const {
    orders,
    searchValue,
    statusSearch,
  } = state.listings;

  return {
    user,
    orders,
    isRedactionOrder: view.isRedactionOrder,
    searchValue,
    statusSearch
  };
}

const mapDispatchToProps = {
  ...orderActions,
  ...viewActions,
  ...listingsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallcenterPageClosed);
