import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as usersActions from "store/actions/users";

import { default as ModalFilterUsers } from "./ModalFilterUsers";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalfiilter.isOpen,
        kitchens: state.settings.kitchens,
        cities: state.settings.cities,
        roles: state.settings.roles,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...usersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterUsers);
