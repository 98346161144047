/*  camelcase */
import produce from "immer";

import { LOGOUT } from "../constants/session";
import {
  CLEAR_EDITED_USER,
  CLEAR_USERS,
  UPDATE_FULL_USERS,
  UPDATE_USERS,
  UPDATE_USER_SEARCH_FORM,
  UPDATE_FORM_USER,
  GET_USER,
  UPDATE_FORM_USER_LICO_ID
} from "../constants/users";

const initialState = {
  users: null,
  fullUsers: null,
  editedUser: {
    first_name: "",
    last_name: "",
    phone: "",
    status: "active",
    locations: [],
    product_types: [],
    kitchens: [],
    kitchens_statistic: [],
    kitchens_statistic_status: 'own',
    kitchens_sales_status: 'own',
    kitchens_sales: [],
    users_by_kitchen: "all",
    available_users: "all",
    city_sync_id: '',
    statistics_pages: [],
    // transport_type: '';
  },
  searchForm: {
    phone: '',
    name: '',
  }
};

export default produce((draft, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_FORM_USER_LICO_ID: {
      draft.editedUser.iiko = { ...draft.editedUser.iiko, iiko_id: payload };
      break;
    }

    case GET_USER: {
      return {
        ...draft,
        editedUser: { ...draft.editedUser, ...payload }
      }
      break;
    }

    case UPDATE_FORM_USER: {
      draft.editedUser[payload.item] = payload.data;
      break;
    }

    case UPDATE_USERS: {
      draft.users = payload.users;
      break;
    }

    case UPDATE_FULL_USERS: {
      draft.fullUsers = payload.fullUsers;
      break;
    }

    case CLEAR_EDITED_USER: {
      draft.editedUser = initialState.editedUser;
      break;
    }

    case UPDATE_USER_SEARCH_FORM:
      draft.searchForm[payload.item] = payload.data;
      break;

    case LOGOUT:
    case CLEAR_USERS: {
      draft.users = null;
      break;
    }

    default:
      break;
  }
}, initialState);
