import produce from "immer";

import {
    GET_ORDERS_BY_STATUS_FOR_A_CHEF,
    GET_COOKS_IN_THE_KITCHEN,
    UPDATE_MODAL_COOKS,
    UPDATE_FORM_COOKS,
    FORM_COOKS_CLEAN_UP
} from '../constants/cook';

const initialState = {
    orders: {},
    cooksInTheKitchen: [],
    modalCooks: {
        isOpen: false,
        isLoad: false,
    },
    formCooks: [],
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case GET_ORDERS_BY_STATUS_FOR_A_CHEF: {
            draft.orders = payload;
            break;
        }

        case GET_COOKS_IN_THE_KITCHEN: {
            draft.cooksInTheKitchen = payload;
            break;
        }

        case UPDATE_MODAL_COOKS: {
            draft.modalCooks[payload.item] = payload.data;
            break;
        }

        case UPDATE_FORM_COOKS: {
            let index = draft.formCooks.findIndex(el => el === payload);

            if (index != -1) {
                draft.formCooks.splice(index, 1);
            } else {
                draft.formCooks.push(payload);
            }

            break;
        }

        case FORM_COOKS_CLEAN_UP: {
            draft.formCooks = initialState.formCooks;
        }

        default:
            break;
    }
}, initialState);
