export const ROOT = "/";
export const LOGIN = `${ROOT}login`;
export const STATISTICS = `${ROOT}statistic`;
export const STATISTICSDETAIL = `${ROOT}statistic/:id`;
export const USERS = `${ROOT}users`;
export const PRODUCTS = `${ROOT}products`;
export const PRODUCT = `${PRODUCTS}/:id`;
export const CALLCENTER = `${ROOT}callcenter`;
export const CALLCENTER_CLOSED = `${CALLCENTER}/closed`;
export const CALLCENTER_REJECTED = `${CALLCENTER}/rejected`;
export const COOK = `${ROOT}cook`;
export const KITCHEN = `${ROOT}kitchen`;
export const KITCHEN_CLOSE = `${KITCHEN}/closed`;
export const COURIER = `${ROOT}courier`;
export const CLIENTS = `${ROOT}clients`;
export const CLIENT_ORDERS = `${ROOT}client-orders/:id`;
export const SETTINGS = `${ROOT}settings`;
export const REPORTS = `${ROOT}reports`;
export const STATISTICS_SALES = `${ROOT}sales`;

