import { isArray } from "lodash";
import React from "react";

import { LoadingModal } from "components/loadingOrder/loading";
import { ItemRow } from "./itemRow";
import { TableHead } from "./TableHead";

export const ReportsTable = ({
    data = null,
    activeSelect = null,
    isLoadPage,
}) => {
    return (
        <>
            <div className="tables-report">
                {
                    !isLoadPage && (
                        <>
                            <TableHead
                                activeSelect={activeSelect}
                            />
                            <div className="report-wr-row">
                                <div className="report-table">
                                    <ItemRow
                                        data={data}
                                    />

                                    {
                                        isArray(data) && data.length == 0 && (
                                            <div className="empty-list">Пусто</div>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    )
                }
            </div>

            {
                isLoadPage && (
                    <div style={{ height: '150px', minWidth: "700px", position: 'relative' }}>
                        <LoadingModal />
                    </div>
                )
            }
        </>
    )
}