import PropTypes from "prop-types";
import { isArray } from "lodash";
import {
    useRouteMatch,
    useHistory
} from "react-router-dom";
import { useEffect } from "react";

import HeaderKitchen from "components/headers/HeaderKitchen";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import Table from "components/ui-kit/Table";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import AddIcon from "@mui/icons-material/Add";
import styles from "./../scss/settings.module.scss";

import { tableRestaurants } from "constants/tables";

const Restaurants = ({
    view = {},
    restaurants = [],
    //dispatch
    getSettingsRestaurants = () => { },
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();

    useEffect(() => {
        getSettingsRestaurants();
    }, []);

    const handlerEdit = (data) => {
        history.push(`${path}/${data.code}`);
    }

    const handlerCreate = () => {
        history.push(`${path}/new`);
    }

    return (
        <div>
            <div className={styles.wr_head}>
                <ButtonIcon
                    onClick={handlerCreate}
                    title='Додати ресторан'
                    icon={<AddIcon />}
                    positionIcon="left"
                />
            </div>
            <div className={styles.wr_row}>
                {
                    !view?.isLoadPage ? (
                        isArray(restaurants) && (
                            <Table
                                onClick={handlerEdit}
                                data={restaurants}
                                scheme={tableRestaurants}
                            />
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </div>
        </div>
    )
}

HeaderKitchen.propTypes = {
    view: PropTypes.object,
    restaurants: PropTypes.array,
    getSettingsRestaurants: PropTypes.func,
};

export default Restaurants;