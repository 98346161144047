import { isArray } from "lodash";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import moment from 'moment';

const TIME_DEFAULT = {
  time: '00:00',
  format: 'HH:mm'
};

export const TabHistory = () => {
  const theme = useTheme();
  const { history } = useSelector((state) => state.order.data);
  const { orderStatuses } = useSelector((state) => state.settings);

  return (
    <Wrapper theme={theme}>
      <ListHistory>
        {
          isArray(orderStatuses) && orderStatuses.map((item, index) => {
            let obj = isArray(history) && history.find((itemTime) => itemTime.status === item.name);
            let time = "";
            let timeStatus = false;
            let name = "";

            if (obj) {
              time = moment(obj?.set_at).format(TIME_DEFAULT.format);
              name = `${obj.user.first_name} ${obj.user.last_name}`;
              timeStatus = true;
            } else {
              time = TIME_DEFAULT.time;
            }

            return (
              <HistoryItem key={index}>
                <HistoryItemTitle>{item.title}</HistoryItemTitle>
                <HistoryName>{name}</HistoryName>
                <HistoryItemTime active={timeStatus}>{time}</HistoryItemTime>
              </HistoryItem>
            )
          })
        }
      </ListHistory>
    </Wrapper>
  );
};

const Wrapper = styled.div`
`;

const ListHistory = styled.ul`
`;

const HistoryItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(p) => p.theme.secondaryColor};
  padding: 10px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.maskDarkColor};
  }
`;

const HistoryItemTitle = styled.span`
  min-width: 130px;
`;

const HistoryName = styled.div`
  font-size: 14px;
  max-width: 150px;
  width: 150px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
`;

const HistoryItemTime = styled.span`
  margin-left: 10px;
  color: ${(p) => p.active ? '#fff' : '#9D9D9D'};
  text-decoration: underline;
  font-size: 14px;
  width: 50px;
`;