import { isArray, isString } from "lodash";

export const saleMetodMapped = (arr = []) => {
    let mewArray = [];

    if (isArray(arr))
        arr?.map((item) => {
            mewArray.push({ title: item, value: item });
        });

    return mewArray;
};

export const saleMetodMappedDrop = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item, title: item });
    });

    return mewArray;
}

export const saleMetodMappedSelect = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item, label: item });
    });

    return mewArray;
}
