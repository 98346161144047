import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import CancelIcon from "@mui/icons-material/Cancel";

const ModalDelete = ({
    // props
    title = '',
    text = '',
    isOpen = false,
    isLoad = false,
    updateModalDelete = () => { },
    funDelete = () => { },
    onCloseModal = () => { },
}) => {
    const theme = useTheme();

    const handlerCloseModal = async () => {
        await updateModalDelete('isOpen', false);
        onCloseModal();
    }

    const handleSubmit = () => {
        funDelete();
    }

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={handlerCloseModal}
        >
            {
                title && (
                    <Title>{title}</Title>
                )
            }

            {
                text && (
                    <Text>{text}</Text>
                )
            }

            <RowBtn>
                {
                    !isLoad && (
                        <>
                            <Item>
                                <ButtonIcon
                                    onClick={handleSubmit}
                                    title={'Підтвердити'}
                                    colorBg="green"
                                />
                            </Item>
                            <Item>
                                <ButtonIcon
                                    onClick={handlerCloseModal}
                                    title='Скасувати'
                                    colorBg="red"
                                    icon={<CancelIcon />}
                                />
                            </Item>
                        </>
                    )
                }
            </RowBtn>

            {
                isLoad && (
                    <LoadingCircl mins={true} />
                )
            }
        </ModalLayout>
    )
}

const Title = styled.div`
    text-align: center;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const Text = styled.div`
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
    padding: 0 20px;
`;

const RowBtn = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
`;

const Item = styled.div`
    padding: 0 10px;
`;


export default ModalDelete;