import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as complaintPage } from './Complaint';

function mapStateToProps(state) {
    const {
        settings: {
            complaints: {
                complaint: {
                    isLoadPage,
                    isLoadModal,
                    list,
                    form
                }
            }
        }
    } = state;

    return {
        isLoadPage,
        isLoadModal,
        list,
        form,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(complaintPage);
