import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { isArray } from "lodash";

import Back from "components/ui-kit/Back";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Radio from "components/ui-kit/Radio";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import { LoadingModal } from "components/loadingOrder/loading";
import InputPhone from "components/ui-kit/Modals/InputPhone";

import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from "./../../scss/settings.module.scss";

import { citiesMapped } from "utils/mappers/cities";
import { validateKitchen } from "utils/validation";
import { hour, getMinute, getHour, minute } from "utils/helpers/time";

import { hours, minutes, deliveryTime } from "constants/watch";
import { RADIO_SCHEME, RADIO_SCHEME_PICKUP, RADIO_SCHEME_CHANGES } from "constants/schemes";

const ItemPolygon = ({
    // props
    data,
    index,
    length,
    updateKitchenFormPolygons = () => { },
    deleteKitchenPolygon
}) => {
    return (
        <div className={styles.item_paylogn}>
            <div className={styles.item_paylogn_left}>
                <TextArea
                    title="Поле для кординат полігону"
                    isStyle={true}
                    height="150px"
                    onChange={(e) => updateKitchenFormPolygons(index, "path", e.target.value)}
                    value={data.path}
                />

                <div className={styles.wr_drop}>
                    <div className={styles.title_in}>Години відкритя</div>
                    <div className={styles.row_form}>
                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                            <Dropdown
                                isBlack={true}
                                width="100%"
                                onChange={(e) => updateKitchenFormPolygons(index, "start_time", hour(e.target.value, data.start_time))}
                                value={getHour(data.start_time)}
                                list={hours}
                                isLight={true}
                                fontSize={'14px'}
                            />
                        </div>
                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                            <Dropdown
                                isBlack={true}
                                width="100%"
                                onChange={(e) => updateKitchenFormPolygons(index, "start_time", minute(e.target.value, data.start_time))}
                                value={getMinute(data.start_time)}
                                list={minutes}
                                isLight={true}
                                fontSize={'14px'}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.wr_drop}>
                    <div className={styles.title_in}>Години закриття</div>
                    <div className={styles.row_form}>
                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                            <Dropdown
                                isBlack={true}
                                width="100%"
                                onChange={(e) => updateKitchenFormPolygons(index, "finish_time", hour(e.target.value, data.finish_time))}
                                value={getHour(data.finish_time)}
                                list={hours}
                                isLight={true}
                                fontSize={'14px'}
                            />
                        </div>
                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                            <Dropdown
                                isBlack={true}
                                width="100%"
                                onChange={(e) => updateKitchenFormPolygons(index, "finish_time", minute(e.target.value, data.finish_time))}
                                value={getMinute(data.finish_time)}
                                list={minutes}
                                isLight={true}
                                fontSize={'14px'}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.wr_drop}>
                    <div className={styles.title_in}>Час доставки</div>
                    <div className={styles.row_form}>
                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                            <Dropdown
                                isBlack={true}
                                width="100%"
                                onChange={(e) => updateKitchenFormPolygons(index, "delivery_time", e.target.value)}
                                value={data.delivery_time}
                                list={deliveryTime}
                                isLight={true}
                                fontSize={'14px'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.item_paylogn_right}>
                {
                    length > 1 && (
                        <button className={styles.btn_delete} title="Видалити" onClick={() => deleteKitchenPolygon(index)}>
                            <CancelIcon />
                        </button>
                    )
                }
            </div>
        </div>
    )
}

const Kitchen = ({
    // state
    data,
    cities,
    isLoadPage,
    //dispatch
    updateKitchenForm,
    updateKitchenFormShiftFirst,
    updateKitchenFormShiftLast,
    updateKitchenFormPolygons,
    addKitchenPolygon,
    deleteKitchenPolygon,
    createKitchen,
    updateKitchen,
    getKitchen,
    cleaningNewForm
}) => {
    const [error, setErrors] = useState({
        title: "",
        address: "",
        city_sync_id: "",
        latitude: "",
        longitude: "",
        time_open: "",
        time_close: "",
        sale_employee: "",
        create_cashless_checks_to: "",
        create_cash_checks_to: ""
    });
    const [changes, setChanges] = useState(RADIO_SCHEME_CHANGES[0].value);
    const { shifts } = data;
    const shiftFirst = shifts[0];
    const shiftLast = shifts[1];

    let { code } = useParams();
    let history = useHistory();

    let titleButton = data?.id ? 'Змінити' : 'Створити';

    useEffect(() => {
        if (code !== "new")
            getKitchen(code);

        if (code == "new")
            cleaningNewForm('editKitchens');

        let itemsBlocks = document.querySelectorAll('.wr-block');

        itemsBlocks.forEach(item => {
            item.querySelector('.wr-block-title').addEventListener('click', function () {
                let isOpen = item.classList.contains('open');

                if (!isOpen) item.classList.add('open');
                if (isOpen) item.classList.remove('open');
            });
        })

        return;
    }, []);

    const handlerUpadatUrl = (code) => {
        history.push(`/settings/kitchens/${code}`);
    }

    const handleSubmit = () => {
        validateKitchen({
            data: {
                title: data.title,
                address: data.address,
                city_sync_id: data.city_sync_id,
                latitude: data.latitude,
                longitude: data.longitude,
                time_open: data.time_open,
                time_close: data.time_close,
                sale_employee: data.sale_employee,
                create_cashless_checks_to: data.create_cashless_checks_to,
                create_cash_checks_to: data.create_cash_checks_to
            },

            onSuccess: async (validData) => {
                if (data?.id) {
                    updateKitchen();
                } else {
                    createKitchen(handlerUpadatUrl);
                }

                setErrors({
                    title: "",
                    address: "",
                    city_sync_id: "",
                    latitude: "",
                    longitude: "",
                    time_open: "",
                    time_close: "",
                    sale_employee: "",
                    create_cashless_checks_to: "",
                    create_cash_checks_to: ""
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    const handleCreatePoligon = () => {
        addKitchenPolygon();
    }

    return (
        <div className={`${styles.d_max}`}>
            <Back />

            <div className={`${styles.title_kitchen}`}>Кухня: <span>{data.title}</span></div>

            <div className={`${styles.d_grid}  ${styles.col_2} ${styles.block}`}>
                <div className={styles.left}>
                    <div className={styles.form}>
                        <div className={`${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Загальне <span><KeyboardArrowDownIcon /></span></div>
                            <div className="wr-block-content">
                                <div className={`${styles.row_form} ${styles.row_form_flex_end}`}>
                                    <div className={`${styles.item_two}`}>
                                        <TextInput
                                            title="Назва"
                                            type="text"
                                            value={data.title}
                                            isStyle={true}
                                            error={error.title}
                                            onChange={(e) => updateKitchenForm("title", e.target.value)}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title="Місто"
                                            error={error.city_sync_id}
                                            isBlack={true}
                                            width="100%"
                                            onChange={(e) => updateKitchenForm("city_sync_id", e.target.value)}
                                            value={data.city_sync_id}
                                            list={citiesMapped(cities)}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.row_form} ${styles.row_form_flex_end}`}>
                                    <div className={`${styles.item_two}`}>
                                        <InputPhone
                                            title='Номер телефону'
                                            type='text'
                                            isStyle={true}
                                            value={data?.phone}
                                            onChange={(value) => updateKitchenForm("phone", value)}
                                            width="100%"
                                        />
                                    </div>
                                    <div className={`${styles.item_two}`}>
                                        <TextInput
                                            title="Адреса"
                                            error={error.address}
                                            type="text"
                                            value={data.address}
                                            isStyle={true}
                                            onChange={(e) => updateKitchenForm("address", e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.wr_drop}>
                                    <Radio
                                        colors={"#000"}
                                        items={RADIO_SCHEME}
                                        label="Активність кухні"
                                        valueKey="status"
                                        value={data.status}
                                        onChange={(e) => updateKitchenForm("status", +e.value.value)}
                                    />
                                </div>
                                <div className={styles.wr_drop}>
                                    <Radio
                                        colors={"#000"}
                                        items={RADIO_SCHEME_PICKUP}
                                        label="Самовивіз ?"
                                        valueKey="pickup"
                                        value={data.pickup}
                                        onChange={(e) => updateKitchenForm("pickup", +e.value.value)}
                                    />
                                </div>
                                <TextInput
                                    title="% Знижка для працівників"
                                    type="number"
                                    max={100}
                                    min={0}
                                    value={data.sale_employee}
                                    isStyle={true}
                                    error={error.sale_employee}
                                    onChange={(e) => updateKitchenForm("sale_employee", e.target.value)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className={`${styles.wr_drop} ${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Години відкритя кухні <span><KeyboardArrowDownIcon /></span></div>
                            <div className="wr-block-content">
                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title={"Години"}
                                            isBlack={true}
                                            width="100%"
                                            error={error.time_open}
                                            onChange={(e) => updateKitchenForm("time_open", hour(e.target.value, data.time_open))}
                                            value={getHour(data.time_open)}
                                            list={hours}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title="Хвилини"
                                            isBlack={true}
                                            width="100%"
                                            error={error.time_open}
                                            onChange={(e) => updateKitchenForm("time_open", minute(e.target.value, data.time_open))}
                                            value={getMinute(data.time_open)}
                                            list={minutes}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={`${styles.wr_drop} ${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Години закриття кухні <span><KeyboardArrowDownIcon /></span></div>
                            <div className="wr-block-content">
                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title={"Години"}
                                            isBlack={true}
                                            width="100%"
                                            error={error.time_close}
                                            onChange={(e) => updateKitchenForm("time_close", hour(e.target.value, data.time_close))}
                                            value={getHour(data.time_close)}
                                            list={hours}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title="Хвилини"
                                            isBlack={true}
                                            width="100%"
                                            error={error.time_close}
                                            onChange={(e) => updateKitchenForm("time_close", minute(e.target.value, data.time_close))}
                                            value={getMinute(data.time_close)}
                                            list={minutes}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={`${styles.wr_drop} ${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Кординати <span><KeyboardArrowDownIcon /></span></div>
                            <div className="wr-block-content">
                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <TextInput
                                            title="Широта"
                                            type="number"
                                            error={error.latitude}
                                            value={data.latitude}
                                            isStyle={true}
                                            onChange={(e) => updateKitchenForm("latitude", e.target.value)}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <TextInput
                                            title="Довгота"
                                            type="number"
                                            error={error.longitude}
                                            value={data.longitude}
                                            isStyle={true}
                                            onChange={(e) => updateKitchenForm("longitude", e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={`${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Фіскальний реєстратор <span><KeyboardArrowDownIcon /></span></div>
                            <div className="wr-block-content">
                                <div className={styles.wr_drop}>
                                    <Radio
                                        colors={"#000"}
                                        items={RADIO_SCHEME_CHANGES}
                                        label="Налаштування по змінах"
                                        value={changes}
                                        onChange={(e) => setChanges(e.value.value)}
                                    />
                                </div>
                                {
                                    (changes == RADIO_SCHEME_CHANGES[0].value) && (
                                        <div className={styles.wr_drop}>
                                            <div className={styles.title_in}>Перша зміна</div>
                                            {
                                                !!shiftFirst?.status && (
                                                    <>
                                                        <div className={styles.wr_drop}>
                                                            <TextInput
                                                                title="Касир"
                                                                value={shiftFirst.cashier_name}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashier_name", e.target.value)}
                                                            />
                                                            <br />
                                                            <div className={styles.title_in_m}>Початок зміни</div>
                                                            <div className={styles.row_form}>
                                                                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                                                    <Dropdown
                                                                        isBlack={true}
                                                                        width="100%"
                                                                        onChange={(e) => updateKitchenFormShiftFirst("open_time", hour(e.target.value, shiftFirst.open_time))}
                                                                        value={getHour(shiftFirst.open_time)}
                                                                        list={hours}
                                                                        isLight={true}
                                                                        fontSize={'14px'}
                                                                    />
                                                                </div>
                                                                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                                                    <Dropdown
                                                                        isBlack={true}
                                                                        width="100%"
                                                                        onChange={(e) => updateKitchenFormShiftFirst("open_time", minute(e.target.value, shiftFirst.open_time))}
                                                                        value={getMinute(shiftFirst.open_time)}
                                                                        list={minutes}
                                                                        isLight={true}
                                                                        fontSize={'14px'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.wr_drop}>
                                                            <div className={styles.title_in_m}>Кінець зміни</div>
                                                            <div className={styles.row_form}>
                                                                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                                                    <Dropdown
                                                                        isBlack={true}
                                                                        width="100%"
                                                                        onChange={(e) => updateKitchenFormShiftFirst("close_time", hour(e.target.value, shiftFirst.close_time))}
                                                                        value={getHour(shiftFirst.close_time)}
                                                                        list={hours}
                                                                        isLight={true}
                                                                        fontSize={'14px'}
                                                                    />
                                                                </div>
                                                                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                                                    <Dropdown
                                                                        isBlack={true}
                                                                        width="100%"
                                                                        onChange={(e) => updateKitchenFormShiftFirst("close_time", minute(e.target.value, shiftFirst.close_time))}
                                                                        value={getMinute(shiftFirst.close_time)}
                                                                        list={minutes}
                                                                        isLight={true}
                                                                        fontSize={'14px'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.wr_drop}>
                                                            <TextInput
                                                                title="Номер ПРРО"
                                                                value={shiftFirst.cashalot_numfiscal_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashalot_numfiscal_shift", e.target.value)}
                                                            />
                                                            <TextInput
                                                                title="Сертифікат в форматі Base64"
                                                                value={shiftFirst.cashalot_certificate_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashalot_certificate_shift", e.target.value)}
                                                            />
                                                        </div>
                                                        <br />
                                                        <div className={styles.wr_drop}>
                                                            <div className={styles.title_in}>Ключи для кашалота</div>
                                                            <TextInput
                                                                title="Приватний ключ для кашалота"
                                                                value={shiftFirst.cashalot_private_key_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashalot_private_key_shift", e.target.value)}
                                                            />
                                                            <TextInput
                                                                title="Пароль"
                                                                value={shiftFirst.cashalot_password_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftFirst("cashalot_password_shift", e.target.value)}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <br />
                                            <div className={styles.wr_drop}>
                                                <Radio
                                                    colors={"#000"}
                                                    items={RADIO_SCHEME}
                                                    label="Активність зміни"
                                                    valueKey="status"
                                                    value={shiftFirst?.status}
                                                    onChange={(e) => updateKitchenFormShiftFirst("status", +e.value.value)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (changes == RADIO_SCHEME_CHANGES[1].value) && (
                                        <div className={styles.wr_drop}>
                                            <div className={styles.title_in}>Друга зміна</div>
                                            {
                                                !!shiftLast?.status && (
                                                    <>
                                                        <br />
                                                        <div className={styles.wr_drop}>
                                                            <TextInput
                                                                title="Касир"
                                                                value={shiftLast.cashier_name}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashier_name", e.target.value)}
                                                            />
                                                            <br />
                                                            <div className={styles.title_in_m}>Початок зміни</div>
                                                            <div className={styles.row_form}>
                                                                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                                                    <Dropdown
                                                                        isBlack={true}
                                                                        width="100%"
                                                                        onChange={(e) => updateKitchenFormShiftLast("open_time", hour(e.target.value, shiftLast.open_time))}
                                                                        value={getHour(shiftLast.open_time)}
                                                                        list={hours}
                                                                        isLight={true}
                                                                        fontSize={'14px'}
                                                                    />
                                                                </div>
                                                                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                                                    <Dropdown
                                                                        isBlack={true}
                                                                        width="100%"
                                                                        onChange={(e) => updateKitchenFormShiftLast("open_time", minute(e.target.value, shiftLast.open_time))}
                                                                        value={getMinute(shiftLast.open_time)}
                                                                        list={minutes}
                                                                        isLight={true}
                                                                        fontSize={'14px'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.wr_drop}>
                                                            <div className={styles.title_in_m}>Кінець зміни</div>
                                                            <div className={styles.row_form}>
                                                                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                                                    <Dropdown
                                                                        isBlack={true}
                                                                        width="100%"
                                                                        onChange={(e) => updateKitchenFormShiftLast("close_time", hour(e.target.value, shiftLast.close_time))}
                                                                        value={getHour(shiftLast.close_time)}
                                                                        list={hours}
                                                                        isLight={true}
                                                                        fontSize={'14px'}
                                                                    />
                                                                </div>
                                                                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                                                    <Dropdown
                                                                        isBlack={true}
                                                                        width="100%"
                                                                        onChange={(e) => updateKitchenFormShiftLast("close_time", minute(e.target.value, shiftLast.close_time))}
                                                                        value={getMinute(shiftLast.close_time)}
                                                                        list={minutes}
                                                                        isLight={true}
                                                                        fontSize={'14px'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.wr_drop}>
                                                            <TextInput
                                                                title="Номер ПРРО"
                                                                value={shiftLast.cashalot_numfiscal_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashalot_numfiscal_shift", e.target.value)}
                                                            />
                                                            <TextInput
                                                                title="Сертифікат в форматі Base64"
                                                                value={shiftLast.cashalot_certificate_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashalot_certificate_shift", e.target.value)}
                                                            />
                                                        </div>
                                                        <br />
                                                        <div className={styles.wr_drop}>
                                                            <div className={styles.title_in}>Ключи для кашалота</div>
                                                            <TextInput
                                                                title="Приватний ключ для кашалота"
                                                                value={shiftLast.cashalot_private_key_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashalot_private_key_shift", e.target.value)}
                                                            />
                                                            <TextInput
                                                                title="Пароль"
                                                                value={shiftLast.cashalot_password_shift}
                                                                isStyle={true}
                                                                onChange={(e) => updateKitchenFormShiftLast("cashalot_password_shift", e.target.value)}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            <div className={styles.wr_drop}>
                                                <Radio
                                                    colors={"#000"}
                                                    items={RADIO_SCHEME}
                                                    label="Активність зміни"
                                                    valueKey="status"
                                                    value={shiftLast?.status}
                                                    onChange={(e) => updateKitchenFormShiftLast("status", +e.value.value)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                <div className={`${styles.title_in} ${styles.title_in_2}`}>Готівка: (від часу відкриття) + датепікер тільки години (Час до якого працює)</div>

                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title={"Години"}
                                            isBlack={true}
                                            width="100%"
                                            error={error.create_cash_checks_to}
                                            onChange={(e) => updateKitchenForm("create_cash_checks_to", hour(e.target.value, data.create_cash_checks_to))}
                                            value={getHour(data.create_cash_checks_to)}
                                            list={hours}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title="Хвилини"
                                            isBlack={true}
                                            width="100%"
                                            error={error.create_cash_checks_to}
                                            onChange={(e) => updateKitchenForm("create_cash_checks_to", minute(e.target.value, data.create_cash_checks_to))}
                                            value={getMinute(data.create_cash_checks_to)}
                                            list={minutes}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                </div>

                                <div className={`${styles.title_in} ${styles.title_in_2}`}>Безготівка: (від часу відкриття) + датепікер тільки години (Час до якого працює)</div>

                                <div className={styles.row_form}>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title={"Години"}
                                            isBlack={true}
                                            width="100%"
                                            error={error.create_cashless_checks_to}
                                            onChange={(e) => updateKitchenForm("create_cashless_checks_to", hour(e.target.value, data.create_cashless_checks_to))}
                                            value={getHour(data.create_cashless_checks_to)}
                                            list={hours}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                    <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                        <Dropdown
                                            title="Хвилини"
                                            isBlack={true}
                                            width="100%"
                                            error={error.create_cashless_checks_to}
                                            onChange={(e) => updateKitchenForm("create_cashless_checks_to", minute(e.target.value, data.create_cashless_checks_to))}
                                            value={getMinute(data.create_cashless_checks_to)}
                                            list={minutes}
                                            isLight={true}
                                            fontSize={'14px'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className={`${styles.wr_drop} ${styles.blocke_item} wr-block`}>
                            <div className={`${styles.title_in} wr-block-title`}>Telegram бот для кухні <span><KeyboardArrowDownIcon /></span></div>
                            <div className="wr-block-content">
                                <TextInput
                                    title="Назва бота"
                                    value={data.telegram_bot_name}
                                    isStyle={true}
                                    onChange={(e) => updateKitchenForm("telegram_bot_name", e.target.value)}
                                />
                                <TextInput
                                    title="Токен бота"
                                    value={data.telegram_bot_token}
                                    isStyle={true}
                                    onChange={(e) => updateKitchenForm("telegram_bot_token", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    {
                        isArray(data.polygons) && (
                            data.polygons.map((item, index) => (
                                <ItemPolygon
                                    key={index}
                                    index={index}
                                    length={data.polygons.length}
                                    data={item}
                                    updateKitchenFormPolygons={updateKitchenFormPolygons}
                                    deleteKitchenPolygon={deleteKitchenPolygon}
                                />
                            ))
                        )
                    }
                    <div className={styles.row_btn_pol}>
                        <ButtonIcon
                            onClick={handleCreatePoligon}
                            title='Додати полігон'
                            icon={<AddIcon />}
                            positionIcon="left"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.row_btn}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
            </div>
            {
                isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }
        </div>
    )
}

Kitchen.propTypes = {
    data: PropTypes.object,
    cities: PropTypes.array,
    isLoadPage: PropTypes.bool,
    updateKitchenForm: PropTypes.func,
    addKitchenPolygon: PropTypes.func,
    deleteKitchenPolygon: PropTypes.func,
    createKitchen: PropTypes.func,
    updateKitchen: PropTypes.func,
    getKitchen: PropTypes.func,
    cleaningNewForm: PropTypes.func,
};

export default Kitchen;