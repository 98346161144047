import React, { useEffect } from "react";
import styled from "styled-components";

import ModalEditor from "components/modals/ModalEditor";
import OrdersList from "./OrdersList/index";
import OrdersListObj from "./OrdersListObj";
import ModalFilter from "components/modals/ModalFilter";
import { Header } from "components/orders/Header";
import { HeadOrder } from "components/orders/HeadOrderCallcenter";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { ModalLoad } from "components/modals/ModalLoad";

import { getFiltet } from "utils/processingRequester/getLocalFilterOrders";
import { getData } from "utils/helpers/localStorage";

import CloseIcon from "@mui/icons-material/Close";

const CallcenterPage = ({
  orders,
  updateOrder,
  openEditorCallcenter,
  getAllActiveOrders,
  updateFormSearchOrders,
  handleІSybmitSearchOrders,
  openModalFilter,
  isRedactionOrder,
  redactionOrder,
  closeEditorCallcenter,
  searchValue,
  statusSearch,
  loadPage,
  isOpenModal,
}) => {
  useEffect(() => {
    if (!isOpenModal) {
      getAllActiveOrders(getFiltet());

      const interval = setInterval(() => {
        getAllActiveOrders(getFiltet());
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [statusSearch, isOpenModal]);

  const handleCreateOrder = () => {
    openEditorCallcenter();
    updateOrder();
  };

  const handleShowFilter = () => {
    openModalFilter();
  }

  const handleCloseProhibition = () => {
    redactionOrder(true);
    closeEditorCallcenter();
  }

  const renderOrders = () => {
    return (
      <>
        <View>
          <HeadOrder />
        </View>
        <>
          <OrdersList key="new" data={orders.new || {}} isHidePrice={true} isKitchen={true} title='Нові' />
          <OrdersListObj key="cooking" data={orders.cooking || {}} isKitchen={true} title='Готуються' />
          <OrdersListObj key="preparing" data={orders.preparing || {}} isKitchen={true} title='Пакуються' />
          <OrdersListObj key="for_delivery" data={orders.for_delivery || {}} isKitchen={true} title='Очікують доставки' />
          <OrdersListObj key="on_way" data={orders.on_way || {}} isKitchen={true} title='Доставляються' />
          <OrdersListObj key="delivered" data={orders.delivered || {}} isKitchen={true} title='Доставлені' />
          <OrdersListObj key="pending" data={orders.pending || {}} isKitchen={true} title='Попереднi замовлення' />
        </>
      </>
    );
  };

  return (
    <Wrapper>
      <Header
        handleCreateOrder={handleCreateOrder}
        handleShowFilter={handleShowFilter}
        updateFormSearchOrders={updateFormSearchOrders}
        handleІSybmitSearchOrders={handleІSybmitSearchOrders}
        closedOrders={false}
        closeReject={false}
        searchValue={searchValue}
        statusSearch={statusSearch}
        isAtiveFilterBtn={!!getData('dataFilterCallCenter')}
      />
      {
        orders && (
          <WrOrders>
            {renderOrders()}
          </WrOrders>
        )
      }
      <ModalEditor isKitchen={false} />
      <ModalFilter />
      {
        !isRedactionOrder && (
          <ModalProhibition
            onClick={handleCloseProhibition}
          >
            <ModalView>
              <TextModal>Це замовлення вже принято іншим оператором</TextModal>
              <ButtonIcon
                onClick={handleCloseProhibition}
                title='Закрить'
                icon={<CloseIcon />}
              />
            </ModalView>
          </ModalProhibition>
        )
      }
      {
        loadPage && (
          <ModalLoad />
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

const WrOrders = styled.div`
  margin-top: 24px;
`;

const View = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
  background: #FAFAFA;
  position: sticky;
  top: 104px;
  z-index: 100;
`;

const ModalProhibition = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 20000;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
`;

const ModalView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TextModal = styled.div`
  text-align: center;
  color: #fff;
  font-size: 18px;
  margin: 20px 0;
`;

export default React.memo(CallcenterPage);
