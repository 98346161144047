import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { isArray } from "lodash";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Table from "components/ui-kit/Table";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import { DeliveryEdit } from "./DeliveryEdit";
import ModalDelete from "components/modals/ModalDelete";

import AddIcon from "@mui/icons-material/Add";
import styles from "../scss/settings.module.scss";

import { tableTypeOfDelivery } from "constants/tables";

const TypesOfDelivery = ({
    // state
    view,
    list,
    fotmEditTypeOfDelivery,
    payments,
    // dispatch
    updateFormDelivery,
    getTypesOfDeliveryList,
    getDeliveyId,
    createDelivery,
    updateDeliveryId,
    deleteItemDeliverById
}) => {
    const [isEdit, setIsEdit] = useState({
        status: false,
        id: null,
    });

    const [deleteItem, setDeleteItem] = useState({
        isOpen: false,
        id: undefined,
        isLoad: false,
    });

    useEffect(() => {
        getTypesOfDeliveryList();
    }, []);

    const handlerEdit = async ({ id }) => {
        await setIsEdit({ id, status: true });
        await getDeliveyId(id);
    }

    const handlerCreate = () => {
        setIsEdit((prev) => ({ ...prev, ...{ status: true } }));
    }

    const handleSubmitClose = () => {
        setIsEdit((prev) => ({ ...prev, ...{ status: false } }));
    }

    const onSubmit = async () => {
        createDelivery(handleSubmitClose);
    }

    const onSubmitUpdate = () => {
        updateDeliveryId(isEdit.id, handleSubmitClose);
    }

    const handleDelete = async ({ id }) => {
        setDeleteItem(prev => ({
            ...prev,
            isOpen: true,
            id
        }));
    }

    const closeModalDelete = () => {
        setDeleteItem({
            isOpen: false,
            id: undefined,
            isLoad: false,
        });
    }

    const onDeleteItem = () => {
        deleteItemDeliverById(deleteItem.id, setDeleteItem);
    }

    return (
        <div className={styles.d_max_1023}>
            <div className={styles.wr_head}>
                <ButtonIcon
                    onClick={handlerCreate}
                    title='Додати тип доставки'
                    icon={<AddIcon />}
                    positionIcon="left"
                    disabled={isEdit.status}
                />
            </div>
            <div className={styles.wr_row}>
                {
                    !view?.isLoadPage ? (
                        (isArray(list) && (list.length != 0)) ? (
                            <Table
                                onClick={handlerEdit}
                                onDelete={handleDelete}
                                data={list}
                                scheme={tableTypeOfDelivery}
                            />
                        ) : (
                            <div>Немає типів доставок...</div>
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </div>
            {
                isEdit.status && (
                    <DeliveryEdit
                        data={fotmEditTypeOfDelivery}
                        view={view}
                        onSubmit={onSubmit}
                        handleSubmitClose={handleSubmitClose}
                        onSubmitUpdate={onSubmitUpdate}
                        updateFormDelivery={updateFormDelivery}
                        paymentsData={payments}
                    />
                )
            }

            <ModalDelete
                title={'Видалити ? '}
                text={`Ви підтверджуєте видалення ?`}
                isOpen={deleteItem.isOpen}
                isLoad={deleteItem.isLoad}
                funDelete={onDeleteItem}
                onCloseModal={closeModalDelete}
            />
        </div>
    )
}

TypesOfDelivery.propTypes = {
    view: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
    fotmEditTypeOfDelivery: PropTypes.object.isRequired,
    updateFormDelivery: PropTypes.func.isRequired,
    getTypesOfDeliveryList: PropTypes.func.isRequired,
    getDeliveyId: PropTypes.func.isRequired,
    createDelivery: PropTypes.func.isRequired,
    updateDeliveryId: PropTypes.func.isRequired,
    deleteItemDeliverById: PropTypes.func.isRequired
}

export default TypesOfDelivery;