import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as clientsActions from "store/actions/clients";

import { default as Clients } from "./Clients";

function mapStateToProps(state) {
    const { view, clients } = state;

    return {
        view,
        clients,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...clientsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
