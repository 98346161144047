import styles from './noMatch.module.scss';

const NoMatch = () => {
    return (
        <div className={styles.wr}>
            <div className={styles.content}>
                <div className={styles.error}>404</div>
                <div className={styles.text}>Такої сторінки не знайдено...</div>
            </div>
        </div>
    )
}

export default NoMatch;