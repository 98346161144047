import { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { isArray } from "lodash";

import Back from "components/ui-kit/Back";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import { LoadingModal } from "components/loadingOrder/loading";
import Table from "components/ui-kit/Table";
import ModalDelete from "components/modals/ModalDelete";
import ModalInput from "components/modals/ModalInput";
import ModalFileDownload from "components/modals/ModalFileDownload";

import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import styles from "./../../scss/settings.module.scss";

import { validateCity } from "utils/validation";
import { citiesMappedId } from "utils/mappers/cities";

import { dataItemsMenu } from 'constants/menuPageSettings';
import { RADIO_SCHEME } from 'constants/schemes';
import { tableStreets } from "constants/tables";
import { fileSizeCheck } from "utils/helpers/file";

const City = ({
    //state
    data,
    isLoadPage,
    cities,
    //dispatch
    updateFormCity,
    getCitySyncId,
    updateCity,
    createCity,
    deleteCityStreetById,
    updateCityStreet,
    createStreetByCity,
    uploadFileStreet,
}) => {
    let { sync_id } = useParams();
    let history = useHistory();

    const [isOpenModalDelete, setIsOpenModalDelete] = useState({
        isOpen: false,
        id: undefined,
        isLoad: false,
    });
    const [editTextStreet, setEditTextStreet] = useState({
        isOpen: false,
        text: '',
        id: undefined,
        isLoad: false,
    });
    const [addFileStreet, setAddFileStreet] = useState({
        isOpen: false,
        process: 0,
        isResnonse: false,
        isFile: false,
        fileName: '',
    });

    const [error, setErrors] = useState({
        name: "",
        latitude: "",
        longitude: "",
        uuid: "",
        parent_id: "",
    });

    let titleButton = data?.id ? 'Зберегти' : 'Створити';
    let textTitle = data?.type == 1 ? "Місто" : "Передмістя";

    const handlerUpadatUrl = (code) => {
        history.push(`/settings${dataItemsMenu['cities'].path}/${sync_id}`);
    }

    const handleSubmit = () => {
        validateCity({
            data: {
                name: data.name,
                parent_id: data.parent_id,
            },

            isSetEllement: data?.type == 2,

            onSuccess: async (validData) => {
                if (data?.id) {
                    updateCity(onCloseModalDowload);
                } else {
                    createCity(handlerUpadatUrl);
                }

                setErrors((prev) => (
                    {
                        ...prev,
                        ...{
                            name: "",
                            uuid: "",
                        }
                    }
                ));
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    const onLoadModal = async () => {
        setIsOpenModalDelete(prev => ({
            ...prev,
            isLoad: true,
        }));
    }

    const onDeleteStreet = (item, index) => {
        setIsOpenModalDelete(prev => ({
            ...prev,
            isOpen: true,
            index,
        }));
    }

    const handleDeleteSetreet = () => {
        deleteCityStreetById(isOpenModalDelete.id, onCloseModal, onLoadModal);
    }

    const onCloseModal = async () => {
        setIsOpenModalDelete({
            isOpen: false,
            id: undefined,
            isLoad: false,
        });
    }

    const onHandlerEdit = async (item, index) => {
        setEditTextStreet(prev => ({
            ...prev,
            isOpen: true,
            text: item.street,
            id: index,
        }));
    }

    const onCloseModalInput = async () => {
        setEditTextStreet({
            isOpen: false,
            text: "",
            id: undefined,
            isLoad: false,
        })
    }

    const onChangeIn = (value) => {
        setEditTextStreet(prev => (
            {
                ...prev,
                text: value,
            }
        ));
    }

    const updateLoadModalIn = async (status) => {
        await setEditTextStreet(prev => (
            {
                ...prev,
                isLoad: status
            }
        ));
    }

    const handleSubmitUpdateStreet = () => {
        if (editTextStreet.id !== undefined) {
            updateCityStreet(editTextStreet.id, editTextStreet.text, updateLoadModalIn, onCloseModalInput);
            return;
        }

        createStreetByCity(editTextStreet.text, updateLoadModalIn, onCloseModalInput);
    }

    const onAddStreet = () => {
        setEditTextStreet(prev => (
            {
                ...prev,
                isOpen: true,
            }
        ));
    }

    const handlerCreate = () => {
        setAddFileStreet(prev => ({
            ...prev,
            isOpen: true,
        }));
    }

    const onCloseModalDowload = () => {
        setAddFileStreet({
            isOpen: false,
            process: 0,
            isResnonse: false,
            isFile: false,
            fileName: '',
        });
    }

    const onDrop = useCallback(acceptedFiles => {
        fileSizeCheck(acceptedFiles[0], callbackFunctionFile)
    }, []);

    const callbackFunctionFile = async (file) => {
        uploadFileStreet(file, setAddFileStreet);
    }

    const deleteFile = () => {
        setAddFileStreet((prev) => ({
            ...prev,
            isFile: false,
            fileName: '',
            process: 0,
            isResnonse: false,
        }));
    }

    useEffect(() => {
        if (sync_id !== "new")
            getCitySyncId(sync_id);
    }, []);

    return (
        <div className={`${styles.d_max}`}>
            <Back />

            <div className={`${styles.d_grid}  ${styles.col_2} ${styles.block}`}>
                <div className={styles.left}>
                    <div className={`${styles.title_in} ${styles.title_center}`}>{textTitle}</div>
                    <TextInput
                        title="Назва"
                        type="text"
                        value={data.name}
                        isStyle={true}
                        error={error.name}
                        onChange={(e) => updateFormCity("name", e.target.value)}
                    />
                    {
                        (data?.type == 2) && (
                            <div className={styles.wr_drop}>
                                <Dropdown
                                    title="Місто"
                                    isBlack={true}
                                    width="100%"
                                    error={error.parent_id}
                                    onChange={(e) => updateFormCity("parent_id", e.target.value)}
                                    value={data.parent_id}
                                    list={citiesMappedId(cities)}
                                    isLight={true}
                                    fontSize={'14px'}
                                />
                            </div>
                        )
                    }
                    <TextInput
                        title="Uuid"
                        type="text"
                        value={data.uuid}
                        isStyle={true}
                        error={error.uuid}
                        onChange={(e) => updateFormCity("uuid", e.target.value)}
                    />
                    <div className={styles.wr_drop}>
                        <div className={styles.title_in}>Кординати</div>
                        <div className={styles.row_form}>
                            <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                <TextInput
                                    title="Широта"
                                    type="number"
                                    error={error.latitude}
                                    value={data.latitude}
                                    isStyle={true}
                                    onChange={(e) => updateFormCity("latitude", e.target.value)}
                                />
                            </div>
                            <div className={`${styles.wr_drop} ${styles.item_two}`}>
                                <TextInput
                                    title="Довгота"
                                    type="number"
                                    error={error.longitude}
                                    value={data.longitude}
                                    isStyle={true}
                                    onChange={(e) => updateFormCity("longitude", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.wr_drop}>
                        <Radio
                            colors={"#000"}
                            items={RADIO_SCHEME}
                            label="Активність міста"
                            valueKey="status"
                            value={data.status}
                            onChange={(e) => updateFormCity("status", e.value.value)}
                        />
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={`${styles.title_in} ${styles.title_center}`}>Вулиця</div>
                    <div className={`${styles.mt_30} ${styles.row_btn_bet}`}>
                        <ButtonIcon
                            onClick={onAddStreet}
                            title='Додати'
                            icon={<AddIcon />}
                            positionIcon="left"
                        />
                        <ButtonIcon
                            onClick={handlerCreate}
                            title='Завантажити списком'
                            icon={<UploadFileIcon />}
                            positionIcon="left"
                        />
                    </div>
                    {
                        isArray(data?.streets) && (
                            <div className={`${styles.mt_30} ${styles.max_oveh}`}>
                                <Table
                                    data={data.streets}
                                    scheme={tableStreets}
                                    onDelete={onDeleteStreet}
                                    onClick={onHandlerEdit}
                                />
                            </div>
                        )
                    }
                </div>
            </div>

            <div className={styles.row_btn}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
            </div>

            {
                isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }

            <ModalDelete
                title={'Видалити'}
                text={`Ви підтверджуєте видалення ?`}
                isOpen={isOpenModalDelete.isOpen}
                funDelete={() => handleDeleteSetreet()}
                onCloseModal={onCloseModal}
                isLoad={isOpenModalDelete.isLoad}
            />

            <ModalInput
                isOpen={editTextStreet.isOpen}
                onCloseModal={onCloseModalInput}
                value={editTextStreet.text}
                isLoad={editTextStreet.isLoad}
                onChange={onChangeIn}
                handleSubmit={handleSubmitUpdateStreet}
            />

            <ModalFileDownload
                states={addFileStreet}
                isOpen={addFileStreet.isOpen}
                onDrop={onDrop}
                onCloseModal={onCloseModalDowload}
                handleSubmit={handleSubmit}
                deleteFile={deleteFile}
                updateFormCity={updateFormCity}
                data={data}
            />

        </div >
    )
}

City.propTypes = {
    data: PropTypes.object,
    cities: PropTypes.array,
    isLoadPage: PropTypes.bool,
    updateFormCity: PropTypes.func,
    getCitySyncId: PropTypes.func,
    updateCity: PropTypes.func,
    createCity: PropTypes.func,
};

export default City;