import api from "../../apiSingleton";

import {
  CHECK_SESSION_SUCCESS,
  LOGOUT,
  UPDATE_JWT,
  UPDATE_SESSION_FAILURE,
  UPDATE_SESSION_SUCCESS,
  LOGOUT_BUTTON
} from "../constants/session";
import * as ROUTES from "../../constants/routes";
import history from "constants/history";
// import { getProducts } from "./products";
import { getSettings } from "./settings";
import { getUser } from "./user";

import {
  addNotification
} from './notifications';

const PUBLIC_ROUTES = [ROUTES.LOGIN];

export function login({ phone, password, onSuccess, onError }) {
  return async (dispatch) => {
    const res = await api.session.create({ phone, password });
    const isResponseValid = !!res.access_token;

    if (isResponseValid) {
      localStorage.setItem("jwt", res.access_token);
      api.apiClient.setToken(res.access_token);
      onSuccess();
    } else {
      await dispatch(addNotification({ login: [res.message] }, 'error'));
      onError();
    }
  };
}

export function logout() {
  return async (dispatch) => {
    api.apiClient.setToken("");

    dispatch({ type: LOGOUT });

    const pathname = history?.location?.pathname;

    if (!PUBLIC_ROUTES || !PUBLIC_ROUTES?.includes(pathname)) {
      if (history) {
        history.replace(ROUTES.LOGIN);
      }
    }
  };
}

export function logoutButton() {
  return async (dispatch) => {
    localStorage.removeItem("jwt");

    dispatch({ type: LOGOUT_BUTTON });
  };
}

export function checkSession() {
  return async (dispatch) => {
    const jwt = localStorage.getItem("jwt");

    if (jwt) {
      api.apiClient.setToken(jwt);

      await dispatch({ type: UPDATE_SESSION_SUCCESS });
      await dispatch(getUser());
      await dispatch(getSettings());
      // await dispatch(getProducts());

      await dispatch({
        type: UPDATE_JWT,
        payload: { jwt },
      });
    } else {
      dispatch({ type: UPDATE_SESSION_FAILURE });
    }

    dispatch({ type: CHECK_SESSION_SUCCESS });
  };
}
