import { Polygon } from '@react-google-maps/api';

export const MapPolygon = ({
  pathColor,
  positions,
  title,
  marker,
  time,
  startTime,
  finishTime,
  handleArea,
}) => {
  const options = {
    fillColor: pathColor,
    fillOpacity: 0.3,
    strokeColor: pathColor,
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
  }

  const onLoad = polygon => {
    // console.log("polygon: ", polygon);
  }

  return (
    <Polygon
      options={options}
      paths={positions}
      onClick={handleArea}
      onLoad={onLoad}
    >
    </Polygon>
  );
};
