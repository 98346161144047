import Table from "components/ui-kit/Table"

import { tableOrdersKitchen } from "constants/tables";

import { mappedOrder } from 'utils/mappers/statistic';

export const OrdersKitchen = ({
    // props
    data
}) => {
    return (
        <Table
            data={mappedOrder(data)}
            scheme={tableOrdersKitchen}
            isStictyhead={true}
        />
    )
}