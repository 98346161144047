import { isArray } from "lodash";

export const paymentsMappedSelect = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item.id, label: item.title });
    });

    return mewArray;
};

export const searchPaymentsId = (arr = [], payments = []) => {
    let mewArray = [];

    arr?.map((item) => {
        payments.map((el) => {
            if (el.id === item.id) {
                mewArray.push({ value: el.id, label: el.title });
            }
        });
    });

    return mewArray;
}

export const searchPaymentsIdArr = (arr = [], payments = []) => {
    let mewArray = [];

    arr?.map((item) => {
        payments.map((el) => {
            if (el.id === item) {
                mewArray.push({ value: el.id, label: el.title });
            }
        });
    });

    return mewArray;
}

export const arrayIdPaymentsFromPayment = (arr = []) => {
    let newArr = [];

    if (isArray(arr)) {
        arr.map((item) => {
            if (item.value > 0)
                newArr.push(item.value);
        })
    }

    return newArr;
}

export const arrayPaymentsFromPayConvert = (arr = []) => {
    let newArr = [];

    if (isArray(arr)) {
        arr.map((item) => {
            if (item.value > 0)
                newArr.push({ id: item.value, title: item.label });
        })
    }

    return newArr;
}