import { uk } from "date-fns/locale";
import { Calendar } from "react-date-range";
import React, { useState } from "react";

import { TextInput } from "components/ui-kit/inputs/TextInput";

import CloseIcon from "@mui/icons-material/Close";
import styled from "./calendar.module.scss";

export const CalendarDate = ({
    onChangeCal = () => { },
    maxDate,
    date,
    valueIn = "",
    title,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }

    const onChange = (data) => {
        onChangeCal(data);
        onClose();
    }

    return (
        <div className={styled.wr_calendar}>
            <div className={styled.wr_in} onClick={() => isOpen ? onClose() : onOpen()}>
                <TextInput
                    type="text"
                    disabled={true}
                    value={valueIn}
                    title={title}
                />
                {
                    isOpen && (
                        <div className={styled.bnt_close_cal}>
                            <CloseIcon style={{ fontSize: 36 }} />
                        </div>
                    )
                }
            </div>
            <div className={`${styled.main_cal} ${isOpen ? styled.main_cal_active : ''}`}>
                <Calendar
                    className="calendar"
                    onChange={(date) => onChange(date)}
                    date={date}
                    // maxDate={maxDate}
                    locale={uk}
                />
            </div>
        </div>
    )
}