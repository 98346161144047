/*  camelcase */
export default {
    title: ['required', 'trim', 'string'],
    address: ['required', 'trim', 'string'],
    city_sync_id: ['required', 'trim', 'string'],
    latitude: ['required', 'trim', 'string'],
    longitude: ['required', 'trim', 'string'],
    time_open: ['required', 'trim', 'string'],
    time_close: ['required', 'trim', 'string'],
    sale_employee: ['required', 'trim', 'string'],
    create_cash_checks_to: ['required', 'trim', 'string'],
    create_cashless_checks_to: ['required', 'trim', 'string'],
};