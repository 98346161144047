import { isArray } from "lodash";
import styles from "./styles.module.scss";

export const Check = ({ data }) => {
    const {
        address,
        client,
        history,
        items,
        payments,
        persons,
        amount,
        balances,
        payable,
        discounts,
        client_rest,
        cutlery_sets,
        to_pay
    } = data;

    const startOfCook = isArray(history) && history.find(el => el.status === "cooking")?.set_at;

    return (
        <>
            <hr className={styles.hrs} />
            <div className={styles.restorant}>{data?.restaurant}</div>
            <div className={styles.invoice}>Накладна</div>
            <div className={styles.order}>Замовлення № {data.id}</div>
            <div className={styles.address_client}>{address?.city?.name_ua}, <br /> {address?.street}, буд. {address?.house_number}</div>
            <div className={styles.client}>{client?.phone} <br />{client?.name}</div>
            <div className={styles.address_info}>
                {
                    address?.apartment && (
                        <div className={styles.address_info_item}>
                            <span>Квартира</span>
                            <span>: 42</span>
                        </div>
                    )
                }
                {
                    address?.entrance && (
                        <div className={styles.address_info_item}>
                            <span>Під'їзд</span>
                            <span>: {address.entrance}</span>
                        </div>
                    )
                }
                {
                    address?.floor && (
                        <div className={styles.address_info_item}>
                            <span>Поверх</span>
                            <span>: {address.floor}</span>
                        </div>
                    )
                }
            </div>
            {
                address?.comment_to_courier && (
                    <div className={styles.comment}>
                        <span>Коментар : </span>
                        <span>{address?.comment_to_courier}</span>
                    </div>
                )
            }
            {
                startOfCook && (
                    <div className={styles.transferred_kitchen}>
                        <span>Передано на кухню : </span>
                        <span>{startOfCook}</span>
                    </div>
                )
            }
            {/* <div className={styles.person}>
            <span>К-сть Персон : </span>
            <span>{persons}</span>
        </div> */}
            <div className={styles.table}>
                <div className={`${styles.table__row} ${styles.table__row_head}`}>
                    <div className={`${styles.table__item} ${styles.table__item_name}`}>Найменування</div>
                    <div className={`${styles.table__item} ${styles.table__item_number}`}>К-ть</div>
                    <div className={`${styles.table__item} ${styles.table__item_price}`}>Ціна</div>
                    <div className={`${styles.table__item} ${styles.table__item_sum}`}>Сума</div>
                </div>
                {
                    isArray(items) && items.length > 0 && items.map((item, index) => (
                        <div key={index}>
                            <div className={`${styles.table__row_prod}`} >
                                <div className={styles.table__row_item_name}>{item?.name}</div>
                                <div className={styles.table__row_items}>
                                    <div className={`${styles.tables__item} ${styles.table__items_number}`}>{item?.quantity} порц х </div>
                                    <div className={`${styles.tables__item} ${styles.table__items_price}`}>{item?.price}</div>
                                    <div className={`${styles.tables__item} ${styles.table__items_sum}`}>{item?.quantity * item?.price}</div>
                                </div>
                            </div>
                            {
                                isArray(item?.additions) && item.additions.map((itemAddition, index) => (
                                    <div className={`${styles.table__row_prod}`} key={index}>
                                        <div className={`${styles.table__item_name_pad} ${styles.table__row_prod_left}`}>{itemAddition.name}</div>
                                        <div className={styles.table__row_items}>
                                            <div className={`${styles.tables__item} ${styles.table__items_number}`}>{itemAddition.quantity} порц х </div>
                                            <div className={`${styles.tables__item} ${styles.table__items_price}`}>{itemAddition.price}</div>
                                            <div className={`${styles.tables__item} ${styles.table__items_sum}`}>{itemAddition.quantity * itemAddition.price}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
                {
                    isArray(cutlery_sets) && cutlery_sets.map((itemCutlery, index) => (
                        <div className={`${styles.table__row_prod}`} key={index}>
                            <div className={`${styles.table__row_item_name}`}>{itemCutlery.name}</div>
                            <div className={styles.table__row_items}>
                                <div className={`${styles.tables__item} ${styles.table__items_number}`}>{itemCutlery.quantity} набор х</div>
                                <div className={`${styles.tables__item} ${styles.table__items_price}`}>{itemCutlery.price}</div>
                                <div className={`${styles.tables__item} ${styles.table__items_sum}`}>{itemCutlery.sum}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={styles.bot}>
                <span>Сума замовлення: </span>
                <span>{amount}</span>
            </div>
            {
                isArray(discounts) && (discounts.length > 0) && (
                    <div className={styles.discount}>
                        <div className={styles.discount__title}>Знижка:</div>
                        {
                            discounts.map((item, index) => (
                                <div className={styles.discount__row} key={index}>
                                    <div className={styles.discount__name}>{item.name}: </div>
                                    <div className={styles.discount__item}>-{item.sum}</div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            {
                (balances > 0) && (
                    <div className={styles.bonuses}>
                        <div className={styles.bonuses__row}>
                            <div className={styles.bonuses__name}>Оплата бонусами: </div>
                            <div className={styles.bonuses__item}>-{balances}</div>
                        </div>
                    </div>
                )
            }
            {
                isArray(payments) && !!payments.length && (
                    <div className={`${styles.type_payments} ${styles.type_payments_diw}`}>
                        <span><b>Тип оплати: </b></span>
                        <span>
                            {
                                payments.map((item, index) => (
                                    <span key={index}>
                                        <span>{item.payment_type}</span>
                                        <span>{item.sum}</span>
                                    </span>
                                ))
                            }
                        </span>
                    </div>
                )
            }
            {
                (data.delivery_type.id > 0) && (
                    <div className={styles.type_payments}>
                        <span>Доставка: </span>
                        <span>{data.delivery_type?.name}</span>
                    </div>
                )
            }
            {
                (client_rest > 0) && (
                    <div className={styles.rest}>
                        <span>Решта: </span>
                        <span>{client_rest}</span>
                    </div>
                )
            }
            <div className={styles.bot_payment}>
                <span>Сума до сплати: </span>
                <span>{to_pay}</span>
            </div>
            <div className={styles.bot_text}>РУССКИЙ КОРАБЛЬ, ИДИ НАХ*Й!</div>
            <div>....</div>
        </>
    )
}