import { uk } from "date-fns/locale";
import PropTypes from "prop-types";
import { DateRange } from "react-date-range";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Radio from "components/ui-kit/Radio";
import Checkbox from "components/ui-kit/Checkbox";
import styles from "../scss/settings.module.scss";

import { RADIO_SCHEME, RADIO_SCHEME_ACTIONS, RADIO_SCHEME_PERIOD } from "constants/schemes";
import { TextArea } from "components/ui-kit/inputs/TextArea";

import { citiesMappedId } from "utils/mappers/cities";
import { categoriesMapped } from "utils/mappers/categories";
import { activeChecked } from "utils/helpers/checkeds";

import { rangesDate } from "utils/helpers/date";

const days = [
    {
        title: "Пн.",
        value: "Monday",
    },
    {
        title: "Вт.",
        value: "Tuesday",
    },
    {
        title: "Ср.",
        value: "Wednesday",
    },
    {
        title: "Чт.",
        value: "Thursday",
    },
    {
        title: "Пт.",
        value: "Friday",
    },
    {
        title: "Сб.",
        value: "Saturday",
    },
    {
        title: "Нд.",
        value: "Sunday",
    }
];

export const PromotionTypeN = ({
    //props
    error,
    roles,
    cities,
    categories,
    promotionsEdit,
    updateFormPromotion = () => { },
    updateFormPromotionWeek = () => { }
}) => {
    return (
        <div>
            <div className={`${styles.row_form_two}`}>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <Dropdown
                        title='Місто'
                        isBlack={true}
                        width="100%"
                        onChange={(e) => updateFormPromotion("city_id", e.target.value)}
                        value={promotionsEdit.city_id}
                        list={citiesMappedId(cities)}
                        isLight={true}
                        error={error.city_id}
                        fontSize={'14px'}
                    />
                    <TextInput
                        title="Кількість товарів"
                        type="number"
                        value={promotionsEdit.product_count}
                        isStyle={true}
                        error={error.product_count}
                        onChange={(e) => updateFormPromotion("product_count", e.target.value)}
                    />
                    <Dropdown
                        title='Категорія'
                        isBlack={true}
                        width="100%"
                        onChange={(e) => updateFormPromotion("product_category_id", e.target.value)}
                        value={promotionsEdit.product_category_id}
                        list={categoriesMapped(categories)}
                        isLight={true}
                        error={error.product_category_id}
                        fontSize={'14px'}
                    />
                    <TextInput
                        title="Назва"
                        type="text"
                        value={promotionsEdit.name}
                        isStyle={true}
                        error={error.name}
                        onChange={(e) => updateFormPromotion("name", e.target.value)}
                    />
                </div>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <div className={styles.wr_drop}>
                        <Radio
                            colors={"#000"}
                            items={RADIO_SCHEME_PERIOD}
                            valueKey="time_frame_type"
                            value={promotionsEdit.time_frame_type}
                            onChange={(e) => updateFormPromotion("time_frame_type", e.value.value)}
                        />
                    </div>
                    {
                        (promotionsEdit.time_frame_type === "period") && (
                            <div className={styles.wr_range}>
                                <div className={styles.title_input}>Період з - по</div>
                                <DateRange
                                    onChange={(date) => updateFormPromotion("period", [date.selection])}
                                    ranges={rangesDate(promotionsEdit.period)}
                                    locale={uk}
                                />
                            </div>
                        )
                    }
                    {
                        (promotionsEdit.time_frame_type === "days_of_the_week") && (
                            <div>
                                <div className={styles.title_input}>День тижня</div>
                                <div className={styles.items_chec}>
                                    {
                                        days.map((item, index) => (
                                            <Checkbox
                                                key={index}
                                                isStyle={true}
                                                title={item.title}
                                                value={item.value}
                                                checked={activeChecked(item.value, promotionsEdit.days_of_the_week)}
                                                onChecked={updateFormPromotionWeek}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <TextArea
                isStyle={true}
                title='Опис акції'
                value={promotionsEdit.description}
                error={error.description}
                onChange={(e) => updateFormPromotion("description", e.target.value)}
            />
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME_ACTIONS}
                    label="Працює з акційними товарами ?"
                    valueKey="status"
                    value={promotionsEdit.isActions}
                    onChange={(e) => updateFormPromotion("isActions", +e.value.value)}
                />
            </div>
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME}
                    label="Активність акції"
                    valueKey="status"
                    value={promotionsEdit.status}
                    onChange={(e) => updateFormPromotion("status", +e.value.value)}
                />
            </div>
        </div>
    )
}

PromotionTypeN.propTypes = {
    number: PropTypes.number,
    handleSubmit: PropTypes.func,
    handleSubmitClose: PropTypes.func,
    updateFormPromotionWeek: PropTypes.func,
};