import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as KitchenPage } from './Kitchen';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        cities: [...[{ name_ua: '-', sync_id: "-", status: 1 }], ...settings.cities],
        data: settings.editKitchens,
        isLoadPage: view.isLoadPage,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(KitchenPage);
