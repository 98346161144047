import Base from "./Base.js";

export default class OrdersAPI extends Base {
  getOrders(params) {
    return this.apiClient.get("orders", params);
  }

  getCallcenterOrders() {
    return this.apiClient.get("orders");
  }

  getCallcenterOrderItem(orderId, params) {
    return this.apiClient.get(`orders/${orderId}`, params);
  }

  resetChangeAddress(id) {
    return this.apiClient.post(`orders/${id}/reset-changed-status`);
  }

  getOrderItems() {
    return this.apiClient.get("order-items");
  }

  createOrder(order) {
    return this.apiClient.post("orders", order);
  }

  updateOrder(id, order) {
    return this.apiClient.put(`orders/${id}`, order);
  }

  updateOrderPauments(id, data) {
    return this.apiClient.post(`orders/${id}/change-payments`, data);
  }

  updateItem(id, status) {
    return this.apiClient.patch(`order-items/${id}`, status);
  }

  updateStatus(id, status) {
    return this.apiClient.put(`orders/${id}/update-status`, { status: status });
  }

  getStreets(params) {
    return this.apiClient.get(`cities-street`, params);
  }

  startDelivery(delivery_terminal_id, orders) {
    return this.apiClient.post(`mobile/deliveries`, {
      delivery_terminal_id,
      orders,
    });
  }

  completeDelivery(order_uuid, params) {
    return this.apiClient.patch(`mobile/orders/${order_uuid}`, {
      restaurant: params.restaurant,
      latitude: "none",
      longitude: "none",
    });
  }

  // reason
  createReason(id, data) {
    return this.apiClient.post(`orders/${id}/reason-rejected`, data);
  }

  getReasonInformation() {
    return this.apiClient.get(`orders-reasons-rejected-information`);
  }

  auditPropmocode(code, data) {
    return this.apiClient.post(`promocodes/${code}/apply`, data);
  }

  stocks(data) {
    return this.apiClient.post(`stocks/apply`, data);
  }

  updateAddress(id, data) {
    return this.apiClient.post(`orders/${id}/change-address`, data);
  }

  getOrderPrint(id) {
    return this.apiClient.get(`orders/${id}/show-for-check`);
  }

  getOrdersClient(id, params) {
    return this.apiClient.get(`clients/${id}/orders`, params);
  }

  sendOrderTelegram(id, params) {
    return this.apiClient.post(`telegram/${id}/send-order`, params);
  }
}
