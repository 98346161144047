import { connect } from 'react-redux';

import { default as HomePage } from './Home';

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
