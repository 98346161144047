import React, { useEffect, useState, useRef } from "react";
import { isArray } from "lodash";
import { useReactToPrint } from "react-to-print";


import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import CalendarRange from "components/ui-kit/CalendarRange";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import Table from "components/ui-kit/Table";
import Select from "components/ui-kit/Select";
import ModalTypeCheck from "components/modals/ModalTypeCheck";
import { PrintCheck } from "components/PrintCheck";

import { kitchensMapped, kitchenSearchDropdown } from "utils/mappers/kitchens";
import { saleMetodMapped, saleMetodMappedSelect } from "utils/mappers/others";
import { isEditAllSales, isEditOwnSales, isEditCertainSales } from "utils/helpers/checks";
import { cleanCheck } from "utils/helpers/cleanCheck";
import { dataFormat } from "utils/helpers/date";

import { tableSales } from "constants/tables";
import { ModalFormCheck } from "./modal";

const Sales = ({
    userMe,
    view,
    salesList = [],
    filter,
    kitchens,
    cashierList,
    metodPaymentsList,
    postCheckCmc,
    getCashalotCheck,
    updateFilterSales = () => { },
    getSalesList = () => { },
    downloadSalesExel = () => { },
    getAllCashier = () => { },
    getAllMetodPayments = () => { },
}) => {
    const componentRef = useRef(undefined);
    const [stateLoda, setStateLoad] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTable, setModalTable] = useState({ checkId: null, show: false });
    const [loadCmc, setLoadCms] = useState(false);
    const [loadPrint, setLoadPrint] = useState(false);
    const [printCheck, setPrintCheck] = useState({ show: false, data: null });
    const [dataRowSale, setDataRowSale] = useState(null);
    const isAllSales = isEditAllSales(userMe);

    const arrayIdKitchens = () => {
        if (isEditOwnSales(userMe)) {
            return [...[{ title: '-', code: null, status: 1 }], ...kitchenSearchDropdown(userMe?.kitchens, kitchens)];
        } else if (isEditCertainSales(userMe)) {
            return [...[{ title: '-', code: null, status: 1 }], ...kitchenSearchDropdown(userMe?.kitchens_sales, kitchens)];
        }

        return kitchensMapped(kitchens);
    }

    const handleBtnTable = (checkId, dataRow = null) => {
        setModalTable({ checkId: checkId, show: true });
        setPrintCheck({ show: false, data: null });
        setDataRowSale(dataRow);
    }

    const onCloseModalTable = () => {
        setModalTable({ checkId: null, show: false });
    }

    const handleCmc = () => {
        postCheckCmc({
            checkId: modalTable.checkId,
            setIsLoad: setLoadCms,
            onCloseModalTable
        });
    }

    const handlePrint = () => {
        getCashalotCheck({
            checkId: modalTable.checkId,
            setIsLoad: setLoadPrint,
            setPrintCheck,
            onCloseModalTable
        })
    }

    const handlePrintChe = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (printCheck.show)
            handlePrintChe();
    }, [printCheck]);

    useEffect(() => {
        getAllCashier();
        getAllMetodPayments();
    }, []);

    return (
        <>
            <div className="page-sales">
                <div className="sales-filter">
                    <div className="item-sales-filter">
                        <Dropdown
                            title="Господарьска одиниця"
                            type='text'
                            isBlack={true}
                            isLight={true}
                            fontSize={"14px"}
                            onChange={(e) => updateFilterSales("kitchen_code", e.target.value)}
                            value={filter.kitchen_code}
                            list={arrayIdKitchens()}
                        />
                    </div>
                    <div className="item-sales-filter">
                        <Dropdown
                            title="Касир"
                            type='text'
                            isBlack={true}
                            isLight={true}
                            fontSize={"14px"}
                            onChange={(e) => updateFilterSales("cashier_name", e.target.value)}
                            value={filter.cashier_name}
                            list={saleMetodMapped(cashierList)}
                        />
                    </div>
                    <div className="item-sales-filter mb-not">
                        <Select
                            selectOptions={saleMetodMappedSelect(metodPaymentsList)}
                            selectedOption={filter.payment_type || []}
                            valueKey='payment_type'
                            label='Вид оплати'
                            isMulti
                            mode={'primary'}
                            onChange={({ value }) => updateFilterSales("payment_type", value)}
                        />
                    </div>
                    <div className="item-sales-filter item-sales-filter_range">
                        <CalendarRange
                            title="Період з - по"
                            isStyle={true}
                            valueIn={filter.date}
                            ranges={filter.date}
                            isAbs={true}
                            onChangeCal={(date) => updateFilterSales("date", [date.selection])}
                        />
                    </div>
                    <div className="btn-wt-sales">
                        <ButtonIcon
                            title='Сформувати'
                            disabled={!isAllSales && (!filter.kitchen_code) || (filter.kitchen_code == '-')}
                            onClick={() => getSalesList()}
                        />
                    </div>
                    <div className="btn-wt-sales">
                        <ButtonIcon
                            title='Exel'
                            colorBg="blue"
                            isLoad={stateLoda}
                            disabled={!(isArray(salesList) && (salesList.length > 0))}
                            onClick={() => downloadSalesExel(setStateLoad)}
                        />
                    </div>
                    <div className="btn-wt-sales">
                        <ButtonIcon
                            title='Додати чек'
                            colorBg="#58bee1"
                            onClick={() => setShowModal(true)}
                        />

                        <ModalFormCheck
                            kitchens={arrayIdKitchens()}
                            metodPaymentsList={metodPaymentsList}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />
                    </div>
                </div>
                <div className="sales-wr-table">
                    {
                        !view?.isLoadPage ? (
                            isArray(salesList) && (salesList.length > 0) ? (
                                <Table
                                    data={salesList}
                                    scheme={tableSales}
                                    isStictyhead={!!salesList?.length}
                                    isBtn={true}
                                    keyBtn="check_id"
                                    textBtn="Чек"
                                    handleBtn={handleBtnTable}
                                />
                            ) : (
                                <div className="text_info">Пусто... оберіть потрібні параметри і натисніть кнопку "Сформувати"</div>
                            )
                        ) : (
                            <LoadingCircl />
                        )
                    }
                </div>
            </div>
            <ModalTypeCheck
                isOpen={modalTable.show}
                onCloseModal={onCloseModalTable}
                handleCmc={handleCmc}
                isLoadCmc={loadCmc}
                handlePrint={handlePrint}
                isLoadPrint={loadPrint}
            />
            <PrintCheck
                data={cleanCheck(printCheck.data)}
                typeCheck="checkCashalot"
                ref={componentRef}
                linkQr={dataRowSale?.date ? `
                    https://cabinet.tax.gov.ua/cashregs/check?
                    fn=${dataRowSale.numfiscal}
                    &id=${dataRowSale.check_id}
                    &date=${dataFormat(dataRowSale.date.split(' ')[1], 'YYYYMMDD')}
                    &sm=${dataRowSale.sum}
                    &time=${dataRowSale.date.split(' ')[0].replaceAll(":", '')
                    }` : ''
                } />
        </>
    )
}

export default Sales;