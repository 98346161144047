import { isArray } from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";

import { Notification } from "./Notification";

import styles from "./notifications.module.scss";

const Notifications = ({
    //state
    messages,
    removeNotification,
    removeNotificationShift,
}) => {
    useEffect(() => {
        if (isArray(messages)) {
            if (messages.length > 0) {
                setTimeout(() => {
                    removeNotificationShift();
                }, 5e3);
            }
        }
    }, [messages]);

    return (
        <div className={styles.wr_notifications}>
            {
                (isArray(messages) && messages.length > 0) && (
                    messages.map((item, index) => (
                        <Notification
                            key={index}
                            data={item}
                            index={index}
                            removeNotification={removeNotification}
                        />
                    ))
                )
            }

        </div>
    )
}

Notifications.propTypes = {
    messages: PropTypes.array,
    removeNotification: PropTypes.func,
    removeNotificationShift: PropTypes.func,
};

export default Notifications;