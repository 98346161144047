import { useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import { LoadingModal } from "components/loadingOrder/loading";

import { validatePayment } from "utils/validation";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from '@mui/icons-material/Close';
import styles from "../scss/settings.module.scss";

import { RADIO_SCHEME, RADIO_SCHEME_FREE, BLACK_LIST } from "constants/schemes";

export const PaymentEdit = ({
    data,
    view,
    onSubmit,
    onSubmitUpdate,
    handleSubmitClose,
    updateFormPayment,
}) => {
    const [error, setErrors] = useState({
        title: "",
    });

    let titleButton = data?.id ? 'Змінити' : 'Додати';
    let title = data?.id ? `Редагування ${data.title}` : "Строврення нового";

    const handlerSubmit = () => {
        validatePayment({
            data: {
                ...data
            },

            onSuccess: async () => {
                if (data?.id) {
                    onSubmitUpdate(data?.id);
                } else {
                    onSubmit();
                }

                setErrors({
                    title: "",
                });
            },

            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    return (
        <div className={styles.row_mrt}>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>{title}</div>
            <div className={styles.wr_drop}>
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.title}
                    isStyle={true}
                    error={error.title}
                    onChange={(e) => updateFormPayment("title", e.target.value)}
                />
                <br />
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME_FREE}
                    label="Безкоштовно"
                    valueKey="free"
                    value={data.free}
                    onChange={(e) => updateFormPayment("free", +e.value.value)}
                />
                <br />
                <Radio
                    colors={"#000"}
                    items={BLACK_LIST}
                    label="Працює як знижка"
                    valueKey="is_discount"
                    value={data.is_discount}
                    onChange={(e) => updateFormPayment("is_discount", +e.value.value)}
                />
                <br />
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME}
                    label="Активність"
                    valueKey="status"
                    value={data.status}
                    onChange={(e) => updateFormPayment("status", +e.value.value)}
                />
            </div>
            <div className={styles.row_btn_tow}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handlerSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmitClose}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>

            {
                view.isLoadEditPayment && (
                    <LoadingModal />
                )
            }
        </div >
    )
}