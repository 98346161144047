import { useState } from "react";
import PropTypes from "prop-types";
import { isArray } from "lodash";
import {
    useRouteMatch,
    useHistory
} from "react-router-dom";

import HeaderKitchen from "components/headers/HeaderKitchen";
import Table from "components/ui-kit/Table";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import styles from "./../scss/settings.module.scss";

import { tableKitchen } from "constants/tables";
import { useEffect } from "react";

const Kitchens = ({
    cities = [],
    view = {},
    kitchens = [],
    //dispatch
    getSettingsKitchens = () => { },
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();

    const [activeCities, setActiveCities] = useState('all');

    useEffect(() => {
        let parapms = {};

        if (activeCities !== 'all')
            parapms.city_sync_id = activeCities;

        getSettingsKitchens(parapms);
    }, [activeCities]);

    const handlerCities = (value) => {
        setActiveCities(value);
    }

    const handlerEditKitchen = (data) => {
        history.push(`${path}/${data.code}`);
    }

    const handlerCreateKitchen = () => {
        history.push(`${path}/new`);
    }

    return (
        <div>
            <HeaderKitchen
                cities={cities}
                activeCity={activeCities}
                handlerCities={handlerCities}
                handleCreate={handlerCreateKitchen}
            />
            <div className={styles.wr_row}>
                {
                    !view?.isLoadPage ? (
                        isArray(kitchens) && (
                            <Table
                                onClick={handlerEditKitchen}
                                data={kitchens}
                                scheme={tableKitchen}
                                isStictyhead={true}
                            />
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </div>
        </div>
    )
}

HeaderKitchen.propTypes = {
    cities: PropTypes.array,
    view: PropTypes.object,
    kitchens: PropTypes.array,
    getSettingsKitchens: PropTypes.func,
};

export default Kitchens;