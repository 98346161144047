import { connect } from "react-redux";

import { default as ReportsPage } from "./Reports";

import * as reportActions from "store/actions/reports";

function mapStateToProps(state) {
    const {
        reports: {
            listSelect,
            activeSelect,
            filter,
            listReports
        },
        view: {
            isLoadPage
        },
        settings: {
            kitchens,
            restaurants,
        }
    } = state;


    return {
        listSelect,
        activeSelect,
        filter,
        listReports,
        isLoadPage,
        kitchens,
        restaurants,
    };
}

const mapDispatchToProps = {
    ...reportActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
