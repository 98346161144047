import { isArray } from "lodash";

export const categoriesMapped = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ title: item.name, value: item.id });
    });

    return mewArray;
};

export const categoriesMappedSuncId = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ label: item.name, value: item.sync_id });
    });

    return mewArray;
};

export const searchCategoriesSuncId = (sunc_id, arr = []) => {
    let obj = {};

    arr?.map((item) => {
        if (item.sync_id === sunc_id) {
            obj = { label: item.name, value: item.sunc_id, };
        }
    });

    return obj;
}

export const categoriesMappedSelect = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item.id, label: item.name });
    });

    return mewArray;
};

export const searchCategoriesId = (arr = [], categories = []) => {
    let mewArray = [];

    arr?.map((item) => {
        categories.map((el) => {
            if (el.id === item) {
                mewArray.push({ value: el.id, label: el.name });
            }
        });
    });

    return mewArray;
}

export const arrayIdCategoriesFromCategor = (arr = []) => {
    let newArr = [];

    if (isArray(arr)) {
        arr.map((item) => {
            if (item.value > 0)
                newArr.push(item.value);
        })
    }

    return newArr;
}