import produce from 'immer';

import {
    SALES_GET_LIST,
    SALES_UPDATE_FILTER,
} from '../constants/sales';

const initialState = {
    list: [],
    filter: {
        kitchen_code: null,
        cashier_name: null,
        payment_type: null,
        date: [
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
            }
        ],
    },
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case SALES_GET_LIST: {
            draft.list = payload;
            break;
        }

        case SALES_UPDATE_FILTER: {
            draft.filter[payload.item] = payload.data;
            break;
        }

        default:
            return draft;
    }
}, initialState);
