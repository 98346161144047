import { isArray } from "lodash";

export const reasonMapped = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ label: item.title, value: item.id });
    });

    return mewArray;
}

export const searchReasonSelectByArrayId = (ids = [], arr = []) => {
    let mewArray = [];

    if (isArray(ids)) {
        ids?.map((item) => {
            arr.map((el) => {
                if (el.id === item) {
                    mewArray.push({ label: el.title, value: el.id });
                }
            });
        });
    }

    return mewArray;
}

export const reasonInObjOutid = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push(item.value);
    });

    return mewArray;
}