import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as citiesPage } from './Cities';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        view,
        cities: settings.cities,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(citiesPage);
