import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { isArray } from "lodash";

import Back from "components/ui-kit/Back";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingModal } from "components/loadingOrder/loading";
import Select from "components/ui-kit/Select";

import SaveIcon from "@mui/icons-material/Save";
import styles from "./../../scss/settings.module.scss";

import { validateRole } from "utils/validation";
import { searchIndex } from "utils/helpers/searchIndexs";

import { selectPages, selectPagesStatistic, pageStatistic } from "constants/selectPages";

const Restaurant = ({
    //state
    data,
    isLoadPage,
    //dispatch
    updateFormRole,
    updateFormRolePages,
    cleaningNewForm,
    getRoleId,
    updataRole,
    createRole,
}) => {
    let { id } = useParams();
    let history = useHistory();

    const [isPageStatistic, setIsPageStatistic] = useState(false);
    const [error, setErrors] = useState({
        title: "",
        pages: "",
        statisticPages: "",
    });

    let titleButton = data?.id ? 'Змінити' : 'Створити';

    const handlerUpadatBack = () => {
        history.goBack();
    }

    const handleSubmit = () => {
        validateRole({
            data: {
                title: data.title,
                pages: data.pages,
            },

            onSuccess: async (validData) => {
                if (data?.id) {
                    updataRole();
                } else {
                    createRole(handlerUpadatBack);
                }

                setErrors({
                    name: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    const handleSubmitAllStatistic = () => {
        updateFormRolePages(pageStatistic, selectPagesStatistic);
    }

    useEffect(() => {
        if (id !== "new")
            getRoleId(id);

        if (id == "new")
            cleaningNewForm("editRole");
    }, []);

    useEffect(() => {
        if (isArray(data.pages)) {
            setIsPageStatistic(data.pages.find((e) => e.value === pageStatistic) && true);
        }
    }, [data.pages]);

    return (
        <div className={`${styles.d_max_768}`}>
            <Back />

            <div className={`${styles.block}`}>
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.title}
                    isStyle={true}
                    error={error.title}
                    onChange={(e) => updateFormRole("title", e.target.value)}
                />
            </div>

            <div className={`${styles.title_row} ${styles.row_mrt}`}>Загальні сторінки</div>

            <div className={`${styles.block}`}>
                <Select
                    selectOptions={selectPages}
                    selectedOption={data?.pages ? data.pages : []}
                    valueKey='pages'
                    label='Сторінки'
                    error={error.pages}
                    isMulti
                    mode={'primary'}
                    onChange={({ value }) => updateFormRole("pages", value)}
                />
            </div>

            {
                isPageStatistic && (
                    <div className={`${styles.block}`}>
                        <div className={`${styles.title_row} ${styles.row_mrt}`}>Сторінки по статистиці</div>
                        <br />
                        <div className={styles.row_sel}>
                            <Select
                                selectOptions={selectPagesStatistic}
                                selectedOption={((data?.pages.length > 0) && data?.pages?.[searchIndex(data.pages, pageStatistic)]) ? data?.pages?.[searchIndex(data.pages, pageStatistic)].pages : []}
                                valueKey='statisticPages'
                                error={error.statisticPages}
                                isMulti
                                mode={'primary'}
                                onChange={({ value }) => updateFormRolePages(pageStatistic, value)}
                            />
                            <div className={styles.btn_sel}>
                                <ButtonIcon
                                    onClick={handleSubmitAllStatistic}
                                    title={'Обрати всі'}
                                    width="110px"
                                />
                            </div>
                        </div>
                    </div>
                )
            }

            <div className={styles.row_btn}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
            </div>

            {
                isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }
        </div>
    )
}

Restaurant.propTypes = {
    data: PropTypes.object,
    isLoadPage: PropTypes.bool,
    updateFormRole: PropTypes.func,
    cleaningNewForm: PropTypes.func,
    getRoleId: PropTypes.func,
    updataRole: PropTypes.func,
    updateFormRolePages: PropTypes.func,
};

export default Restaurant;