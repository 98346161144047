import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";

import CloseIcon from "@mui/icons-material/Close";
import "leaflet/dist/leaflet.css";

import { getOrdersClient } from "store/actions/order";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import { isArray } from "lodash";

const OrderAll = ({
    // props
    isThereMap = false,
    closeOrderAll,
    handlerGetOneOrder,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { ordersAll } = useSelector(state => state.order);

    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        dispatch(getOrdersClient({ setIsLoad }));
    }, []);

    return (
        <Wrapper theme={theme} isThereMap={isThereMap}>
            <Head>
                <Title>Всі замовлення клієнта</Title>
                <CloseButton onClick={closeOrderAll}>
                    <CloseIcon style={{ fontSize: 36 }} />
                </CloseButton>
            </Head>
            <Main>
                {
                    isLoad ? (
                        <LoadingCircl mins={true} />
                    ) : (
                        isArray(ordersAll) && (ordersAll.length > 0) && (
                            <UL>
                                {
                                    ordersAll.map(item => (
                                        <Li key={item.id} onClick={() => handlerGetOneOrder(item.id)}>
                                            <Number>{item.id}</Number>
                                            <Items>
                                                {
                                                    item.items.map((el, index) => (
                                                        <Item key={index}>{el?.product?.title_ua} - {el.quantity} шт.</Item>
                                                    ))
                                                }
                                            </Items>
                                        </Li>
                                    ))
                                }
                            </UL>
                        )
                    )
                }
            </Main>
        </Wrapper>
    );
};

const Item = styled.li`
    padding: 4px 0; 
`;

const Items = styled.ul`
    width: calc(100% - 50px);
    padding: 0 5px;
    border-left: 1px solid #9D9D9D;
`;

const Number = styled.span`
    width: 50px;
    padding: 5px 5px 5px 0;
    color: #EDA240;
`;

const Li = styled.li`
    display: flex;
    align-items: flex-start;
    color: #fff;
    &:not(:last-child) {
        border-bottom: 1px solid #9D9D9D;
    }
    cursor: pointer;
    &:hover {
        color: #EDA240;
    }
`;

const UL = styled.ul`
`;

const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.accentColor};
    border-radius: 50%;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.backgroundHover};
    }
`;

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
`;

const Main = styled.div`
    max-height: 90%;
    width: 100%;
    overflow-y: auto; 
`;

const Title = styled.div`
    color: #fff;
    font-size: 16px;
    font-weight: 500;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    transform-origin: right top;
    height: 100%;
    top: 0;
    right: ${(p) => p.isThereMap ? "460px" : "920px"};
    width: 420px;
    padding: 20px 10px;
    background-color: ${(p) => p.theme.background};
    z-index: 10;
    border-right: 1px solid #9D9D9D;
`;

export default React.memo(OrderAll);
