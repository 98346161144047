import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { NavLink } from "./NavLink";
import SearchBar from "./SearchBar";

import history from "constants/history";

import Icon from "assets/icons/icon.svg";

const Navigation = ({
  user,
  tabs,
  activeTab,
  logout
}) => {
  const theme = useTheme();
  const handleLink = (value) => history.push(value);

  const renderMenu = () => {
    return tabs.map((item, index) => {
      const { label, value } = item;

      let isActive = false;

      if (activeTab) {
        isActive = activeTab.includes(value);
      }

      return (
        <Item key={index}>
          <NavLink
            title={label}
            path={value}
            isActive={isActive}
            onClick={() => handleLink(value)}
          />
        </Item>
      );
    });
  };

  return (
    <Wrapper theme={theme}>
      <Container>
        <NavBar>
          <Logo src={Icon} />
          <Menu>{tabs && renderMenu()}</Menu>
        </NavBar>

        <View>
          <SearchBar user={user} />
          <ButtonExit onClick={logout}>Вихід</ButtonExit>
        </View>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  background-color: ${(p) => p.theme.background};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Logo = styled.img`
  height: 40px;
  margin-right: 10px;
`;

const NavBar = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Menu = styled.ul`
  display: flex;
  height: 100%;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
`;

const View = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonExit = styled.button`
  margin: 0 0 0 30px;
  background: transparent;
  color: ${(p) => p.theme.secondaryColor};
  cursor: pointer;
  height: 100%;
  padding: 5px 15px;
  font-size: 16px;
  &:hover {
    background: ${(p) => p.theme.backgroundActive};
    color: ${(p) => p.theme.accentColor};
  }
`;

export default React.memo(Navigation);
