import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";

import CancelIcon from "@mui/icons-material/Cancel";

const ModalInput = ({
    // props
    isOpen = false,
    onChange = () => { },
    value = "",
    onCloseModal = () => { },
    handleSubmit = () => { },
    isLoad = false,
}) => {
    const theme = useTheme();

    const handlerCloseModal = async () => {
        onCloseModal();
    }

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={handlerCloseModal}
        >
            {
                !isLoad && (
                    <>
                        <TextInput
                            title="Назва"
                            type="text"
                            value={value}
                            isStyle={true}
                            onChange={(e) => onChange(e.target.value)}
                        />
                        <RowBtn>
                            <Item>
                                <ButtonIcon
                                    onClick={handleSubmit}
                                    title={'Підтвердити'}
                                    colorBg="green"
                                />
                            </Item>
                            <Item>
                                <ButtonIcon
                                    onClick={handlerCloseModal}
                                    title='Скасувати'
                                    colorBg="red"
                                    icon={<CancelIcon />}
                                />
                            </Item>
                        </RowBtn>
                    </>
                )
            }
            {
                isLoad && (
                    <LoadingCircl mins={true} />
                )
            }
        </ModalLayout>
    )
}

const RowBtn = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
`;

const Item = styled.div`
    padding: 0 10px;
`;


export default ModalInput;