import styles from "../scss/settings.module.scss";

import Reasons from "./Reasons";

const BlackListPage = () => {
    return (
        <div className={`${styles.d_grid} ${styles.grid_gap} ${styles.col_2}`}>
            <div>
                <Reasons />
            </div>
        </div>
    )
}

export default BlackListPage;