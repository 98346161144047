const API_URL_GOOGLE = "https://maps.google.com/maps/api/geocode/json";
const API_KEY_GOOGLE = process.env.GOOGLE_API_KEY || "AIzaSyAlOTGOPRI3wZKLhHL_g699NFwQmS662Lk";

// получения адресса по кординатам
export async function getGeodataAddress(lat, lng) {
  return await fetch(
    `${API_URL_GOOGLE}?latlng=${lat},${lng}&language='uk'&key=${API_KEY_GOOGLE}`
  )
    .then((res) => res.json())
    .then((data) => {
      if (data?.status.includes("OK")) {
        if (data.results?.[0]) {
          let addressComponents = data.results?.[0].address_components;
          let streetNumber = null;
          let street = null;
          let city = null;
          let region = null;
          let strange = null;

          addressComponents.forEach((item) => {
            switch (item.types[0]) {
              case 'street_number':
                streetNumber = item.long_name;
                break;
              case 'route':
                street = item.long_name;
                break;
              case 'locality':
                city = item.long_name;
                break;
              case 'administrative_area_level_1':
                region = item.long_name;
              case 'country':
                strange = item.long_name;
            }
          });

          if (!!street && !!streetNumber) {
            return {
              house_number: streetNumber,
              street: street,
              city: city,
              region: region,
              strange: strange,
              plusCode: data?.plus_code?.compound_code,
            }
          } else return null;
        }
      } else return null;
    })
    .catch((e) => console.error(e));
}

// получения кординат по адрессу
export async function getGeodataCoordinates(city, settlement_id, street, house) {
  return await fetch(
    `${API_URL_GOOGLE}?address=${city} ${settlement_id} ${street} ${house}&key=${API_KEY_GOOGLE}`
  )
    .then((res) => res.json())
    .then((data) => {

      if (data?.status.includes("OK")) {
        let lat = null;
        let lng = null;
        let results = data?.results[0];
        let location = results.geometry.location;
        lat = location.lat;
        lng = location.lng;

        if (lat && lng) {
          return { lat, lng };
        } else return null;
      } else console.log("found nothing", data);

    })
    .catch((e) => console.error(e));
}
