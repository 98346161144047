import React from "react";

import { Check } from "./check";
import { CheckKitchenAll } from "./checkKitchenAll";
import { CheckCashalot } from "./checkCashalot";

import styles from "./styles.module.scss";

export const PrintCheck = React.forwardRef((props, ref) => {
    const { data, typeCheck, linkQr = '' } = props;

    if (typeCheck == "check" && !!!data?.id) {
        return <div></div>;
    }

    if (typeCheck == "kitchen" && !!!data?.kitchen) {
        return <div></div>;
    }

    if (typeCheck == "checkCashalot" && !data) {
        return <div></div>;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.main} ref={ref}>
                {
                    (typeCheck == "check") && (
                        <Check data={data} />
                    )
                }

                {
                    (typeCheck == "kitchen") && (
                        <CheckKitchenAll data={data} />
                    )
                }

                {
                    (typeCheck == "checkCashalot") && (
                        <CheckCashalot data={data} linkQr={linkQr} />
                    )
                }
            </div>
        </div>
    )
})