import { isArray } from "lodash";

export const orderSearchById = (orders, id) => {
    if (isArray(orders)) {
        return orders.find(el => el.id === id);
    }

    return undefined;
}

export const ordersClientOfComplaint = (arr = []) => {
    return arr.map((item) => {
        return { label: `id: ${item.id}, Час: ${item.created_at.split('T')[0]}`, value: item.id };
    });
}