import PropTypes from "prop-types";

import { MenuItem } from "./menuItem";
import ButtonIcon from "components/ui-kit/ButtonIcon";


import AddIcon from "@mui/icons-material/Add";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import styles from "./scss/menuPage.module.scss";

const MenuPage = ({
    // props
    items = {},
    activeTabs,
    setActiveTabs,
    isBtnAdd = false,
    isBtnPrint = false,
    isBtnKitchenCounter = false,
    handleCreateOrder = () => { },
    handlePrintAll = () => { },
    handleBtnKitchenCounter = () => { },
}) => {
    const onHandle = (key) => {
        setActiveTabs((prev) => {
            let status = false;
            let arr = [...prev];

            arr.map((el, index) => {
                if ((el == key) && (arr.length > 1)) {
                    arr.splice(index, 1);
                    status = true;
                }
            });

            if (!status) {
                arr.push(key);
                status = false;
            }

            return arr;
        })
    }

    return (
        <div className={styles.wr}>
            <ul className={styles.ul}>
                {
                    Object.keys(items).map((key) => {
                        let active = activeTabs.indexOf(key) != -1;

                        return (
                            <MenuItem
                                key={key}
                                inKey={key}
                                isActive={active}
                                icon={items[key]?.icon}
                                title={items[key].title}
                                onHandle={onHandle}
                            />
                        )
                    })
                }
            </ul>

            <div className={styles.btns_r}>
                {
                    !!isBtnKitchenCounter && (
                        <div>
                            <ButtonIcon
                                onClick={handleBtnKitchenCounter}
                                icon={<CurrencyExchangeIcon />}
                                positionIcon="left"
                            />
                        </div>
                    )
                }
                {
                    !!isBtnPrint && (
                        <div>
                            <ButtonIcon
                                onClick={handlePrintAll}
                                icon={<LocalPrintshopIcon />}
                                positionIcon="left"
                            />
                        </div>
                    )
                }
                {
                    !!isBtnAdd && (
                        <div>
                            <ButtonIcon
                                onClick={handleCreateOrder}
                                icon={<AddIcon />}
                                positionIcon="left"
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

MenuPage.prototype = {
    items: PropTypes.object,
};

export default MenuPage;