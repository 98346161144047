import api from "../../apiSingleton";

import { UPDATE_USER } from "../constants/user";

import {
  addNotification
} from './notifications';

export function getUser() {
  return async (dispatch) => {
    const res = await api.user.get();

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: UPDATE_USER,
        payload: { user: { ...res.data } },
      });
    }
  };
}

