import styled from "./BtnDisplayType.module.scss";

import TableRowsIcon from '@mui/icons-material/TableRows';
import DashboardIcon from '@mui/icons-material/Dashboard';

const BtnDisplayType = ({
    isType = 'table',
    onHandler = () => { },
}) => {
    return (
        <button
            className={styled.btnType}
            onClick={onHandler}
        >
            {
                isType.includes('table') ? <DashboardIcon /> : <TableRowsIcon />
            }
        </button>
    )
}

export default BtnDisplayType;