import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as Resource } from './Resource';

function mapStateToProps(state) {
    return {
        resourceEdit: state.settings.resourceEdit,
        resource: state.settings.resource,
        view: state.view,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Resource);
