import styled, { useTheme } from "styled-components";

import SearchIcon from "@mui/icons-material/Search";

export const InputSearch = ({
    placeholder = "",
    value = "",
    onChange = () => { },
    onKeyDown = () => { },
    width = 200
}) => {
    const theme = useTheme();

    return (
        <Label theme={theme} width={width}>
            <Icone>
                <SearchIcon />
            </Icone>
            <Input
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e)}
                onKeyDown={onKeyDown}
            />
        </Label>
    )
}

const Label = styled.label`
    position: relative;
    width: ${(p) => p.width}px;
    display: flex;
    outline: none;
`;

const Input = styled.input`
    width: 100%;
    height: 42px;
    border: 1px solid ${(p) => p.theme.colorDE};
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 14px;
    &::placeholder {
        font-weight: 400;
    }
    font-size: 14px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 2px 12px 2px 40px;
    &:active,
    &:focus {
        border: 1px solid ${(p) => p.theme.accentColor};
    }
    outline: none;
`;

const Icone = styled.span`
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: none;
    cursor: pointer;
    svg {
        color: #ABBBC2;
    }
`;