import { connect } from "react-redux";

import * as actionOrders from "store/actions/orders";

import { default as RenderOrders } from "./RenderOrders";

function mapStateToProps(state) {
  const { view } = state;

  return {
    idLoad: view.idLoadCook
  };
}

const mapDispatchToProps = {
  ...actionOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderOrders);
