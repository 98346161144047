export const ItemHeadCol = ({
    index,
    itemHead,
}) => {
    return (
        <div
            key={index}
            className="report-head__item item-col-table"
            title={itemHead.name}
        >
            <span>{itemHead.name}</span>
        </div>
    )
}