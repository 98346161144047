import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as orderActions from "store/actions/order";
import * as ordersActions from "store/actions/orders";
import * as clientsActions from "store/actions/clients";
import * as couriersActions from "store/actions/couriers";

import { default as ModalEditor } from "./ModalEditor";

function mapStateToProps(state) {
  return {
    reason: state.order.reason,
    reasonData: state.order.reasonData,
    order: state.order.data,
    isOpen: state.view.modalCallCenter.isOpen,
    data: state.view.modalCallCenter.data,
    activeOrder: state.orders.activeOrder,
    orderStatus: state.orders.orderStatus,
    fullUsers: state.users.fullUsers,
    loadingsModal: state.loadings.loadModalEdition.create,
    promotions: state.settings.promotions,
    isOpenModalReason: state.order.isOpenModalReason,
    isLoadModalReason: state.order.isLoadModalReason,
    kitchens: state.settings.kitchens,
    payment: state.settings.payment,
    typeListDeliver: state.settings.typesOfDelivery.list,
  };
}

const mapDispatchToProps = {
  ...viewActions,
  ...orderActions,
  ...clientsActions,
  ...couriersActions,
  ...ordersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditor);
