export const messages = {
    doneEdit: {
        title: ['Змінено'],
    },
    doneCreate: {
        title: ['Створено'],
    },
    doneDelete: {
        title: ['Видалено'],
    },
}