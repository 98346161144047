import produce from 'immer';

import {
    CHECK_SESSION_SUCCESS,
    LOGOUT,
    UPDATE_JWT,
    UPDATE_SESSION_FAILURE,
    UPDATE_SESSION_SUCCESS,
    LOGOUT_BUTTON
} from '../constants/session';

const initialState = {
    isUserLoggedIn: false,
    isSessionChecked: false,
    jwt: ''
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case CHECK_SESSION_SUCCESS: {
            draft.isSessionChecked = true;
            break;
        }

        case UPDATE_SESSION_SUCCESS: {
            draft.isUserLoggedIn = true;
            break;
        }

        case UPDATE_JWT: {
            draft.jwt = payload.jwt;
            break;
        }

        case UPDATE_SESSION_FAILURE:
        case LOGOUT: {
            draft.isUserLoggedIn = false;
            break;
        }

        case LOGOUT_BUTTON: {
            return initialState;
        }

        default:
            break;
    }
}, initialState);
