import { useEffect } from "react";

import Complaint from "./Complaint";
import Resourse from "./Resourse";

import styles from "./../scss/settings.module.scss";

const Complaints = ({
    // dispath
    getComplaintAll,
    getComplaintAllSources,
}) => {
    useEffect(() => {
        getComplaintAll();
        getComplaintAllSources();
    }, []);

    return (
        <div className={styles.bloks}>
            <div className={`${styles.d_grid} ${styles.grid_gap} ${styles.col_2}`}>
                <div className={styles.col_border_r}>
                    <Complaint />
                </div>
                <div>
                    <Resourse />
                </div>
            </div>
        </div>
    )
}

export default Complaints;