import styled, { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";

const Back = () => {
    let history = useHistory();
    let theme = useTheme();

    const handlerBack = () => {
        history.goBack();
    }

    return (
        <HeadRebot theme={theme} onClick={() => handlerBack()}>{`< Назад`}</HeadRebot>
    )
}

const HeadRebot = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;
    color: ${(p) => p.theme.accentColor};
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.4s;
`;

export default Back;