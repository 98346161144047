import { isArray, isObject } from "lodash";

export const typePrice = (payments, isPayment = true) => {
    let price = 0;
    let bonus = 0;
    let typePayment = [];

    if (isArray(payments) && payments.length > 0) {
        payments.map((itemPayments) => {
            if (itemPayments.payment_type == "bonus" || itemPayments.payment_type == "promocode" || itemPayments.payment_type == "rucna-zniska") {
                bonus += itemPayments.sum;
            } else if (itemPayments.payment_type != 'online') {
                price += itemPayments.sum;
            }

            typePayment.push(`${itemPayments?.payment?.title} ${isPayment ? itemPayments.sum : ''}`);
        });
    }

    return {
        price,
        bonus,
        typePayment,
    }
}

// определения Ціни в зависимости от города
export const priceOrCity = (citySyncId, list) => {
    let price = null;

    if (citySyncId) {
        if (isArray(list))
            price = list.find(item => item.city_sync_id === citySyncId).price;
    } else {
        price = list[0].price;
    }

    return price;
}

export const searchPriceTypeDelivery = (dataOrder = {}, delivery_prices = [], element, typeListDeliver = []) => {
    let price = 0, isPartner = false;

    if (isArray(typeListDeliver)) {
        for (let i = 0; typeListDeliver.length > 0; i++) {
            if (typeListDeliver[i].id == dataOrder.delivery_type_id) {
                isPartner = !!typeListDeliver[i].partner_price;
                break;
            }
        }
    }

    if (isPartner) {
        if (isObject(element) && isArray(delivery_prices)) {
            if (dataOrder.delivery_type_id > 1) {
                if (delivery_prices.length > 0) {
                    for (let i = 0; i < delivery_prices.length; i++) {
                        if (delivery_prices[i].delivery_type_id == dataOrder.delivery_type_id) {
                            price = delivery_prices[i].price;
                            break;
                        }
                    }
                } else {
                    price = element.price;
                }
            } else {
                price = element.price;
            }
        }
    } else {
        price = element?.price || 0;
    }

    return price;
}