import { useEffect, useState } from "react";

export const useKeyPress = (keyTarget) => {
    const [isKeyPressed, setIsKeyPressed] = useState(false);
    
    const downHandle = ({ key }) => {
        if (key == keyTarget) setIsKeyPressed(true);
    }

    const unHanlde = ({ key }) => {
        if (key == keyTarget) setIsKeyPressed(false);
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandle);
        window.addEventListener('keyup', unHanlde);

        return () => {
            window.addEventListener('keydown', downHandle);
            window.addEventListener('keyup', unHanlde);
        }
    }, []);

    return isKeyPressed;
}