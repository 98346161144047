import React from "react";
import styled from "styled-components";
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

import MenuPage from "components/menuPage";

import PagePrepare from "./Prepare";
import PageOther from "./Other";

import { menuCook, menuCookOther } from "constants/menuCook";
import { isObject } from "lodash";

const CookPage = () => {
  const { path } = useRouteMatch();

  return (
    <Wrapper>
      <MenuPage items={{ ...menuCook, ...menuCookOther }} isTime={true} />

      <MainCook>
        <Switch>
          <Route component={PagePrepare} exact path={`${path}${menuCook.common.path}`} />
          {
            isObject(menuCookOther) && Object.keys(menuCookOther).map((key, index) => (
              <Route key={index} component={PageOther} exact path={`${path}${menuCookOther[key].path}`} />
            ))
          }
        </Switch>
      </MainCook>
    </Wrapper>
  );
};

const MainCook = styled.div`
  margin: 0 -24px;
  padding: 0 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: -20px;
  background: #524a4a;
  min-height: calc(100vh - 127px);
`;

const Wrapper = styled.div`
  position: relative;
`;

export default React.memo(CookPage);