import PropTypes from "prop-types";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

import styles from "./notifications.module.scss";

export const Notification = ({
    // props
    data,
    index,
    removeNotification
}) => {
    let Icon = CheckCircleOutlineIcon;
    let className = 'done';

    switch (data.type) {
        case 'warn':
            Icon = WarningIcon;
            className = 'warn';
            break;
        case 'error':
            Icon = ErrorIcon;
            className = 'error';
            break;
    }

    const remove = () => {
        removeNotification(index);
    }

    return (
        <div className={`${styles.item} ${styles[className]}`}>
            <div className={styles.icon}>
                <Icon />
            </div>
            <div className={styles.main}>{data.text}</div>
            <button className={styles.btn} onClick={remove}>
                <CloseIcon />
            </button>
        </div>
    )
}

Notification.propTypes = {
    data: PropTypes.object,
    index: PropTypes.number,
    removeNotification: PropTypes.func,
};