import React from 'react';
import Modal from 'react-modal'
import styled, { useTheme } from "styled-components";

import CloseIcon from "@mui/icons-material/Close";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const ModalLayout = ({
    modalIsOpen = false,
    closeModal = () => { },
    children,
    isfull = false,
    fullWidth = "1200px",
    dopClass = '',
}) => {
    const theme = useTheme();

    const afterOpenModal = () => { }

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            theme={theme}
            contentLabel="Example Modal"
            className={`modal__wrapp ${dopClass}`}
            overlayClassName="modal__opas"
        >
            <WrModal isfull={isfull} fullWidth={fullWidth} className='moda-contest'>
                <CloseButton onClick={closeModal}>
                    <CloseIcon style={{ fontSize: 36 }} />
                </CloseButton>
                <ModalContent className='moda-contest__wr'>
                    {children}
                </ModalContent>
            </WrModal>
        </Modal>
    );
}

const WrModal = styled.div`
    margin: 50px 0;
    flex-shrink: 0;
    flex-grow: 0;
    background: #fff;
    max-width: ${(p) => p.isfull ? p.fullWidth : "500px"};
    width: 100%;
    overflow: visible;
    flex-shrink:0;
    position: relative;
`;

const ModalContent = styled.div`
    padding: 20px;
    position: relative;
    z-index: 44000;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  top: -40px;
  right: 0;
  display: block;
  outline: none;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }

  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30000;
  }
`;

export default ModalLayout;
