import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as RestaurantPage } from './Restaurant';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        data: settings.editRestaurant,
        isLoadPage: view.isLoadPage,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantPage);
