import PropTypes from "prop-types";
import { useEffect } from "react";

import styles from "../StatisticPage.module.scss";
import { OrdersKitchen } from "./OrdersKitchen";
import { ModalLoad } from "components/modals/ModalLoad";

import { isEditNone } from "utils/helpers/checks";

const Orders = ({
    //state
    orders,
    user,
    //dispatch
    getOrders,
    loadPage
}) => {
    const isNone = isEditNone(user);

    useEffect(() => {
        if (isNone) {
            getOrders();

            const interval = setInterval(() => {
                getOrders();
            }, 10000);

            return () => clearInterval(interval);
        }
    }, []);

    return (
        <div className={styles.rows_dashboard}>
            <div className={styles.col_2}>
                <div className={styles.title_col}>Скільки замовленнь на кухні. На теперішній час</div>
                <div className={styles.wr_item}>
                    {
                        isNone && (
                            <OrdersKitchen data={orders} />
                        )
                    }
                </div>
            </div>

            {
                loadPage && (
                    <ModalLoad />
                )
            }
        </div>
    )
}

Orders.propTypes = {
    orders: PropTypes.object,
    getOrders: PropTypes.func,
};

export default Orders;