import { NavLink } from "react-router-dom";

import styles from "./scss/menuPage.module.scss";

export const MenuItem = ({
    //props
    exact = false,
    to,
    icon,
    title,
}) => {
    let className = `${styles.icon}`;

    title.length == 0 && (className += ` ${styles.icon_marg}`)

    return (
        <li className={styles.li}>
            <NavLink
                exact={exact}
                className={styles.a}
                activeClassName={styles.active}
                to={to}
            >
                {
                    icon && (
                        <span className={className}>{icon}</span>
                    )
                }
                {
                    title.length > 0 && <span className={styles.title}>{title}</span>
                }
            </NavLink>
        </li>
    )
}