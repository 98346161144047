import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { isArray } from "lodash";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Table from "components/ui-kit/Table";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import AddIcon from "@mui/icons-material/Add";
import styles from "../scss/settings.module.scss";

import { tablePayment } from "constants/tables";
import { PaymentEdit } from "./PaymentEdit";

const Payment = ({
    // state
    view,
    paymentData,
    paymentDataEdit,
    // dispatch
    getPayment,
    updateFormPayment,
    createPayment,
    updatePaymentId,
    getPaymentId
}) => {
    const [isEdit, setIsEdit] = useState({
        status: false,
        id: null,
    });

    useEffect(() => {
        getPayment();
    }, []);

    const handlerEdit = async ({ id }) => {
        await setIsEdit({ id, status: true });
        await getPaymentId(id);
    }

    const handlerCreate = () => {
        setIsEdit((prev) => ({ ...prev, ...{ status: true } }));
    }

    const handleSubmitClose = () => {
        setIsEdit((prev) => ({ ...prev, ...{ status: false } }));
    }

    const onSubmit = async () => {
        createPayment(handleSubmitClose);
    }

    const onSubmitUpdate = () => {
        updatePaymentId(isEdit.id, handleSubmitClose);
    }

    return (
        <div className={styles.d_max_1023}>
            <div className={styles.wr_head}>
                <ButtonIcon
                    onClick={handlerCreate}
                    title='Додати тип оплати'
                    icon={<AddIcon />}
                    positionIcon="left"
                    disabled={isEdit.status}
                />
            </div>
            <div className={styles.wr_row}>
                {
                    !view?.isLoadPage ? (
                        isArray(paymentData) && (
                            <Table
                                onClick={handlerEdit}
                                data={paymentData}
                                scheme={tablePayment}
                            />
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </div>
            {
                isEdit.status && (
                    <PaymentEdit
                        data={paymentDataEdit}
                        view={view}
                        onSubmit={onSubmit}
                        handleSubmitClose={handleSubmitClose}
                        onSubmitUpdate={onSubmitUpdate}
                        updateFormPayment={updateFormPayment}
                    />
                )
            }
        </div>
    )
}

Payment.propTypes = {
    view: PropTypes.object.isRequired,
    paymentData: PropTypes.array.isRequired,
    paymentDataEdit: PropTypes.object.isRequired,
    updateFormPayment: PropTypes.func.isRequired,
    createPayment: PropTypes.func.isRequired,
    updatePaymentId: PropTypes.func.isRequired,
    getPaymentId: PropTypes.func.isRequired,
};

export default Payment;