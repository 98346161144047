import { connect } from "react-redux";

import { default as SalesPage } from "./Sales";

import * as salesActions from "store/actions/salses";
import * as settingActions from "store/actions/settings";

function mapStateToProps(state) {
    return {
        userMe: state.user.user,
        view: state.view,
        salesList: state.sales.list,
        filter: state.sales.filter,
        kitchens: [...[{ title: '-', code: null, status: 1 }], ...state.settings.kitchens],
        cashierList: [...["-"], ...state.settings.cashier.list],
        metodPaymentsList: state.settings.metodPayments.list,
    };
}

const mapDispatchToProps = {
    ...salesActions,
    ...settingActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesPage);
