import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";

import MenuGeneral from "components/menuGeneral";

import styles from "./../scss/settings.module.scss";

import { generalDataItemsMenu } from 'constants/menuPageSettings';

import Home from './Home';
import PageRoles from '../Roles';
import PageRole from '../Roles/Role';
import PagePayment from '../Payment';
import PageTypesOfDeliveryPage from "../TypesOfDelivery";
import BlackListReasons from "../BlackList";
import PageKitchens from '../Kitchens';
import PageKitchen from '../Kitchens/Kitchen';
import PagePromocodes from '../Promocodes';
import PagePromotions from '../Promotions';
import PageRestaurants from '../Restaurants';
import PageRestaurant from '../Restaurants/Restaurant';
import PageCities from '../Cities';
import PageCity from '../Cities/City';
import PageLocations from '../Locations';
import PageLocation from '../Locations/Location';
import PagePickup from '../Pickup';
import PageSourseOfCustomers from '../SourseOfCustomers';
import PageComplaints from "../Complaints";
import PageCategories from "../Categories";
import PageTelestat from "../Telestat";

const General = () => {
    let { path } = useRouteMatch();

    return (
        <div className={styles.ge_wr}>
            <div className={styles.ge_menu}>
                <MenuGeneral items={generalDataItemsMenu} />
            </div>
            <div className={styles.ge_main}>
                <Switch>
                    <Route component={Home} exact path={`${path}${generalDataItemsMenu['common'].path}`} />

                    <Route component={PageKitchens} exact path={`${path}${generalDataItemsMenu['kitchens'].path}`} />
                    <Route component={PageKitchen} path={`${path}${generalDataItemsMenu['kitchens'].path}/:code`} />

                    <Route component={PagePromocodes} exact path={`${path}${generalDataItemsMenu['promocodes'].path}`} />

                    <Route component={PagePromotions} exact path={`${path}${generalDataItemsMenu['promotions'].path}`} />

                    <Route component={PageRestaurants} exact path={`${path}${generalDataItemsMenu['restaurants'].path}`} />
                    <Route component={PageRestaurant} path={`${path}${generalDataItemsMenu['restaurants'].path}/:code`} />

                    <Route component={PageCities} exact path={`${path}${generalDataItemsMenu['cities'].path}`} />
                    <Route component={PageCity} path={`${path}${generalDataItemsMenu['cities'].path}/:sync_id`} />

                    <Route component={PageLocations} exact path={`${path}${generalDataItemsMenu['locations'].path}`} />
                    <Route component={PageLocation} path={`${path}${generalDataItemsMenu['locations'].path}/:id`} />

                    <Route component={PagePickup} exact path={`${path}${generalDataItemsMenu['pickup'].path}`} />

                    <Route component={PageRoles} exact path={`${path}${generalDataItemsMenu['roles'].path}`} />
                    <Route component={PageRole} path={`${path}${generalDataItemsMenu['roles'].path}/:id`} />

                    <Route component={PagePayment} exact path={`${path}${generalDataItemsMenu['payment'].path}`} />
                    <Route component={PageTypesOfDeliveryPage} exact path={`${path}${generalDataItemsMenu['typesOfDelivery'].path}`} />
                    <Route component={BlackListReasons} exact path={`${path}${generalDataItemsMenu['blackList'].path}`} />
                    <Route component={PageSourseOfCustomers} exact path={`${path}${generalDataItemsMenu['sourseForCustomers'].path}`} />
                    <Route component={PageComplaints} exact path={`${path}${generalDataItemsMenu['complaints'].path}`} />
                    <Route component={PageCategories} exact path={`${path}${generalDataItemsMenu['categories'].path}`} />
                    <Route component={PageTelestat} exact path={`${path}${generalDataItemsMenu['telestat'].path}`} />
                </Switch>
            </div>
        </div>
    )
}

export default General;