export const fileSizeCheck = (file, callbackFunction, size = 5120000) => {
  if (file?.size > size) {
    alert(`File: ${file.name} exceeded the 5MB limit`);
  } else {
    callbackFunction(file);
  }
}

export const uploadProgress = (percent) => {
  return Math.round(
    (percent.loaded * 100) / percent.total
  );
}