import React, { useEffect, useState } from "react";
import { isArray, isObject } from "lodash";
import styled, { useTheme } from "styled-components";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { typePrice } from "utils/helpers/price";

const RowItem = ({
  data,
  item,
  price,
  bonus,
  typePayment,
  floor,
  userData,
  isTechnical = false,
  openEdit = () => { },
}) => {
  const theme = useTheme();
  const [isUpdadeAdressStatusCycle, setIsUpdadeAdressStatusCycle] = useState(false);
  const [isUpdateSttatusPayments, setIsUpdateSttatusPayments] = useState(false);

  let timeCook = item.time_flags?.end_cooking ? item.time_flags?.end_cooking : { time: item.time_flags.time, class: "green" }

  useEffect(() => {
    if ((item.user_id_change_address != userData.id) || (item.user_id_change_payments != userData.id)) {
      if (item.change_address > 0 || item.payments_changed > 0) {
        let timeCyccleW = setInterval(() => {
          if (item.change_address > 0) {
            setIsUpdadeAdressStatusCycle(prev => !prev);
          }

          if (item.payments_changed > 0) {
            setIsUpdateSttatusPayments(prev => !prev);
          }
        }, 2000);

        return () => clearInterval(timeCyccleW);
      } else {
        setIsUpdadeAdressStatusCycle(false);
        setIsUpdateSttatusPayments(false);
      }
    }

  }, [data]);

  return (
    <Rows
      theme={theme}
      isUpdateAddress={isUpdadeAdressStatusCycle}
      isUpdateSttatusPayments={isUpdateSttatusPayments}
    >
      {
        isTechnical && (
          <div className="technical-info">Технічне замовлення</div>
        )
      }
      <Item width={7} color={timeCook.class} onClick={openEdit}>
        {
          item?.status == "closed" ? (
            <div className="closed_time">
              <i>Закрите: </i>
              <div>
                {
                  item.history.find(el => (el.status == "closed"))?.set_at
                }
              </div>
            </div>
          ) : (
            <Time>{timeCook.time}</Time>
          )
        }
      </Item>
      <Item width={5} onClick={openEdit}>
        <Name>{item.id}</Name>
      </Item>
      <Item width={6} onClick={openEdit}>{item.restaurant_info.name || "Не вибрано"}</Item>
      <Item width={12} onClick={openEdit}>
        <Name>{item.client.name}</Name>
      </Item>
      <Item width={14}>
        <a className="tel" href={`tel:${item.client.phone}`}>{item.client.phone}</a>
      </Item>
      <Item width={18} onClick={openEdit}><div className="address">{`${item.address.city.name_ua}, ${item.address.street}, ${item.address.house_number} ${floor}`}</div> </Item>
      <Item width={15} onClick={openEdit}>{item?.operator_full_name}</Item>
      <Item isList={true} width={12} onClick={openEdit}>
        <div className="payments">
          <ListPris>
            {
              isArray(typePayment) && typePayment.map((itemPa, i) => (
                <span key={i}>{itemPa}</span>
              ))
            }
          </ListPris>
        </div>
      </Item>
      <Item width={7} onClick={openEdit}>-{item?.discount}</Item>
      <Item width={7} onClick={openEdit}>{item?.to_pay}</Item>
    </Rows>
  )
}

const OrdersListObj = ({
  data,
  title,
  openEditorCallcenter,
  getCallcenterOrderItem,
  resetChangeAddress,
  idOrder,
  userData
}) => {
  const theme = useTheme();

  const [isExpand, setExpand] = useState(true);

  const handleOpenEditor = async (item, isChange) => {
    await openEditorCallcenter(item);
    await getCallcenterOrderItem(item.id);

    if (!!isChange)
      await resetChangeAddress(item.id);
  };

  const renderBody = () => {
    return Object.keys(data).map((key) => {
      let itemArray = data[key];

      return isObject(itemArray[0]) && isArray(itemArray) && itemArray.map((item, index) => {
        let floor = item?.address?.floor ? `, кв. ${item.address.floor}` : '';
        let { price, bonus, typePayment } = typePrice(item.payments, false);
        let isTechnical = (item?.technical == 1);

        return (
          <div key={`orderItem_${index}`}>
            <Wr
              isOpen={idOrder == item.id}
            >
              {
                index == 0 && <NameKitchen>Кухня: {item.kitchen.title}</NameKitchen>
              }
              <RowItem
                isTechnical={isTechnical}
                openEdit={() => handleOpenEditor(item, item.change_address || item.payments_changed)}
                item={item}
                price={price}
                bonus={bonus}
                typePayment={typePayment}
                floor={floor}
                data={data}
                userData={userData}
              />
            </Wr>
          </div>
        )
      })
    })
  };

  return (
    <Wrapper theme={theme}>
      <Titles onClick={() => {
        if (Object.keys(data).length > 0) {
          setExpand(!isExpand)
        }
      }} isExpand={isExpand}>
        {title}
        {
          (Object.keys(data).length > 0) && (
            <IconWr isExpand={isExpand}>
              <ArrowDownwardIcon />
            </IconWr>
          )
        }
      </Titles>

      {Object.keys(data).length ? (
        <Table isExpand={isExpand}>
          {renderBody()}
        </Table>
      ) : (
        <Text>Немає замовлень у цьому статусі</Text>
      )}

    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin-bottom: 15px;
                `;

const Text = styled.p`
                text-align: center;
                color: ${(p) => p.theme.lightAccentColor};
                `;

const Table = styled.div`
                display: ${(p) => (p.isExpand ? "table" : "none")};
                width: 100%;
                border-collapse: collapse;
                `;

const Wr = styled.div`
                margin-top: 16px;
                position: relative;
                z-index: ${(p) => p.isOpen ? 99 : 0}
                `;

const Titles = styled.div`
                font-weight: 600;
                font-size: 20px;
                color: ${(p) => p.theme.color25};
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: 0.4s;
                &:hover {
                  color: ${(p) => p.theme.accentColor};
    }
                `;

const Rows = styled.div`
                display: flex;
                align-items: center;
                background-color: ${(p) => (p.isUpdateAddress || p.isUpdateSttatusPayments) ? "#ffcf93" : "#fff"};
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                margin-top: 16px;
                cursor: pointer;
                transition: 0.4s;
                position: relative;
                .address {
                  color: ${(p) => p.isUpdateAddress ? "red" : "#425466"};;
                  font-weight: ${(p) => p.isUpdateAddress ? "500" : "400"};;
                }

                .payments {
                  color: ${(p) => p.isUpdateSttatusPayments ? "orange" : "#425466"};
                  font-weight: ${(p) => p.isUpdateSttatusPayments ? "500" : "400"};
                }

                &:hover {
                  background - color: #E6E6E6;
    }
                `;

const Item = styled.div`
                min-height: 56px;
                width: ${(p) => p.width}%;
                font-size: 14px;
                color: ${(p) => p.theme.color42};
                padding: 5px 5px 5px 5px;
                background: ${(p) => p.color};
                // white-space: nowrap;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                &:first-child {
                  border-radius: 8px 0px 0px 8px;
    }
                &:last-child {
                  border-radius: 0px 8px 8px 0px;
    }

    .tel {
      padding: 8px 0;
      &:hover {
        color: red;
      }
    }

    .closed_time {
      display: flex;
      flex-direction: column;
      i {
        color: green;
      }
    }
                `;

const ListPris = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Time = styled.div`
                width: 100%;
                height: 100%;
                border-radius: inherit;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                `;

const Name = styled.div`
               padding-left: 10px;
                `;

const IconWr = styled.span`
                svg {
                  transform: rotate(${(p) => p.isExpand ? "-180deg" : "0deg"});
  }
                margin-left: 20px;
                `;

const NameKitchen = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export default React.memo(OrdersListObj);
