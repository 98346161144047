import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as LocationPage } from './Location';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        data: settings.editLocation,
        cities: [...[{ title: '-', sync_id: "-", status: 1 }], ...settings.cities],
        restaurants: [...[{ name: '-', code: "-", status: 1 }], ...settings.restaurants],
        kitchens: [...[{ title: '-', code: "-", status: 1 }], ...settings.kitchens],
        isLoadPage: view.isLoadPage,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationPage);
