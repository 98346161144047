import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Back from "components/ui-kit/Back";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import InputImage from "components/ui-kit/InputImage";
import { LoadingModal } from "components/loadingOrder/loading";
import { TextArea } from "components/ui-kit/inputs/TextArea";

import SaveIcon from "@mui/icons-material/Save";
import styles from "./../../scss/settings.module.scss";

import { validateRestaurant } from "utils/validation";
import { dataItemsMenu } from 'constants/menuPageSettings';
import { RADIO_SCHEME } from 'constants/schemes';

const Restaurant = ({
    //state
    data,
    isLoadPage,
    //dispatch
    updateFormRestaurant,
    getRestorant,
    cleaningNewForm,
    updateRestorant,
    createRestorant
}) => {
    let { code } = useParams();
    let history = useHistory();

    const [fileImg, setFileImg] = useState(undefined);
    const [error, setErrors] = useState({
        name: "",
        order_review_sms_text: "",
    });

    let titleButton = data?.id ? 'Змінити' : 'Створити';

    const handlerUpadatUrl = (code) => {
        history.push(`/settings${dataItemsMenu['restaurants'].path}/${code}`);
    }

    const handleFileChange = (objectFile) => {
        setFileImg(objectFile);
    };

    const handleSubmit = () => {
        validateRestaurant({
            data: {
                name: data.name,
                order_review_sms_text: data.order_review_sms_text
            },

            onSuccess: async (validData) => {
                const formData = new FormData();

                if (fileImg) {
                    formData.append('image', fileImg, fileImg.name);
                }

                if (data?.id) {
                    updateRestorant(fileImg ? formData : undefined);
                } else {
                    createRestorant(handlerUpadatUrl, fileImg ? formData : undefined);
                }

                setErrors({
                    name: "",
                    order_review_sms_text: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    useEffect(() => {
        if (code !== "new")
            getRestorant(code);

        if (code == "new")
            cleaningNewForm("editRestaurant");
    }, []);

    return (
        <div className={`${styles.d_max_1023}`}>
            <Back />

            <div className={`${styles.block}`}>
                <InputImage
                    currentImage={data?.logo_path}
                    setFileImage={handleFileChange}
                />
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.name}
                    isStyle={true}
                    error={error.name}
                    onChange={(e) => updateFormRestaurant("name", e.target.value)}
                />
                <TextArea
                    title="Поле тексту, розсилки повідомлень для опитування"
                    isStyle={true}
                    height="150px"
                    error={error.order_review_sms_text}
                    onChange={(e) => updateFormRestaurant("order_review_sms_text", e.target.value)}
                    value={data.order_review_sms_text}
                />
                <div>Інструкція: Змінні для автозаповнення: %order_id% - ID замовленя; %restaurant_code% – код ресторану</div>
                <div className={styles.wr_drop}>
                    <Radio
                        colors={"#000"}
                        items={RADIO_SCHEME}
                        label="Активність ресторану"
                        valueKey="status"
                        value={data.status}
                        onChange={(e) => updateFormRestaurant("status", e.value.value)}
                    />
                </div>
            </div>

            <div className={styles.row_btn}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
            </div>

            {
                isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }
        </div>
    )
}

Restaurant.propTypes = {
    data: PropTypes.object,
    isLoadPage: PropTypes.bool,
    updateFormRestaurant: PropTypes.func,
    getRestorant: PropTypes.func,
    cleaningNewForm: PropTypes.func,
    updateRestorant: PropTypes.func,
    createRestorant: PropTypes.func,
};

export default Restaurant;