import { connect } from 'react-redux';
import { compose } from 'redux';

import * as productsActions from '../../store/actions/products';
import * as ROUTES from '../../constants/routes';
import withRedicrect from '../withRedirect';
import { default as ProductPage } from './ProductPage';

function mapStateToProps(state) {
    return {
        view: state.view,
        productTypes: [...[{ sync_id: "null", name: "-" }], ...state.settings.productCategories],
        restaurants: [...[{ code: "null", name: "-" }], ...state.settings.restaurants],
        typesOfDeliveryList: state.settings.typesOfDelivery.list,
        cities: state.settings.cities,
        formEditProduct: state.products.formProduct,
        urlToRedirect: ROUTES.PRODUCTS
    };
}

const mapDispatchToProps = {
    ...productsActions
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRedicrect
)(ProductPage);
