import produce from 'immer';
import { isArray } from 'lodash';

import {
    GET_REPORTS_LIST,
    UPDATE_REPORTS_ACTIVE,
    UPDATE_FILTER_REPORTS,
    GET_REPORTS_OF_CODE,
    UPDATE_FILTER_REPORTS_ARR
} from '../constants/reports';

const initialState = {
    listReports: [],
    listSelect: [],
    activeSelect: null,
    filter: {
        filterRestorantAll: "all",
        filterRestorant: [],
        filterKitchenAll: "all",
        filterKitchen: [],
        date: [
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
            }
        ]
    }
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case GET_REPORTS_OF_CODE: {
            draft.listReports = payload;
            break;
        }
        case UPDATE_REPORTS_ACTIVE: {
            draft.activeSelect = payload;
            break;
        }
        case GET_REPORTS_LIST: {
            draft.listSelect = payload;

            if (isArray(payload)) {
                draft.activeSelect = payload[0];
            }

            break;
        }
        case UPDATE_FILTER_REPORTS: {
            draft.filter[payload.name] = payload.value;
        }

        case UPDATE_FILTER_REPORTS_ARR: {
            const { name, value, isArr } = payload;

            if (isArr) {
                let index = draft.filter[name].findIndex(el => el == value);

                if (index == -1) {
                    draft.filter[name].push(value);

                    if (name == "filterRestorant") {
                        draft.filter["filterRestorantAll"] = null;
                    }

                    if (name == "filterKitchen") {
                        draft.filter["filterKitchenAll"] = null;
                    }
                } else {
                    draft.filter[name] =  draft.filter[name].filter(el => el != value);
                }
            } else {
                draft.filter[name] = value;

                if (name == "filterRestorantAll") {
                    draft.filter["filterRestorant"] = [];
                }

                if (name == "filterKitchenAll") {
                    draft.filter["filterKitchen"] = [];
                }
            }
        }

        default:
            draft
            break;
    }
}, initialState);
