import api from "../../apiSingleton";

import {
    GET_REPORTS_LIST,
    UPDATE_REPORTS_ACTIVE,
    UPDATE_FILTER_REPORTS,
    GET_REPORTS_OF_CODE,
    UPDATE_FILTER_REPORTS_ARR
} from "../constants/reports";

import {
    updadeLoadPageClients,
} from './view';

import { rangeConverDate } from "utils/helpers/date";

import {
    addNotification
} from './notifications';
import { reportFilterRestKit } from "utils/helpers/reports";

export function getReportsList() {
    return async (dispatch, state) => {
        let res = await api.reports.getReports();

        if (res?.data) {
            await dispatch({
                type: GET_REPORTS_LIST,
                payload: res.data,
            });
        }
    };
}

export const updateActiveSelect = (value) => {
    return {
        type: UPDATE_REPORTS_ACTIVE,
        payload: value,
    }
}

export const updateFilterAction = (name, value) => {
    return {
        type: UPDATE_FILTER_REPORTS,
        payload: { name, value },
    }
}

export const updateFilterArr = (name, value, isArr = false) => {
    return {
        type: UPDATE_FILTER_REPORTS_ARR,
        payload: { name, value, isArr },
    }
}

export function getReportsOfType() {
    return async (dispatch, state) => {
        const { reports: { filter, activeSelect }, settings: { kitchens, restaurants } } = state();
        const code = activeSelect?.params?.code;

        if (code) {
            let params = {
                code,
                restaurants: reportFilterRestKit(filter.filterRestorantAll == "all", filter.filterRestorant, restaurants),
                kitchen_codes: reportFilterRestKit(filter.filterKitchenAll == "all", filter.filterKitchen, kitchens),
                ...rangeConverDate(filter.date),
            }

            dispatch(updadeLoadPageClients(true));

            let res = await api.reports.getReportsOfType(params);

            if (res?.errors) {
                await dispatch(addNotification(res.errors, 'error'));
            } else {
                await dispatch({
                    type: GET_REPORTS_OF_CODE,
                    payload: res.data,
                });
            }

            dispatch(updadeLoadPageClients(false));
        }
    };
}