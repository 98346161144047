import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Back from "components/ui-kit/Back";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingModal } from "components/loadingOrder/loading";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";

import SaveIcon from "@mui/icons-material/Save";
import styles from "./../../scss/settings.module.scss";

import { citiesMapped } from "utils/mappers/cities";
import { restaurantsMapped } from "utils/mappers/restaurants";
import { kitchensMapped } from "utils/mappers/kitchens";
import { validateLocation } from "utils/validation";

import { dataItemsMenu } from 'constants/menuPageSettings';

const Location = ({
    //state
    data,
    isLoadPage,
    cities,
    restaurants,
    kitchens,
    //dispatch
    updateFormLocation,
    cleaningNewForm,
    getLocationId,
    createLocation,
    updateLocation,
}) => {
    let { id } = useParams();
    let history = useHistory();

    const [error, setErrors] = useState({
        name: "",
        restaurant: "",
        city_sync_id: "",
        street: "",
        house_number: "",
        delivery_terminal_id: "",
        kitchen_code: "",
    });

    let titleButton = data?.id ? 'Змінити' : 'Створити';

    const handlerUpadatUrl = (id) => {
        history.push(`/settings${dataItemsMenu['locations'].path}/${id}`);
    }

    const handleSubmit = () => {
        validateLocation({
            data: {
                ...data,
            },

            onSuccess: async () => {
                if (data?.id) {
                    updateLocation();
                } else {
                    createLocation(handlerUpadatUrl);
                }

                setErrors({
                    name: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    useEffect(() => {
        if (id !== "new")
            getLocationId(id);

        if (id == "new")
            cleaningNewForm("editLocation");
    }, []);

    return (
        <div className={`${styles.d_max_1023}`}>
            <Back />

            <div className={`${styles.block}`}>
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.name}
                    isStyle={true}
                    error={error.name}
                    onChange={(e) => updateFormLocation("name", e.target.value)}
                />
                <div className={styles.wr_drop}>
                    <div className={styles.row_form}>
                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                            <Dropdown
                                title="Ресторан"
                                isBlack={true}
                                width="100%"
                                error={error.restaurant}
                                onChange={(e) => updateFormLocation("restaurant", e.target.value)}
                                value={data.restaurant}
                                list={restaurantsMapped(restaurants)}
                                isLight={true}
                                fontSize={'14px'}
                            />
                        </div>
                        <div className={`${styles.wr_drop} ${styles.item_two}`}>
                            <Dropdown
                                title="Кухня"
                                isBlack={true}
                                width="100%"
                                error={error.kitchen_code}
                                onChange={(e) => updateFormLocation("kitchen_code", e.target.value)}
                                value={data.kitchen_code}
                                list={kitchensMapped(kitchens)}
                                isLight={true}
                                fontSize={'14px'}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.wr_drop}>
                    <Dropdown
                        title="Місто"
                        isBlack={true}
                        width="100%"
                        error={error.city_sync_id}
                        onChange={(e) => updateFormLocation("city_sync_id", e.target.value)}
                        value={data.city_sync_id}
                        list={citiesMapped(cities)}
                        isLight={true}
                        fontSize={'14px'}
                    />
                </div>
                <div className={styles.wr_drop}>
                    <div className={styles.row_form}>
                        <div className={`${styles.item_two_flex}`}>
                            <TextInput
                                title="Вулиця"
                                error={error.street}
                                type="text"
                                value={data.street}
                                isStyle={true}
                                onChange={(e) => updateFormLocation("street", e.target.value)}
                            />
                        </div>
                        <div className={`${styles.item_two_house}`}>
                            <TextInput
                                title="Будинок"
                                error={error.house_number}
                                type="text"
                                value={data.house_number}
                                isStyle={true}
                                onChange={(e) => updateFormLocation("house_number", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <TextInput
                    title="Термінал доставки"
                    error={error.delivery_terminal_id}
                    type="text"
                    value={data.delivery_terminal_id}
                    isStyle={true}
                    onChange={(e) => updateFormLocation("delivery_terminal_id", e.target.value)}
                />
            </div>

            <div className={styles.row_btn}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
            </div>

            {
                isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }
        </div>
    )
}

Location.propTypes = {
    data: PropTypes.object,
    cities: PropTypes.array,
    restaurants: PropTypes.array,
    kitchens: PropTypes.array,
    isLoadPage: PropTypes.bool,
    updateFormLocation: PropTypes.func,
    cleaningNewForm: PropTypes.func,
    getLocationId: PropTypes.func,
    createLocation: PropTypes.func,
    updateLocation: PropTypes.func,
};

export default Location;