import { isArray } from "lodash";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";

import { MenuItem } from "./menuItem";

import styles from "./scss/menuPage.module.scss";
import { Clock } from "components/Clock";

const MenuPage = ({
    // props
    items = {},
    isTime = false
}) => {
    let { path } = useRouteMatch();

    return (
        <div className={styles.wr}>
            <div>
                <ul className={styles.ul}>
                    {
                        isArray(items) ? (
                            items.map((item, index) => {
                                let exact = index === 0;

                                return (
                                    <MenuItem
                                        key={index}
                                        exact={exact}
                                        to={`${path}${item.path}`}
                                        icon={item.icon}
                                        title={item.title}
                                    />
                                )
                            })
                        ) : (
                            Object.keys(items).map((key) => {
                                let exact = key === 'common';

                                return (
                                    <MenuItem
                                        key={key}
                                        exact={exact}
                                        to={`${path}${items[key].path}`}
                                        icon={items[key]?.icon}
                                        title={items[key].title}
                                    />
                                )
                            })
                        )
                    }
                </ul>
            </div>
            {
                isTime && (
                    <div className={styles.right}>
                        <Clock />
                    </div>
                )
            }
        </div>
    )
}

MenuPage.prototype = {
    items: PropTypes.object,
};

export default MenuPage;