import Base from "./Base.js";

export default class StatisticAPI extends Base {
    getSalesList(params) {
        return this.apiClient.get("receipt-statistics/receipts", params);
    }
    downloadSalesExcel(params) {
        return this.apiClient.get("receipt-statistics/receipts-excel", params);
    }
    cashalotGetOrderById(params) {
        return this.apiClient.get("cashalot/show-order", params);
    }
    postCashalotCreate(data) {
        return this.apiClient.post("cashalot/create-check", data);
    }
    postCmc(check_id) {
        return this.apiClient.post("cashalot/send-check", { check_id });
    }
    getCheckCashalot(check_id) {
        return this.apiClient.get("cashalot/show-check", { check_id });
    }
}