import { isArray } from "lodash";
import React from "react";
import { ItemHeadCol } from "./TableHeadItem";

export const TableHead = ({
    activeSelect,
}) => {
    return (
        <div className="report-head">
            {
                isArray(activeSelect?.params.columns) && activeSelect?.params.columns.map((itemHead, index) => {
                    let isColums = itemHead?.columns;
                    if (isColums) {
                        return (
                            <div className="head-col-row_two" key={index}>
                                <div className="head-col-row_two__top">{itemHead.name}</div>
                                <div className="head-col-row_two__bot">
                                    {
                                        isArray(itemHead.columns) && itemHead.columns.map((itemCol, indexCol) => {
                                            return (
                                                <ItemHeadCol
                                                    index={index + indexCol}
                                                    itemHead={itemCol}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    } else {
                        return <ItemHeadCol
                            key={index}
                            index={index}
                            itemHead={itemHead}
                        />
                    }
                })
            }
        </div>
    )
}