/*  no-magic-numbers */
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Product.module.scss';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link } from 'react-router-dom';

function Product({
    image,
    titleRu,
    weight,
    id
}) {

    return (
        <div className={styles.product}>
            <div className={styles.front} >
                <div className={styles.frontTop}>
                    <div className={styles.imageContainer}>
                        <img
                            className={styles.productImage}
                            src={image}
                            alt='product'
                        />
                    </div>
                    <div className={styles.text}>
                        <p className={`${styles.title} font-100`}>
                            {titleRu}
                        </p>
                        <p className={`${styles.weight} font-100`}>
                            {weight.toFixed(2)}
                        </p>
                    </div>
                </div>

                <div className={styles.rwEditButton}>
                    <Link to={`/products/${id}`}>
                        <BorderColorIcon />
                        <span>Редактировать</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

Product.propTypes = {
    image: PropTypes.string.isRequired,
    titleRu: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
    restaurant: PropTypes.string.isRequired,
    descriptionRu: PropTypes.string,
    article: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
};

Product.defaultProps = {
    descriptionRu: '',
};

export default React.memo(Product);
