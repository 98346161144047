import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { isArray, isObject } from "lodash";

const workSheetName = 'reports-1';

const columsKey = (cols) => {
    if (!isArray(cols)) return null;

    return cols.map(item => {
        return { header: item.name, key: item.code };
    });
}

const dataTable = (data, cols) => {
    if (!isArray(data)) return [];
    let colKeys = cols.map(item => item.code);

    let arrObjAll = [];

    data.map(item => {
        if (isObject(item)) {
            let keys = Object.keys(item);
            let objRow = {};
            let dopRows = [];

            for (let i = 0; i < keys.length; i++) {
                let itemValue = item[keys[i]];

                if (!isArray(itemValue)) objRow[colKeys[i]] = `${itemValue}`;
                if (isArray(itemValue)) {
                    objRow[colKeys[i]] = itemValue[0];

                    if (itemValue.length > 0) {
                        for (let k = 1; k < itemValue.length; k++) {
                            let k2 = k - 1;

                            if (!dopRows?.[k2])
                                dopRows.push({ [colKeys[i]]: itemValue[k] });

                            if (dopRows?.[k2]) {
                                dopRows[k2][colKeys[i]] = itemValue[k];
                            }
                        }
                    }
                }
            }

            arrObjAll = [...arrObjAll, ...[objRow], ...dopRows];
        }
    });

    return arrObjAll;
}

export const seveExel = async (activeSelect, data) => {
    const workbook = new Excel.Workbook();
    let columns = columsKey(activeSelect?.params.columns);

    if (columns === null) return;

    try {
        const worksheet = workbook.addWorksheet(workSheetName);

        worksheet.columns = columns;
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach(column => {
            column.width = column.header.length + 8;
            column.alignment = { horizontal: 'left' };
        });

        dataTable(data, activeSelect?.params.columns).forEach(singleData => {
            worksheet.addRow(singleData);
            // worksheet.addRow([3, 'Sam', new Date()]);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, row => {
            // store each cell to currentCell
            const currentCell = row._cells;

            // loop through currentCell to apply border only for the non-empty cell of excel
            currentCell.forEach(singleCell => {
                // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                const cellAddress = singleCell._address;

                // apply border
                worksheet.getCell(cellAddress).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${activeSelect.title}.xlsx`);
    } catch (error) {
        console.error('<<<ERRROR>>>', error);
        console.error('Something Went Wrong', error.message);
    } finally {
        workbook.removeWorksheet(workSheetName);
    }
}