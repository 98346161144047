import React from "react";
import styled, { useTheme } from "styled-components";

import Loader from "../Spiner";

const ButtonIcon = ({
  title = '',
  icon,
  onClick,
  disabled = false,
  colorBg = "",
  positionIcon = "right",
  width,
  isLoad = false,
  loaderProps,
  label = "",
  isActive = false,
  isMin = false,
}) => {
  const theme = useTheme();
  colorBg = colorBg.length ? colorBg : theme.accentColor;

  return (
    <Wrapper
      onClick={onClick}
      theme={theme}
      disabled={disabled}
      colorBg={colorBg}
      width={width}
      title={label}
      isActive={isActive}
      isMin={isMin}
    >
      {
        positionIcon.includes('left') && <Icon>{icon}</Icon>
      }
      {
        title && <Title>{title}</Title>
      }
      {
        icon && positionIcon.includes('right') && <Icon>{icon}</Icon>
      }
      {
        isLoad && <Loader {...loaderProps} />
      }
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.isMin ? "2px 10px" : "4px 15px"};
  min-height: ${(p) => p.isMin ? "30px" : "42px"};
  border-radius: 8px;
  color: ${(p) => p.theme.secondaryColor};
  background-color: ${(p) => p.isActive ? p.theme.proceedColor : p.colorBg};
  pointer-events: ${(p) => p.isActive ? "none" : "auto"};
  transition: background-color 200ms ease-in-out;
  width: ${(p) => p.width};
  position: relative;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.proceedColor};
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000036;
  }
`;

const Title = styled.span`
font-family: 'Barlow', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  margin: 0 5px;
`;

const Icon = styled.span`
  color: ${(p) => p.theme.secondaryColor};
  height: 18px;
  width: 18px;
  svg {
    height: inherit;
    width: inherit;
  }
  color: inherit;
`;

export default ButtonIcon;
