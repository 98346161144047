import React from 'react';
import styled, { useTheme } from "styled-components";

import CheckIcon from '@mui/icons-material/Check';

const CustomCheckbox = ({
    title = "",
    onChecked = () => { },
    value,
    checked = false,
    isStyle = false,
    isMin = false
}) => {
    const theme = useTheme();

    return (
        <Wr theme={theme} >
            <Label>
                <Input
                    type="checkbox"
                    onChange={() => onChecked(value)}
                    checked={checked}
                />
                <Item isStyle={isStyle} isMin={isMin}>
                    <span>
                        <CheckIcon />
                    </span>
                </Item>
                <PTtitle isStyle={isStyle}>{title}</PTtitle>
            </Label>
        </Wr>
    );
}

const Wr = styled.div`
    margin-top: 3px;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
`;

const PTtitle = styled.p`
    margin-left: 10px;
    color: ${(p) => p.isStyle ? "#000" : p.theme.secondaryColor};
    font-size: 14px;
`;

const Item = styled.span`
    min-width: ${(p) => p.isMin ? "16px" : "20px"};
    min-height: ${(p) => p.isMin ? "16px" : "20px"};
    max-width: ${(p) => p.isMin ? "16px" : "20px"};
    max-height: ${(p) => p.isMin ? "16px" : "20px"};
    position: relative;
    background-color:  ${(p) => p.isStyle ? "#fff" : p.theme.backgroundLight};
    border: ${(p) => p.theme.inputBorder};
    border-radius: 2px;
    span {
        position: absolute;
        opacity: 0;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        color: ${(p) => p.isStyle ? "#000" : "#fff"};
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            font-size: 20px;
        }
    }
`;

const Input = styled.input`
    display: none;
    &:checked {
        & + span {
            span {
                opacity: 1;
            }
        }
    }
`;

export default React.memo(CustomCheckbox);
