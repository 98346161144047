/*  camelcase */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isArray } from "lodash";
import { useHistory } from "react-router-dom";

import NavigationTabs from '../../components/ui-kit/NavigationTabs/NavigationTabs';
import Loader from '../../components/ui-kit/Spiner';
import Product from './ProductСard';
import Pagination from "components/ui-kit/Pagination";
import styles from './ProductsPage.module.scss';
import BtnDisplayType from 'components/ui-kit/BtnDisplayType';
import Table from "components/ui-kit/Table";
import ModalUpdateCategories from "components/modals/ModalUpdateCategories";
import ButtonIcon from 'components/ui-kit/ButtonIcon';

import { getData, saveData } from 'utils/helpers/localStorage';

import { tableProducts } from "constants/tables";

const defOnj = {
    modal: {
        isOpen: false,
        isLoad: false,
    }
};

function ProductsPage({
    isFetching,
    products,
    productCategories,
    restaurants,
    getProducts,
    getSettingsProductCategories,
    cutlery,
    updateCategorieOfid,
    productsMeta
}) {
    const [activeProductTabIndex, setActiveProductTabIndex] = useState(0);
    const [activeKitchenTabIndex, setActiveKitchenTabIndex] = useState(0);
    const [settingCat, setSettingCat] = useState(null);
    const [statModal, setStateModal] = useState({ ...defOnj.modal });
    const [isLoadBtn, setIsLoadBtn] = useState(false);
    const [requestParams, setRequestParams] = useState({
        restaurant: restaurants[0]?.key || 'smaki',
        per_page: 30,
        isPage: true
    });
    const [typeReflection, setTypeReflection] = useState("table");
    const history = useHistory();

    const renderTabs = (tabs, activeTabIndex, changeKey, disabledTabs) => {
        return (
            <div className={styles.tabs}>
                <NavigationTabs
                    tabs={tabs}
                    tabMode='light'
                    activeTabIndex={activeTabIndex}
                    disabledTabs={disabledTabs}
                    onTabClick={handleTabClick(changeKey)}
                />
            </div>
        );
    };

    const renderProducts = () => {
        return products.map(el => {
            const {
                image,
                title_ru,
                title_ua,
                weight,
                weight_type,
                description_ua,
                article,
                id
            } = el;

            return (
                <Product
                    key={article}
                    image={image}
                    titleRu={title_ru}
                    titleUa={title_ua}
                    weight={weight}
                    weightType={weight_type}
                    descriptionUa={description_ua}
                    id={id}
                />
            );
        });
    };

    const handleTabClick = (changeKey = null) => ({ tab = settingCat, index = activeProductTabIndex }) => {
        history.push(`?page=1`);
        const newParams = { ...requestParams, [changeKey]: tab.key, page: 1 };

        switch (changeKey) {
            case 'restaurant': {
                setActiveKitchenTabIndex(index);
                setSettingCat(null);
                setActiveProductTabIndex(0);
                break;
            }

            default: {
                setActiveProductTabIndex(index);
                if (index == 0)
                    setSettingCat(null);

                if (index != 0) {
                    setSettingCat({ ...tab.obj, cutlery_sets: tab.obj.cutlery_sets.map(item => item.id) });
                }

                break;
            }
        }

        if (newParams[changeKey] === 'all') {
            delete newParams[changeKey];
        }

        if (newParams[changeKey] !== requestParams[changeKey]) {
            setRequestParams(newParams);
            updateProducts(newParams);
        }
    };

    const updateProducts = (params) => {
        getProducts(params);
    };

    const onHandlerReflection = () => {
        setTypeReflection((prev) => {
            if (prev.includes("cards")) {
                saveData("typeReflection", { type: "table" });
                return "table";
            } else {
                saveData("typeReflection", { type: "cards" });
                return "cards";
            }
        });
    }

    const openModal = () => {
        setStateModal((prev) => ({
            ...prev,
            isOpen: true,
        }));
    }

    const closeModal = () => {
        setStateModal(defOnj.modal);
        setIsLoadBtn(false);
    }

    const updateFormCategories = (name, value) => {
        setSettingCat(prev => ({ ...prev, [name]: value }));
    }

    const handleCreateClick = async () => {
        updateCategorieOfid(setIsLoadBtn, closeModal, settingCat, setSettingCat);
    }

    const setPage = (page = 1) => {
        updateProducts({ ...requestParams, page });
        history.push(`?page=${page}`);
    }

    useEffect(() => {
        getProducts(requestParams)
    }, []);

    useEffect(() => {
        let type = getData("typeReflection")?.type;

        if (type)
            setTypeReflection(type);

        getSettingsProductCategories({ restaurant: requestParams.restaurant });
    }, [activeKitchenTabIndex]);

    return (
        <>
            <div className={styles.products}>
                <div className={styles.header_top}>
                    <div className={styles.header}>
                        {renderTabs(productCategories, activeProductTabIndex, 'category_sync_id')}
                        {renderTabs(restaurants, activeKitchenTabIndex, 'restaurant')}
                    </div>
                    <div className={styles.row_jus}>
                        <div className={styles.flex_cent}>
                            {
                                (activeProductTabIndex > 0) && (
                                    <>
                                        <div>{(settingCat.take_part_promotions_discounts == 1) ? "Без ігнорування акцій" : "Ігнорує акції"} </div>
                                        <ButtonIcon disabled={isFetching} title='Редагувати' onClick={() => openModal()} />
                                    </>
                                )
                            }
                        </div>
                        <BtnDisplayType
                            isType={typeReflection}
                            onHandler={onHandlerReflection}
                        />
                    </div>
                </div>

                {
                    isFetching ? (
                        <div className={styles.loader}>
                            <Loader color='orange' width={40} height={40} />
                        </div>
                    ) : (
                        <div className={styles.w_100}>
                            {
                                typeReflection.includes('table') ? (
                                    <Table
                                        data={products}
                                        scheme={tableProducts}
                                        isLink={true}
                                        link={`/products/`}
                                    />
                                ) : (
                                    <div className={styles.body}>
                                        {
                                            renderProducts()
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {
                    isArray(products) && (
                        products.length > 0 && (
                            <Pagination
                                onClick={setPage}
                                currentPage={productsMeta.current_page}
                                lastPage={productsMeta.last_page}
                            />
                        )
                    )
                }
            </div>

            <ModalUpdateCategories
                isOpen={statModal.isOpen}
                isLoad={isLoadBtn}
                isLoadBtn={isLoadBtn}
                data={settingCat}
                cutlery={cutlery}
                onCloseModal={closeModal}
                updateCategoriesForm={updateFormCategories}
                handleCreateClick={handleCreateClick}
            />
        </>
    );
}

ProductsPage.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    products: PropTypes.array.isRequired,
    getProducts: PropTypes.func.isRequired,
    productCategories: PropTypes.array.isRequired
};

export default React.memo(ProductsPage);
