import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import { RADIO_SCHEME } from "constants/schemes";

const ModalСreateComplaint = ({
    // props
    isOpen = false,
    isLoad = false,
    isLoadBtn = false,
    isNew = fase,
    data = {},
    onCloseModal = () => { },
    handleCreateClick = () => { },
    updateComplainForm = () => { },
}) => {
    const theme = useTheme();

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={onCloseModal}
        >
            {
                !isLoad && (
                    <>
                        <Title>{isNew ? "Нове" : "Редагування"}</Title>

                        <TextInput
                            title="Назва"
                            value={data.name}
                            isStyle={true}
                            onChange={(e) => updateComplainForm("name", e.target.value)}
                        />

                        <Radio
                            colors={"#000"}
                            items={RADIO_SCHEME}
                            valueKey="status"
                            value={data.status}
                            onChange={(e) => updateComplainForm("status", +e.value.value)}
                        />
                        <br />
                        <Wrcenter>
                            <ButtonIcon
                                title={isNew ? "Створити" : "Оновити"}
                                onClick={handleCreateClick}
                                isLoad={isLoadBtn}
                            />
                        </Wrcenter>
                    </>
                )
            }

            {
                isLoad && (
                    <LoadingCircl mins={true} />
                )
            }
        </ModalLayout>
    )
}

const Title = styled.div`
    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const Wrcenter = styled.div`
    display: flex;
    justify-content: center;
`;

export default ModalСreateComplaint;