import { isArray } from "lodash";

export const removeUnnecessaryTableware = (cutlery = []) => {
    let newArray = [];

    if (!isArray(cutlery))
        return null;

    if (cutlery.length == 0)
        return null;

    for (let i = 0; i < cutlery.length; i++) {
        let itemCutlery = {...cutlery[i]};

        if ((itemCutlery.status == 0) || (itemCutlery.quantity == 0))
            continue;

        delete itemCutlery.status;
        newArray.push(itemCutlery);
    }

    return newArray;
}