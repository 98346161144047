import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as complaintPage } from './Categories';

function mapStateToProps(state) {
    const {
        settings: {
            productCategories,
            productCategoriesForm,
            cutlery,
        }
    } = state;

    return {
        productCategories,
        productCategoriesForm,
        cutlery,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(complaintPage);
