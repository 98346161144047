import { isArray } from "lodash";

export const rolesMapped = (arr = []) => {
    let mewArray = [];

    if (isArray(arr))
        arr?.map((item) => {
            mewArray.push({ title: item.title, value: item.name });
        });

    return mewArray;
};

export const rolesMappedSelect = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item.name, label: item.title });
    });

    return mewArray;
};

export const rolesMappedSelectId = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item.id, label: item.title });
    });

    return mewArray;
};

export const rolesArrayId = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push(item.value);
    });

    return mewArray;
}

export const rolesSearchId = (arr = [], rolles = []) => {
    let mewArray = [];

    arr?.map((item) => {
        rolles.map((el) => {
            if (el.id === item) {
                mewArray.push({ value: el.id, label: el.title });
            }
        });
    });

    return mewArray;
}

export const rolesSearchIdName = (arr = [], rolles = []) => {
    let mewArray = [];

    arr?.map((item) => {
        rolles.map((el) => {
            if (el.id === item) {
                mewArray.push({ value: el.name, label: el.title });
            }
        });
    });

    return mewArray;
}

export const roleSearchOnIdName = (id, rolles = []) => {
    let name = '';

    rolles.map((el) => {
        if (el.id === id) {
            name = el.name;
        }
    });

    return name;
}

export const rolesSearchName = (value = '', rolles = []) => {
    let mewArray = [];

    rolles.map((el) => {
        if (el.name === value) {
            mewArray.push({ value: el.name, label: el.title });
        }
    });

    return mewArray;
}