import { uk } from "date-fns/locale";
import { useState, useEffect } from "react";
import { DateRange } from "react-date-range";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";

import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import styles from "../scss/settings.module.scss";

import {
    RADIO_SCHEME,
    RADIO_SCHEME_PERIOD_NO,
    TYPE_CREATE_PROMOCODES
} from "constants/schemes";
import { TextArea } from "components/ui-kit/inputs/TextArea";

import { categoriesMappedSelect, searchCategoriesId } from "utils/mappers/categories";
import { productMappedSelect, productMappedDropdown, searchProductId } from "utils/mappers/products";

import { validatePromocode } from "utils/validation";
import { rangesDate } from "utils/helpers/date";
import { isArray } from "lodash";

const typePromocode = [
    {
        title: '-',
        value: '',
    },
    {
        title: 'сумма скидки ( -100 от суммы корзины)',
        value: 'discount_amount',
    },
    {
        title: '% скидки ( -10% от суммы корзины) ',
        value: 'percent_amount',
    },
    {
        title: 'блюдо в подарок ( пицца А в подарок) ',
        value: 'free_product',
    }
];

export const EditPromocode = ({
    products,
    categories,
    promocodesEdit,
    handleSubmitClose,
    updateFormPromocode,
    handleSubmitСreate,
    handleSubmitUpdate,
    updateFormPromocodeArray,
    deleteOneFieldPromocode,
    addOneFieldPromocode,
    updateFormPromocodeItemCode,
    addOneFieldCodePromocode,
    deleteOneFieldCodePromocode,
    getProducts
}) => {
    const [error, setErrors] = useState({
        title: "",
        min_order_amount: "",
    });
    const [typeCreate, setTypeCreate] = useState(TYPE_CREATE_PROMOCODES[0].value);

    let titleButton = promocodesEdit?.id ? 'Змінити' : 'Додати';
    let title = promocodesEdit?.id ? `Редагування ${promocodesEdit.title}` : "Строврення нового промокода";
    let titleDiscount = '';

    (typePromocode[1].value === promocodesEdit.type) && (titleDiscount = "сумма скидки");
    (typePromocode[2].value === promocodesEdit.type) && (titleDiscount = "% скидки");

    const onSubmit = () => {
        validatePromocode({
            data: {
                ...promocodesEdit
            },

            onSuccess: async () => {
                if (promocodesEdit?.id) {
                    handleSubmitUpdate(promocodesEdit?.id);
                } else {
                    handleSubmitСreate();
                }

                setErrors({
                    title: "",
                    min_order_amount: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    const onHandlerType = (value) => {
        setTypeCreate(value);

        if (TYPE_CREATE_PROMOCODES[0].value === value) {
            deleteOneFieldPromocode("codes");
            addOneFieldPromocode("generate_codes", "5");
        } else if (TYPE_CREATE_PROMOCODES[1].value === value) {
            deleteOneFieldPromocode("generate_codes");
            addOneFieldPromocode("codes", [{ code: '', status: 1 }]);
        }
    }

    useEffect(() => {
        getProducts(false);
    }, []);

    return (
        <div>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>{title}</div>
            <div className={`${styles.row_form}`}>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <TextInput
                        title="Назва"
                        type="text"
                        value={promocodesEdit.title}
                        isStyle={true}
                        error={error.title}
                        onChange={(e) => updateFormPromocode("title", e.target.value)}
                    />
                    <div className={styles.row_item}>
                        <TextInput
                            title="Вказати мінімальну суму кошика для застосування промокоду"
                            type="number"
                            value={promocodesEdit.min_order_amount}
                            isStyle={true}
                            error={error.min_order_amount}
                            onChange={(e) => updateFormPromocode("min_order_amount", e.target.value)}
                        />
                    </div>
                    <div className={styles.row_item}>
                        <Dropdown
                            title='На що діє'
                            isBlack={true}
                            width="100%"
                            onChange={(e) => updateFormPromocode("type", e.target.value)}
                            value={promocodesEdit.type}
                            list={typePromocode}
                            isLight={true}
                            error={error.type}
                            fontSize={'14px'}
                        />
                        {
                            ([typePromocode[1].value, typePromocode[2].value].indexOf(promocodesEdit.type) != -1) && (
                                <div className={styles.row_item}>
                                    <TextInput
                                        title={titleDiscount}
                                        type="number"
                                        value={promocodesEdit.discount}
                                        isStyle={true}
                                        onChange={(e) => updateFormPromocode("discount", e.target.value)}
                                    />
                                </div>
                            )
                        }
                        {
                            (promocodesEdit.type === "free_product") && (
                                <div className={styles.row_item}>
                                    <Dropdown
                                        title='Страва в подарок'
                                        isBlack={true}
                                        width="100%"
                                        onChange={(e) => updateFormPromocode("free_product", e.target.value)}
                                        value={promocodesEdit.free_product}
                                        list={productMappedDropdown([...[{ title_ua: '-', id: '' }], ...products])}
                                        isLight={true}
                                        error={error.free_product}
                                        fontSize={'14px'}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.row_item}>
                        <Select
                            selectOptions={categoriesMappedSelect(categories)}
                            selectedOption={searchCategoriesId(promocodesEdit.exclude_categories, categories)}
                            valueKey='сategorys'
                            label='Виняток категорії'
                            isMulti
                            mode={'primary'}
                            onChange={({ value }) => updateFormPromocodeArray("exclude_categories", value)}
                        />
                        <Select
                            selectOptions={productMappedSelect(products)}
                            selectedOption={searchProductId(promocodesEdit.exclude_products, products)}
                            valueKey='exclude_products'
                            label='Виняток товари'
                            isMulti
                            mode={'primary'}
                            onChange={({ value }) => updateFormPromocodeArray("exclude_products", value)}
                        />
                    </div>
                    <div className={styles.row_item}>
                        {
                            (promocodesEdit.id === null) && (
                                <Radio
                                    colors={"#000"}
                                    items={TYPE_CREATE_PROMOCODES}
                                    valueKey="typePromocode"
                                    label="Як створити промокоди"
                                    value={typeCreate}
                                    onChange={(e) => onHandlerType(e.value.value)}
                                />
                            )
                        }

                        {
                            (TYPE_CREATE_PROMOCODES[0].value === typeCreate) && (promocodesEdit.id === null) && (
                                <TextInput
                                    title="Кількість промокодів"
                                    type="number"
                                    value={promocodesEdit?.generate_codes || ''}
                                    isStyle={true}
                                    onChange={(e) => updateFormPromocode("generate_codes", e.target.value)}
                                />
                            )
                        }

                        {
                            ((TYPE_CREATE_PROMOCODES[1].value === typeCreate) || (promocodesEdit.id !== null)) && (
                                <>
                                    <div className={styles.title_rows}>Список промокодів</div>
                                    {
                                        isArray(promocodesEdit?.codes) && promocodesEdit.codes.map((item, index) => (
                                            <div className={styles.row_numb_field} key={index}>
                                                <span className={styles.number_promo}>{index + 1}.</span>
                                                <TextInput
                                                    isTitle={false}
                                                    type="text"
                                                    value={item.code}
                                                    isStyle={true}
                                                    disabled={(promocodesEdit.id !== null) && !!item?.id}
                                                    width="150px"
                                                    onChange={(e) => updateFormPromocodeItemCode(index, e.target.value)}
                                                />
                                                {
                                                    (promocodesEdit.codes.length > 1) && (
                                                        <span className={styles.row_numb_btn}>
                                                            <ButtonIcon
                                                                onClick={() => deleteOneFieldCodePromocode(index)}
                                                                colorBg="red"
                                                                icon={<DeleteForeverIcon />}
                                                            />
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                    <div className={styles.row_btn}>
                                        <div className={styles.item_btn}>
                                            <ButtonIcon
                                                onClick={addOneFieldCodePromocode}
                                                title="Додати код"
                                                colorBg="green"
                                                icon={<AddIcon />}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <div className={styles.wr_drop}>
                        <Radio
                            colors={"#000"}
                            items={RADIO_SCHEME_PERIOD_NO}
                            valueKey="perpetual"
                            value={promocodesEdit.perpetual}
                            onChange={(e) => updateFormPromocode("perpetual", +e.value.value)}
                        />
                    </div>
                    {
                        (promocodesEdit.perpetual == "0") && (
                            <div className={styles.wr_range}>
                                <div className={styles.title_input}>Період з - по</div>
                                <DateRange
                                    onChange={(date) => updateFormPromocode("period", [date.selection])}
                                    ranges={rangesDate(promocodesEdit.period)}
                                    locale={uk}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={styles.row_item}>
                <TextArea
                    isStyle={true}
                    title='Опис промокода'
                    value={promocodesEdit.description}
                    onChange={(e) => updateFormPromocode("description", e.target.value)}
                />
            </div>
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME}
                    label="Активність промокода"
                    valueKey="status"
                    value={promocodesEdit.status}
                    onChange={(e) => updateFormPromocode("status", +e.value.value)}
                />
            </div>
            <div className={styles.row_btn_tow}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={onSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmitClose}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>
        </div>
    )
}