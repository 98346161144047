import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";

import ModalEditor from "components/modals/ModalEditor";
import OrdersListObj from "./OrdersListObj";
import MenuPageMylti from "components/menuPageMulti";
import { HeadOrder } from "components/orders/HeadOrder";
import { dataItemsMenu } from 'constants/menuPageKitchens';
import { PrintCheck } from "components/PrintCheck";
import { Voice } from "components/voice";
import CalendarRange from "components/ui-kit/CalendarRange";
import { ModalLoad } from "components/modals/ModalLoad";
import ModalKitchenCounter from "components/modals/ModalKitchenCounter";

import { activeInForceKitchenTabs } from "utils/helpers/activeInForce";
import { filterKitchenCodeOnId, isPicupKitchen } from "utils/helpers/filterApiParams";
import { kitchensName } from "utils/helpers/kitchens";
import { rangeConverDate } from "utils/helpers/date";
import { isArray } from "lodash";
import { isCloseKitchen } from "utils/helpers/adminKitchens";

const KitchenPage = ({
  // state
  orders,
  print,
  dataPrintAll,
  user,
  kitchens,
  isOpenModal,
  filter,
  profile,
  // redux
  getAllActiveOrders,
  addNotification,
  loadPage,
  getOrderPrint,
  updateFilter,
  openEditorCallcenter,
  updateOrder,
  updateOrderData,
  getDataPrintOrderAllKitchen,
}) => {
  const controlPlayer = useRef(undefined);
  const componentRef = useRef(undefined);
  let location = useLocation();
  let pathname = location.pathname;
  let keysMenu = Object.keys(dataItemsMenu);

  const [activeTabs, setActiveTabs] = useState([keysMenu[0]]);
  const [isEdit, setIsEdit] = useState(true);
  const [isBtnAdd, setBtnAdd] = useState(false);
  const [statePrint, setStatePrint] = useState(undefined);
  const [typePrint, setTypePrint] = useState("check");
  const [showModalCounter, setModalCounter] = useState(false);

  const handlePrint = () => {
    setTypePrint("check");
    handlePrintChe();
  };

  const handlePrintChe = useReactToPrint({
    content: () => componentRef.current,
  });

  const handBtnDate = () => {
    getAllActiveOrders({ ...filterKitchenCodeOnId(user.user.kitchens, kitchens), ...rangeConverDate(filter.date) }, false);
  }

  const openEditModal = () => {
    setIsEdit(true);
  }

  const renderOrders = () => {
    return (
      <>
        <RowsH>
          <KithenTitle>Кухня: <span>{kitchensName(user.user.kitchens, kitchens)}</span></KithenTitle>
          <ColH>
            <CalendarRange
              isAbs={true}
              isStyle={true}
              valueIn={filter.date}
              ranges={filter.date}
              onChangeCal={(date) => updateFilter("date", [date.selection])}
              isBtn={true}
              handlerSubmit={handBtnDate}
            />
          </ColH>
        </RowsH>

        <View>
          <HeadOrder />
        </View>

        {
          activeInForceKitchenTabs(keysMenu[0], activeTabs) && (
            <OrdersListObj
              key="cooking"
              data={orders.cooking || {}}
              title='Готуються'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[1], activeTabs) && (
            <OrdersListObj
              key="preparing"
              data={orders.preparing || {}}
              title='Пакуються'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[2], activeTabs) && (
            <OrdersListObj
              key="for_delivery"
              data={orders.for_delivery || {}}
              title='Очікують доставки'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[3], activeTabs) && (
            <OrdersListObj
              key="on_way"
              data={orders.on_way || {}}
              title='Доставляються'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[4], activeTabs) && (
            <OrdersListObj
              key="pending"
              data={orders.pending || {}}
              title='Попереднi замовлення'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[5], activeTabs) && (
            <OrdersListObj
              key="rejected"
              data={orders.rejected || {}}
              title='Відмінені'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[6], activeTabs) && (
            <OrdersListObj
              key="delivered"
              data={orders.delivered || {}}
              title='Доставлені'
              openEditModal={openEditModal}
            />
          )
        }
        {
          activeInForceKitchenTabs(keysMenu[7], activeTabs) && (
            <OrdersListObj
              key="closed"
              data={orders.closed || {}}
              title='Закриті'
              openEditModal={openEditModal}
            />
          )
        }
      </>
    );
  };

  const handleCreateOrder = () => {
    setIsEdit(false);
    openEditorCallcenter();
    updateOrder();
    updateOrderData("pickup", "1");
  }

  const handlePrintAll = async () => {
    await getDataPrintOrderAllKitchen();
    await setTypePrint("kitchen");
    await handlePrintAllCheck();
  }

  const handlePrintAllCheck = useReactToPrint({
    content: () => componentRef.current,
  });

  // розкоментувати після розробки модалки
  useEffect(() => {
    if (!isOpenModal) {
      let status = null;

      if (activeInForceKitchenTabs(dataItemsMenu['closed'].path, pathname)) {
        status = 'all';
      } else if (activeInForceKitchenTabs(dataItemsMenu['rejected'].path, pathname)) {
        status = 'all';
      }

      getAllActiveOrders({ ...filterKitchenCodeOnId(user.user.kitchens, kitchens), ...rangeConverDate(filter.date) }, false, status);

      const interval = setInterval(() => {
        getAllActiveOrders({ ...filterKitchenCodeOnId(user.user.kitchens, kitchens), ...rangeConverDate(filter.date) }, false, status);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [pathname, filter.date]);

  useEffect(async () => {
    let needCheck = orders?.cooking?.need_check;

    if (isArray(needCheck) && (needCheck?.length > 0)) {
     await controlPlayer?.current && controlPlayer.current.play();
     await addNotification({ war: ['Нове замовлення'] });

      await getOrderPrint(isArray(needCheck) ? needCheck[needCheck.length - 1] : null);
      await handlePrint();
    }
  }, [orders]);

  useEffect(() => {
    // setBtnAdd
    let arrKitchenProfile = filterKitchenCodeOnId(user.user.kitchens, kitchens, true);

    if (isArray(arrKitchenProfile)) {
      setBtnAdd(isPicupKitchen(arrKitchenProfile));
    }

    isCloseKitchen(user.user.kitchen_code, kitchens, setStatePrint);
  }, []);

  useEffect(() => {
    if (statePrint?.isAutoPrint) {
      handlePrintAll();
    }
  }, [statePrint])

  return (
    <>
      <Wrapper>
        <MenuPageMylti
          items={dataItemsMenu}
          activeTabs={activeTabs}
          setActiveTabs={setActiveTabs}
          isBtnAdd={isBtnAdd}
          handleCreateOrder={handleCreateOrder}
          isBtnPrint={true}
          isBtnKitchenCounter={profile.role_name == 'administrator'}
          handlePrintAll={handlePrintAll}
          handleBtnKitchenCounter={() => setModalCounter(true)}
        />
        {orders && (
          <WrOrders>
            {renderOrders()}
          </WrOrders>
        )}
        <ModalEditor
          isAdmin={isEdit}
          handlePrint={handlePrint}
          isKitchen={true}
          iskeyEdit={isEdit}
          isEditDeliver={false}
          isMap={false}
          isDeliveryOnlyPicup={true}
        />

        <PrintCheck data={(typePrint == "check") ? print : dataPrintAll} typeCheck={typePrint} ref={componentRef} />

        <Voice controlPlayer={controlPlayer} />
        {
          loadPage && (
            <ModalLoad />
          )
        }
        {
          showModalCounter && (
            <ModalKitchenCounter
              title={'Буде заголовок'}
              isOpen={showModalCounter}
              onCloseModal={() => setModalCounter(false)}
            // isLoad={isLoadModalSaveEditAddress}
            />
          )
        }
      </Wrapper>
    </>
  );
};

const ColH = styled.div`
  margin-left: auto;
  padding-left: 20px;
`;

const RowsH = styled.div`
  display: flex;
  align-items: center;
`;

const KithenTitle = styled.div`
  font-size: 16px;
  span {
    font-weight: 700;
  }
`;

const Wrapper = styled.div`
  padding: 5px;
  padding-bottom: 80px;
`;

const View = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`;

const WrOrders = styled.div`
  margin-top: 14px;
`;

export default React.memo(KitchenPage);
