export const activeInForceKitchen = (pathMenu = '', path = '', pageStart = false) => {
    if (pageStart) {
        if (path === '/kitchen') {
            return true;
        }
    } else {
        return path.includes(pathMenu);
    }
}

export const activeInForceKitchenTabs = (keyItem = '', arrkeys = []) => {
    return arrkeys.indexOf(keyItem) != -1;
}