import { isArray } from "lodash";

export const citiesMapped = (arr = [], city_sync_id = null) => {
  let mewArray = [];

  arr?.map((item, index) => {
    if (city_sync_id) {
      if (index > 0) {
        if (item?.status && item.sync_id == city_sync_id) {
          mewArray.push({ title: item.name_ua, value: item.sync_id });
        }
      } else {
        if (item?.status) {
          mewArray.push({ title: item.name_ua, value: item.sync_id });
        }
      }
    } else {
      if (item?.status) {
        mewArray.push({ title: item.name_ua, value: item.sync_id });
      }
    }
  });

  return mewArray;
};

export const citiesMappedId = (arr = []) => {
  let mewArray = [];

  arr?.map((item) => {
    if (item?.status) {
      mewArray.push({ title: item.name_ua, value: item.id });
    }
  });

  return mewArray;
};

export const citySearch = (id, cities) => {
  if (isArray(cities)) {
    return cities.find((item) => item.sync_id === id);
  } else {
    return [];
  }
}

export const citySearchNameUa = (name, cities) => {
  if (isArray(cities)) {
    return cities.find((item) => item.name_ua === name);
  } else {
    return [];
  }
}

export const suburdSearchNameUa = (name, cities) => {
  if (isArray(cities)) {
    return cities.find((item) => item.name_ua === name);
  } else {
    return [];
  }
}