import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import { isArray } from "lodash";
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import Checkbox from "components/ui-kit/Checkbox";

import SaveIcon from "@mui/icons-material/Save";
import { DetalCardCook } from "components/DetalCardCook";
import { orderSearchById } from "utils/mappers/orders";

const ModalListCooks = ({
    // props
    idOrder = undefined,
    // state
    isOpen,
    isLoad,
    cooksInTheKitchen,
    cookings,
    // reducer
    updateModalCooks,
    updateFormCooks,
    formCooks,
    handleSubmit,
}) => {
    const theme = useTheme();
    const currentOrder = orderSearchById(cookings, idOrder);

    const handlerCloseModal = async () => {
        await updateModalCooks("isOpen", false);
    }

    const handlerItem = (item) => {
        updateFormCooks(item.id)
    }

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={handlerCloseModal}
        >
            <Title>№ {idOrder}, Готує?</Title>

            {
                (currentOrder !== undefined) && (
                    <DetalCardCook data={currentOrder} />
                )
            }

            <Mt>
                {
                    (isArray(cooksInTheKitchen) && !isLoad) && (
                        <>
                            {
                                (cooksInTheKitchen.length > 0) && (
                                    <UlList>
                                        {
                                            cooksInTheKitchen.map((item) => {
                                                let active = formCooks.indexOf(item.id) != -1;

                                                return (
                                                    <ItemLi key={item.id} active={active} onClick={() => handlerItem(item)}>
                                                        <Check>
                                                            <Checkbox
                                                                key={item.id}
                                                                isStyle={true}
                                                                value={item.id}
                                                                checked={active}
                                                                onChecked={() => { handlerItem(item) }}
                                                            />
                                                        </Check>
                                                        <Name>{item?.first_name} {item?.last_name}</Name>
                                                    </ItemLi>
                                                )
                                            })
                                        }
                                    </UlList>
                                )
                            }

                            <WrVtn>
                                {
                                    (formCooks.length > 0) && (
                                        <ButtonIcon
                                            onClick={handleSubmit}
                                            title='Підтвердити'
                                            icon={<SaveIcon />}
                                        />
                                    )
                                }
                            </WrVtn>
                        </>
                    )
                }

                {
                    isLoad && (
                        <LoadingCircl mins={true} />
                    )
                }
            </Mt>
        </ModalLayout>
    )
}

const Mt = styled.div`
    margin-top: 20px;
`;

const Check = styled.div`
    width: 25px;
`;

const Title = styled.div`
    text-align: center;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const UlList = styled.ul`
    max-height: 300px;
    overflow: auto;
`;

const ItemLi = styled.li`
    padding: 10px 0;
    font-size: 16px;
    display: flex;
    border-bottom: ${(p) => p.theme.inputBorder};
    cursor: pointer;
    margin-right: 10px;
    transition: 0.4s;

    color: ${(p) => p.active && p.theme.accentColor};

    &:hover {
        color: ${(p) => p.theme.accentColor};
    }
`;

const Name = styled.span`
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 25px);
`;

const WrVtn = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export default ModalListCooks;