import { connect } from "react-redux";

import * as statisticActions from "store/actions/statistic";
import { default as complaintsPage } from "./Complaints";

function mapStateToProps(state) {
    const {
        statistic: {
            complaints: {
                filter,
                list,
            }
        },
        settings: {
            complaints: {
                complaint,
                resource,
            }
        },
        view,
    } = state;

    return {
        list,
        filter,
        view,
        complaint,
        resource,
    };
}

const mapDispatchToProps = {
    ...statisticActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(complaintsPage);
