export const n_plus_one = 'n_plus_one';
export const pickup_for_employees = 'pickup_for_employees';

export const typePromotions = [
    {
        title: '-',
        value: '',
    },
    {
        title: 'N+1',
        value: n_plus_one,
    },
    // {
    //     title: 'Cамовивіз',
    //     value: pickup_for_employees,
    // },
];