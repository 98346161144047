import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import "leaflet/dist/leaflet.css";

import MapIcon from "@mui/icons-material/Map";
import iconHome from "assets/icons/marker-home.svg";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import { updateOrderAddress } from "store/actions/order";

import { MapMarker } from "./MapMarker";
import { MapPolygon } from "./MapPolygon";
import { LoadingModal } from "../../../../loadingOrder/loading";

import {
  getGeodataAddress,
  getGeodataCoordinates,
} from "utils/helpers/geocoding";

import { citySearch, citySearchNameUa, suburdSearchNameUa } from "utils/mappers/cities";
import { searchActiveKitchenCityPolygons } from "utils/mappers/kitchens";

// определить кординаты по адрессу
export const searchForCoorinates = async (address, dispatch) => {
  const { city_sync_id, street, house_number, settlement_id } = address;

  if (city_sync_id.length) {
    const data = await getGeodataCoordinates(
      city_sync_id,
      settlement_id,
      street,
      house_number
    );

    if (data) {
      await dispatch(updateOrderAddress("latitude", data?.lat));
      await dispatch(updateOrderAddress("longitude", data?.lng));
    }
  }
};

// получения и передача кординат карте
const parseCoordinates = (latitude, longitude, cities) => {
  let cityDefault = null;

  if (!!latitude && !!longitude)
    return {
      lat: +parseFloat(latitude),
      lng: +parseFloat(longitude),
    };

  cityDefault = citySearch('lviv', cities);

  if (!!cityDefault.latitude && !!cityDefault.longitude) {

    return {
      lat: +cityDefault.latitude,
      lng: +cityDefault.longitude,
    };
  }

  return {
    lat: 49.84287298912712,
    lng: 24.027133941126525,
  };
};

const defaultStyle = {
  height: "100%",
  width: "100%",
  borderRadius: 10
};

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  clicableIcons: false,
  KeyboardShortcuts: false,
  scrollwheel: true,
  disablDoubleClickZoom: true,
  fullscreenControll: false,
}

const TabMap = ({ isEdit }) => {
  const dispatch = useDispatch();
  const mapRef = useRef(undefined);

  const {
    order: {
      data: {
        address,
        address: { latitude, longitude },
      },
    },
    settings: { cities },
    kitchens: { kitchensListCity },
    loadings: { loadModalEdition }
  } = useSelector((store) => store);

  const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY || "AIzaSyAlOTGOPRI3wZKLhHL_g699NFwQmS662Lk";

  // загрузка карты
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    language: 'uk'
  });

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    mapRef.current = undefined;
  }, []);

  // получить все пригороды по городу
  const settlementList = () => {
    let list = citySearch(address?.city_sync_id, cities)?.settlements;

    return list;
  };

  //это отслеживает смену адреса
  useEffect(async () => {
    if (!!!address.street) {
      let currenCity = citySearch(address.city_sync_id, cities);

      if (!!address.settlement_id) {
        let currentSettlements = citySearch(
          address.settlement_id,
          currenCity?.settlements
        );

        dispatch(updateOrderAddress("latitude", +currentSettlements?.latitude));
        dispatch(updateOrderAddress("longitude", +currentSettlements?.longitude));
      } else {
        dispatch(updateOrderAddress("latitude", +currenCity?.latitude));
        dispatch(updateOrderAddress("longitude", +currenCity?.longitude));
      }
    }
  }, [address.city_sync_id, address.settlement_id]);

  const handleChangeCoordinates = async (lat, lng) => {
    const data = await getGeodataAddress(lat, lng);

    if (data) {
      let city = citySearchNameUa(data?.city, cities);

      if (city) {
        dispatch(updateOrderAddress("city_sync_id", city.sync_id));
      } else {
        city = suburdSearchNameUa(data?.city, settlementList());

        if (city) {
          dispatch(updateOrderAddress("settlement_id", city.sync_id));
        }
      }
      dispatch(updateOrderAddress("street", data.street));
      dispatch(updateOrderAddress("house_number", data.house_number));
    } else {
      dispatch(updateOrderAddress("street", ''));
      dispatch(updateOrderAddress("house_number", ''));
    }

    dispatch(updateOrderAddress("latitude", lat));
    dispatch(updateOrderAddress("longitude", lng));
  };

  const handleArea = (e) => {
    console.log(e);
  };

  return (
    <Wrapper>

      <ButtonIcon
        onClick={() => searchForCoorinates(address, dispatch)}
        title="Знайти координати"
        icon={<MapIcon />}
      />

      <MapDiv>
        {
          isLoaded ? (
            <GoogleMap
              mapContainerStyle={defaultStyle}
              zoomControlOptions={
                {
                  position: google.maps.ControlPosition.RIGHT_CENTER,
                }
              }
              center={parseCoordinates(latitude, longitude, cities)}
              zoom={13}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={defaultOptions}
              onClick={(e) => handleChangeCoordinates(e.latLng.lat(), e.latLng.lng())}
            >
              <MapMarker
                key={'markerOne'}
                onChange={handleChangeCoordinates}
                position={parseCoordinates(latitude, longitude, cities)}
              />
              {
                searchActiveKitchenCityPolygons(address.city_sync_id, kitchensListCity)?.map((itemKitchen, indexKitchen) => (
                  <div key={`wr_${indexKitchen}`}>
                    <MapMarker
                      key={`marger_${indexKitchen}`}
                      onChange={handleChangeCoordinates}
                      position={{
                        lat: +itemKitchen.latitude,
                        lng: +itemKitchen.longitude,
                      }}
                      icone={iconHome}
                      clickable={false}
                      label={itemKitchen.title}
                    />

                    {
                      itemKitchen?.polygons?.map((itemPolygons, index) => (
                        <MapPolygon
                          key={`mapPolygon_${index}`}
                          pathColor={itemPolygons?.paths_color}
                          positions={itemPolygons?.paths}
                          title={itemKitchen?.title}
                          time={itemPolygons?.delivery_time}
                          startTime={itemPolygons?.start_time}
                          finishTime={itemPolygons?.finish_time}
                          marker={parseCoordinates(latitude, longitude, cities)}
                          handleArea={handleArea}
                        />
                      ))
                    }
                  </div>
                ))
              }

            </GoogleMap>
          ) : (
            <LoadMap>
              Завантаження
            </LoadMap>
          )
        }
      </MapDiv>

      {
        loadModalEdition.create && (
          <LoadingModal />
        )
      }
    </Wrapper>
  );
};

const MapDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex: 1 1 auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  transform-origin: right top;
  height: 100%;
  top: 0;
  right: 460px;
  width: 460px;
  padding: 10px;
  background-color: ${(p) => p.theme.background};
  z-index: 10;
`;

const LoadMap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default React.memo(TabMap);
