
import { isArray, isObject } from 'lodash';

import {
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION,
    REMOVE_NOTIFICATION_SHIFT
} from '../constants/notifications';

export const addNotification = (data, type = 'done') => {
    return async (dispatch) => {
        if (!isObject(data))
            return;

        let text = '';

        for (let key in data) {
            if (isArray(data[key]))
                text += `${data[key][0]}\n`;
        }

        await dispatch({
            type: ADD_NOTIFICATION,
            payload: {
                type,
                text
            }
        });
    }
}

export const removeNotification = (index) => {
    return ({
        type: REMOVE_NOTIFICATION,
        payload: {
            index
        }
    });
}

export const removeNotificationShift = () => {
    return ({
        type: REMOVE_NOTIFICATION_SHIFT,
    });
}