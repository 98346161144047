export const validDateGet = (work_start, work_end) => {
    let dataValid = [];
    
        dataValid.push({
            startDate: new Date(work_start),
            endDate: new Date(work_end),
            key: "selection"
        });

    return dataValid;
}