import { isArray, isObject } from "lodash";

export const reportsListDropdown = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ title: item.title, value: item.id });
    });

    return mewArray;
};

export const converFormatReports = (obj) => {
    if (isObject(obj)) {
        return obj.id;
    }

    return undefined;
}

export const searchAllDataOfId = (arr, id) => {
    if (!isArray(arr))
        return null;

    for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == +id) {
            return arr[i];
        }
    }

    return null;
}

export const widthTableAll = () => {
    let domHeadTable = document.querySelector(`.report-head`);
    let domMainTable = document.querySelector('.report-table');

    domMainTable.style.width = `${domHeadTable.scrollWidth}px`;
}

export const reportFilterRestKit = (isAll = false, arrFilter = [], arrAll = []) => {
    let arrt = [];

    if (isAll) {
        if (!isArray(arrAll))
            return;

        for (let i = 0; i < arrAll.length; i++) {
            if (!!arrAll[i].status) {
                arrt.push(arrAll[i].code);
            }
        }
    } else {
        arrt = arrFilter;
    }

    return arrt;
}

export const itemListHeight = () => {
    let rowe = document.querySelectorAll('.report-row-table');

    if (rowe.length == 0) return;

    rowe.forEach((elRow) => {
        let colTable = elRow.querySelectorAll('.item-col-table.item-col-table_list');
        if (colTable.length == 0) return;

        colTable.forEach(function (elem) {
            let dLit = elem.querySelectorAll('.item-col-list');
            if (!dLit) return;

            dLit.forEach(function (itemColList) {
                let idItem = itemColList.getAttribute('data-item');
                let allItem = elRow.querySelectorAll(`.item-col-list[data-item="${idItem}"]`);

                let maxHeight = 26;

                if (allItem.length > 0)
                    allItem.forEach(itemCol => {
                        if (itemCol.offsetHeight > maxHeight) {
                            maxHeight = itemCol.offsetHeight;

                            allItem.forEach(itemColH => {
                                itemColH.style.height = `${maxHeight}px`;
                            });
                        }
                    });
            });
        });
    });
}