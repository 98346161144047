import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { isArray } from "lodash";

import { getProducts } from "store/actions/products";
import { addOrderItem, addCutlery } from "store/actions/order";
import { getSettingsProductCategories } from "store/actions/settings";

import { ClearButton } from "components/ui-kit/buttons/ClearButton";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInputSearch } from "components/ui-kit/inputs/TextInputSearch";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import SaveClose from "@mui/icons-material/Close";
import { searchPriceTypeDelivery } from "utils/helpers/price";

export const TabProducts = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useRefWrapper = useRef(undefined);
  const useIdSetTimeout = useRef(undefined);
  const [activeCategory, setCategory] = useState(null);
  const [textSearch, setTextSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [fetching, setFetching] = useState(false);

  const dataOrder = useSelector((store) => store.order.data);
  const categories = useSelector((store) => store.settings.productCategories);
  const products = useSelector((store) => store.products.list);
  const meta = useSelector((store) => store.products.meta);
  const city = useSelector((store) => store.order.data.address.city_sync_id);
  const restaurant = useSelector((store) => store.order.data.restaurant);
  const isLoad = useSelector((store) => store.view.isLoadModalProduct);
  const typeListDeliver = useSelector((store) => store.settings.typesOfDelivery.list);


  const handleAddProduct = async (data, price) => {
    const product = {
      id: data.id,
      article: data.article,
      description: data.description_ua,
      title: data.title_ua,
      weight: data.weight,
      weight_type: data.weight_type,
      image: data.image,
      prices: data.prices,
      price: parseInt(price),
      addition_groups: data.addition_groups,
      cutlery_sets: data.category.cutlery_sets,
    };

    await dispatch(addOrderItem(product));

    if (data?.category) {
      if (isArray(data.category?.cutlery_sets) && data.category.cutlery_sets.length > 0) {
        let cutleryObj = [];

        for (let i = 0; i < data.category.cutlery_sets.length; i++) {
          const itemAsunk = async () => {
            let cutleryProduct = data.category.cutlery_sets[i];
            let price = cutleryProduct?.price ? +cutleryProduct?.price > 0 ? +cutleryProduct?.price : null : null;

            cutleryObj = {
              cutlery_set_id: cutleryProduct.id,
              name: cutleryProduct.name,
              code: cutleryProduct.code,
              status: 1,
              quantity: 1,
              price,
            };

            await dispatch(addCutlery(cutleryObj));
          };

          itemAsunk();
        }
      }
    }
  };

  const handlerChangeSearch = (value) => {
    setTextSearch(value);

    if (value.length == 0) {
      handleClearSearch();
    }
  }

  const handleClearSearch = () => {
    setTextSearch('');
  }

  const renderCategories = () => {
    return categories.map((item, index) => {
      return (
        <ClearButton
          key={index}
          isActive={item.sync_id === activeCategory}
          onClick={() => { handleClickCategory(item.sync_id) }}
          title={item.name}
          fontSize="12px"
          disabled={isLoad}
          isTab={true}
        />
      );
    });
  };

  const renderProducts = () => {
    return products.map((item, index) => {
      const element = item.prices.find((el) => el.city_sync_id === city);
      let price = 0;
      let delivery_prices = element?.delivery_type_prices;

      price = searchPriceTypeDelivery(dataOrder, delivery_prices, element, typeListDeliver);

      return (
        <ProdcutsItem
          key={index}
          onClick={() => handleAddProduct(item, price)}
          disabled={isLoad}
        >
          <ProductImg src={item.image} alt={item.title_ua} />
          <ItemsRows>
            <DivRowProd>{item.title_ua} <div>{item.restaurant}</div></DivRowProd>
            <div>{price} грн.</div>
          </ItemsRows>
        </ProdcutsItem>
      );
    });
  };

  const handleClickCategory = async (cat) => {
    setCurrentPage(1);

    let params = {
      restaurant: restaurant,
      page: 1,
    };

    handleClearSearch();
    setCategory(cat);

    !!cat && (params.category_sync_id = cat);

    await dispatch(getProducts(params, setFetching, setCurrentPage, true));
  }

  const handleScroll = async (e) => {
    if (e.target.scrollHeight - (e.target.offsetHeight + e.target.scrollTop) < 100) {
      setFetching(true);
    }
  }

  useEffect(async () => {
    if (fetching && meta.last_page > currentPage) {
      let params = {
        restaurant: restaurant,
        page: currentPage + 1,
      };

      !!activeCategory && (params.category_sync_id = activeCategory);
      !!textSearch.length && (params.search = textSearch);

      await dispatch(getProducts(params, setFetching, setCurrentPage));
    }
  }, [fetching]);

  useEffect(() => {
    setCurrentPage(1);

    if (textSearch.length > 2) {
      let params = {
        restaurant: restaurant,
        page: 1,
      };

      params.search = textSearch;
      setCategory(null);

      if (useIdSetTimeout.current) {
        clearTimeout(useIdSetTimeout.current);
      }

      useIdSetTimeout.current = setTimeout(async () => {
        await dispatch(getProducts(params, setFetching, setCurrentPage, true));
        clearTimeout(useIdSetTimeout.current);
      }, 700);
    } else if (textSearch.length == 0) {
      handleClickCategory(null);
    }
  }, [textSearch]);

  useEffect(() => {
    dispatch(getSettingsProductCategories({ status: 1, restaurant }));

    !!useRefWrapper.current && useRefWrapper.current.addEventListener('scroll', handleScroll);

    return () => {
      !!useRefWrapper.current && useRefWrapper.current.addEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <Wrapper
      theme={theme}
      ref={useRefWrapper}
    >
      <CategoriesList>
        <ClearButton
          isActive={activeCategory === null}
          onClick={() => { handleClickCategory(null); }}
          title='Всі'
          disabled={isLoad}
        />

        {isArray(categories) && (categories.length > 0) && renderCategories()}

        <WrSearch>
          <TextInputSearch
            title='Пошук по всім категоріям'
            type='text'
            width="calc(100% - 50px)"
            isSearch="true"
            onChange={(e) => handlerChangeSearch(e.target.value)}
            value={textSearch}
          />
          <BtnCloseSearch>
            <ButtonIcon
              onClick={handleClearSearch}
              icon={<SaveClose />}
              disabled={textSearch.length == 0 && true}
            />
          </BtnCloseSearch>
        </WrSearch>
      </CategoriesList>

      {
        isLoad && (
          <WrLoade>
            <LoadingCircl mins={true} />
          </WrLoade>
        )
      }

      <ProductsList>{products?.length > 0 && renderProducts()} </ProductsList>

      {
        isLoad && (products?.length > 15) && (
          <WrLoad>
            <LoadingCircl mins={true} />
          </WrLoad>
        )
      }

    </Wrapper>
  );
};

const ItemsRows = styled.div`
  width: calc(100% - 60px);
`;

const DivRowProd = styled.div`
  display: flex;
  div {
    margin-left: auto;
    padding-left: 10px;
    font-size: 12px;
    color: #EDA240;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  transform-origin: right top;
  height: 100vh;
  top: 0;
  right: 460px;
  width: 460px;
  padding: 10px;
  background-color: ${(p) => p.theme.background};
  overflow-y: scroll;
  overflow-x: hidden;
`;

const BtnCloseSearch = styled.div`
  margin-left: 10px;
  margin-bottom: 3px;
  button {
    padding-top: 9px;
    padding-bottom: 9px;
    span {
      margin: 0;
    }
  }
;`

const WrSearch = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

const CategoriesList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ProductsList = styled.ul``;

const ProdcutsItem = styled.li`
  display: flex;
  margin: 10px 0;
  padding: 7px;
  background-color: ${(p) => p.theme.backgroundLight};
  border: ${(p) => p.theme.inputBorder};
  border-radius: 10px;
  color: ${(p) => p.theme.secondaryColor};
  pointer-events: ${(p) => p.disabled ? "none" : "all" };

  &:hover {
    cursor: pointer;
    border: 1px solid ${(p) => p.theme.lightAccentColor};
  }
`;

const ProductImg = styled.img`
  width: 50px;
  height: 100%;
  margin-right: 10px;
  border-radius: 10px;
`;

const WrLoad = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const WrLoade = styled.div`
  margin-top: 10px;
  width: 100%;
`;
