import { isArray } from "lodash";
import React from "react";
import styled, { useTheme } from "styled-components";

const TableCities = ({
    scheme,
    data,
    onClick,
    isStictyhead = false,
}) => {
    const theme = useTheme();

    const renderHeader = () => {
        const HTMLelement = scheme.map((item, index) => {
            const { title, width } = item;
            return (
                <HeadCell key={index} style={{ width }}>
                    {title}
                </HeadCell>
            );
        });

        return <HeadRow>{HTMLelement}</HeadRow>;
    };

    const renderBody = () => {
        const renderCells = (item) =>
            scheme.map((cell, index) => {
                if (cell.type === "list")
                    return (
                        <Cell key={index}>{renderList(item[cell.key], cell.items)}</Cell>
                    );
                else if (cell.type === "obj") {
                    return <Cell key={index}>{item[cell.key]?.[cell?.item]}</Cell>;
                }
                else if (cell.type === "img") {
                    return <Cell key={index}>
                        <IMG src={item[cell.key]} />
                    </Cell>;
                }
                else if (item[cell.key] || item[cell.key] == 0) {
                    let text = item[cell.key];

                    if (cell.key == 'status') {
                        text = text ? "Актив" : "Не актив.";
                    }

                    return <Cell key={index}>{text}</Cell>;
                }

                else return <Cell key={index}></Cell>;
            });

        const HTMLelement = data.map((item, index) => {
            return (
                <>
                    <Row key={item.id} onClick={onClick ? () => onClick(item) : null}>
                        {renderCells(item)}
                    </Row>

                    {
                        isArray(item?.settlements) && (
                            item?.settlements.length > 0 && (
                                item.settlements.map((itemSetl) => (
                                    <Row key={(itemSetl.id)} isColor={true} onClick={onClick ? () => onClick(itemSetl) : null}>
                                        {renderCells(itemSetl)}
                                    </Row>
                                ))
                            )
                        )
                    }
                </>
            );
        });

        return HTMLelement;
    };

    const renderList = (list, items) => {
        const HTMLelement = list.map((item, index) => {
            return (
                <Item key={index}>
                    {item[items[0]]} {item[items[1]]}
                </Item>
            );
        });
        return <List>{HTMLelement}</List>;
    };

    return (
        <>
            {!!data && data.length ? (
                <Wrapper theme={theme}>
                    <Thead isStictyhead={isStictyhead}>{renderHeader()}</Thead>
                    <Tbody>{renderBody()}</Tbody>
                </Wrapper>
            ) : null}
        </>
    );
};

const Wrapper = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead`
    position: ${(p) => p.isStictyhead ? "sticky" : "inherit"};
    top: 49px;
`;

const Tbody = styled.tbody``;

const HeadRow = styled.tr``;

const Row = styled.tr`
background-color: ${(p) => p.isColor ? "#dddddd" : "#FAFAFA"};

  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.accentColor};
    background-color: ${(p) => p.theme.maskColor};
  }
`;

const HeadCell = styled.th`
  padding: 3px;
  background-color: ${(p) => p.theme.lightAccentColor};
  border: 1px solid ${(p) => p.theme.lightAccentColor};
  color: ${(p) => p.theme.secondaryColor};
  font-weight: 400;
`;

const Cell = styled.td`
  padding: 5px;
  border: 1px solid ${(p) => p.theme.maskColor};
`;

const List = styled.ul``;

const Item = styled.li``;

const IMG = styled.img`
  max-height: 40px;
`;

export default React.memo(TableCities);
