import { isArray } from "lodash";

import { defaultPriceActionsOrder } from "constants/price";
import { searchPriceTypeDelivery } from "./price";

export const searchPaymentCodeAndReturn = (idPayment, list, sum = 0) => {
    let pay = list.find(el => (el.id == idPayment));

    if (pay?.code) {
        return {
            payment_type: (pay.code == 'kurjerom') ? 'cash' : pay.code, // cash - якщо обрано кур'єр
            sum: sum,
            free: 0,
        }
    }
}

// проверка на наличия оплаты наличкой или бонусми
export const sumBonusAndOnline = (array, arrayDiscoumt) => {
    let sum = 0;

    array?.find((item) => {
        if ((item.payment_type) == "bonus" || (item.payment_type == "rucna-zniska")) {
            sum += +item?.sum;
        }
    });

    if (isArray(arrayDiscoumt)) {
        arrayDiscoumt?.find((item) => {
            if (item?.ignore != 1)
                sum += +item?.sum;
        });
    }

    return sum;
}

export const revealBonuses = (balances, restaurant, allSum) => {
    let kitchen = balances.find((itemBonuses) => itemBonuses.restaurant === restaurant);

    if (kitchen) {
        let valueBodusOfKitchen = kitchen.value;

        if (valueBodusOfKitchen > allSum) {
            return allSum;
        } else {
            return valueBodusOfKitchen;
        }
    }

    return 0;
}

export const sumCutlery = (cutlerus) => {
    let sum = 0;

    if (isArray(cutlerus)) {
        for (let i = 0; i < cutlerus.length; i++) {
            if (cutlerus[i].status == 1) {
                if (!!cutlerus[i].price) {
                    sum += (+cutlerus[i].price * cutlerus[i].quantity);
                }
            }
        }
    }

    return sum;
}

export const returnBonus = (payments) => {
    let bonus = undefined;

    if (isArray(payments)) {
        for (let i = 0; i < payments.length; i++) {
            if ((payments[i].payment_type === "bonus") || (payments[i].payment_type === "rucna-zniska")) {
                bonus = payments[i];
            }
        }
    }

    return bonus;
}

// Сплачено
export const calculateSumPayments = (payments) => {
    return payments.reduce((a, b) => {
        if ((b.payment_type != "bonus") && (b.payment_type != "rucna-zniska")) {
            let bSum = b.sum ? +b.sum : 0;
            return +a + bSum;
        }

        return a;
    }, 0);
};

// до оплаты рахується всі додатки, всі позиції
export const calculateSumItems = (payments, payment = null, items, city, data, typeListDeliver, isBonus = true) => {
    // віднімаємо бонуси і ручну знишку
    let bonusAndOnline = sumBonusAndOnline(payments, data.discounts);
    let priceCutlery = sumCutlery(data?.cutlery_sets);
    let priceAndBonus = 0;


    items.map(item => {
        const element = item.prices ? item.prices.find((el) => el.city_sync_id === city) : item;

        let price = element?.price || 0;

        let quantity = item.quantity;
        let sumItem = 0;
        let subDop = 0;

        price = searchPriceTypeDelivery(data, element?.delivery_type_prices, element, typeListDeliver);

        if (quantity > 1) {
            if (item.stock >= 1) {
                sumItem = (+price * (quantity - item.stock)) + (defaultPriceActionsOrder * item.stock);
            } else {
                sumItem = price * quantity;
            }
        } else {
            sumItem = price * quantity;
        }

        if (item.additions) {
            if (item.additions.length > 0) {

                item.additions.map((dop) => {
                    subDop += (dop.price * dop.quantity) * quantity;
                });
            }
        }

        priceAndBonus += (sumItem + subDop);
    });

    priceAndBonus += priceCutlery;

    // бонуси віднімати більше не потрібно
    if (isBonus)
        return priceAndBonus - bonusAndOnline;

    return priceAndBonus;
};

export const paymentAvailablFunList = (paymentAvailable = [], paymentsOrder = []) => {
    let newArr = [...paymentAvailable];

    if (isArray(paymentAvailable) && isArray(paymentsOrder)) {
        if (paymentsOrder.length == 0) {
            return paymentAvailable;
        } else {
            paymentsOrder.map((el) => {
                newArr.map((itemPay, index) => {
                    if (el.payment_type == itemPay.code) {
                        newArr.splice(index, 1);
                    }
                })
            });
        }
    }

    return newArr;
}