import React from "react";
import styled, { useTheme } from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

import { Header } from "./Header";
import Table from "components/ui-kit/Table";
import { tableClietns } from "constants/tables";
import Pagination from "components/ui-kit/Pagination";
import ModalClients from "components/modals/ModalClients";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import ModalClientAdd from "components/modals/ModalClientAdd";
import ModalFilterClients from "components/modals/ModalFilterClients";

import { getData } from "utils/helpers/localStorage";

const keyLocalStorageFilter = 'dataFilterClients';

const Clients = ({
    //state
    clients,
    view,
    //redux
    updateClientSearchForm,
    getClientsSearch,
    openModalClients,
    getClientExtensiveAndOrder,
    openModalClientsAdd,
    openModalFilter,
}) => {
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();

    const getDataLocalParams = () => {
        let par = {};
        let dataFilterLocalStorage = getData(keyLocalStorageFilter);

        if (dataFilterLocalStorage?.black_list) {
            par = dataFilterLocalStorage;
        }

        return par;
    }

    const handleBtn = () => {
        getClientsSearch((location?.query?.page || 1), getDataLocalParams());
    }

    const getClientsByFilter = (page = 1, params) => {
        getClientsSearch(page, params);
    }

    const handlerOpenClient = ({ id }) => {
        openModalClients();
        getClientExtensiveAndOrder(id, true);
    }

    const handlerOpenModalAdd = () => {
        openModalClientsAdd();
    }

    // pagination
    const setPage = (page) => {
        history.push(`?page=${page}`);
        getClientsSearch(page, getDataLocalParams());
    }

    const handleShowFilter = () => {
        openModalFilter();
    }

    return (
        <Wrapper theme={theme}>
            <Header
                updateClientSearchForm={updateClientSearchForm}
                searchFormValie={clients.searchForm}
                handleІSybmit={handleBtn}
                handlerOpenModalAdd={handlerOpenModalAdd}
                handleShowFilter={handleShowFilter}
                isAtiveFilterBtn={!!getData(keyLocalStorageFilter)}
            />

            <ViewRow>
                {
                    !view?.isLoadPage ? (
                        clients?.searchClients.data.length > 0 ? (
                            <Table
                                onClick={handlerOpenClient}
                                data={clients?.searchClients.data}
                                scheme={tableClietns}
                                isKeyRed="blocking_reasons"
                                isStictyhead={true}
                            />
                        ) : (
                            <TextNone>По данному запросу не чого не знайдено</TextNone>
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </ViewRow>

            {
                clients?.searchClients.data.length > 0 && (
                    <Pagination
                        onClick={setPage}
                        currentPage={clients.searchClients.meta.current_page}
                        lastPage={clients.searchClients.meta.last_page}
                    />
                )
            }

            <ModalClients getClientsSea={handleBtn} />
            <ModalClientAdd getClientsSea={handleBtn}/>
            <ModalFilterClients keyNameLocal={keyLocalStorageFilter} getClients={getClientsByFilter} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
`;

const ViewRow = styled.div`
    margin-top: 24px;
`;

const TextNone = styled.div`
    text-align: center;
    color: #9D9D9D;
`;

export default React.memo(Clients);