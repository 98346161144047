import styled, { useTheme } from "styled-components";

export const HeadOrder = () => {
    const theme = useTheme();

    return (
        <Row theme={theme}>
            <Item width={7}>Таймер</Item>
            <Item width={5}>№</Item>
            <Item width={6}>Рест</Item>
            <Item width={13}>Ім'я</Item>
            <Item width={12}>Телефон</Item>
            <Item width={18}>Адрес</Item>
            <Item width={13}>Оператор</Item>
            <Item width={12}>Тип оплати</Item>
            <Item width={7}>Знижка</Item>
            <Item width={7}>До оплати</Item>
        </Row>
    )
}

const Item = styled.div`
    width: ${(p) => p.width}%; 
    font-size: 14px;
    color: ${(p) => p.theme.color7a};
    padding: 5px 5px 5px 0;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(p) => p.theme.colorED};
    padding: 0 0 13px 0;
`;

const TextCenter = styled.div`
    text-align: center;
`;