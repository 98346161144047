/*  camelcase */
import { connect } from "react-redux";

import * as notificationsActions from "store/actions/notifications";

import { default as Notifications } from "./Notifications";

function mapStateToProps(state) {
    return {
        messages: state.notifications.messages
    };
}

const mapDispatchToProps = {
    ...notificationsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
