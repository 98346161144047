import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { isArray } from "lodash";

import { resetOrder } from "store/actions/order";
import { TabDelivery } from "./Tabs/TabDelivery";
import { TabOrder } from "./Tabs/TabOrder";
import { TabPayments } from "./Tabs/TabPayments";
import { TabHistory } from "./Tabs/TabHistory";
import { TabReject } from "./Tabs/TabReject";
import { LoadingModal } from "../../loadingOrder/loading";
import ModalReason from "../ModalReason/ModalReason";
import ModalDelete from "../ModalDelete";
import OrderAll from "./orderAll";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import ModalListOfCouriers from "components/modals/ModalListOfCouriers";

import { calculateSumItems, calculateSumPayments, returnBonus, sumBonusAndOnline } from "utils/helpers/payment";
import { isActiveTabPosition, isActiveTabPayment } from "utils/helpers/isActivesTablesOrder";

import SaveIcon from "@mui/icons-material/Save";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import TelegramIcon from '@mui/icons-material/Telegram';
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const EditorCallcenter = ({
  isKitchen = false,
  isOpen,
  order,
  reason,
  closeEditorCallcenter,
  loadingsModal,
  getClientsExtensive,
  updateModalCourirers,
  getCouriers,
  isAdmin = false,
  promotions,
  isOpenModalReason,
  isLoadModalReason,
  kitchens,
  payment,
  typeListDeliver,
  handlePrint = () => { },
  updateFormReason = () => { },
  updateFormReasonStatus = () => { },
  createReason = () => { },
  updateAdressServer = () => { },
  getCallcenterOrderItem = () => { },
  SendAnOrderByTelegram = () => { },
  cleanFormReason = () => { },
  getReasonInformation = () => { },
  orderCreate,
  orderUpdate,
  getOrderPrint,
  iskeyEdit = false,
  isEditDeliver = true,
  isMap = true,
  isDeliveryOnlyPicup = false,
  reasonData,
}) => {
  if (!isOpen) {
    return <></>
  }

  const dispatch = useDispatch();
  const theme = useTheme();
  const { path } = useRouteMatch();
  const isEdit = !((path.includes('kitchen') || path.includes('client-orders')) && iskeyEdit);

  const [tab, setTab] = useState(1);
  const [isOpenModalSaveEditAddress, setIsOpenModalSaveEditAddress] = useState(false);
  const [isLoadModalSaveEditAddress, setIsLoadModalSaveEditAddress] = useState(false);
  const [isOpenOrderAll, setIsOpenOrderAll] = useState(false);
  const [isLoadBtnSendTelegram, setIsLoadBtnTelegram] = useState(false);
  const [activeCourier, setActiveCourier] = useState(null);

  const isSelfPickup = order?.pickup == 1;

  let textBtnSubmit = "Зберегти";

  (order.status === "cooking") && (textBtnSubmit = "Змінити");

  let isPaymentOk = false;

  if (order.payments.find(el => el.free == 1)) {
    isPaymentOk = true;
  } else {
    let calAllSum = calculateSumItems(order.payments, payment, order.items, order.address.city_sync_id, order, typeListDeliver, false);
    let calPayments = calculateSumPayments(order.payments, payment);
    let bonusAndDiscount = sumBonusAndOnline(order.payments, order.discounts);
    let paymsAndBonusAndDis = calPayments + bonusAndDiscount;

    isPaymentOk = (paymsAndBonusAndDis || (calPayments > 0)) && ((calAllSum <= calPayments) || (paymsAndBonusAndDis >= calAllSum));
  }

  const handleSubmit = () => {
    if (order.status === "draft" || order.id === "") {
      orderCreate(order, isKitchen, false, handlePrint);
    } else if (order.status == "pending") {
      orderUpdate(order, "cooking", isKitchen);
    } else {
      orderUpdate(order, "cooking", isKitchen);
    }
  };

  const handleCloseOrder = () => {
    if (!!order?.id) {
      updateFormReasonStatus("isOpenModalReason", true);
      getReasonInformation();
    } else {
      handleCloseWindow();
    }
  };

  const handleCloseModal = () => {
    updateFormReasonStatus("isOpenModalReason", false);
  };

  const handleReject = () => {
    createReason(isKitchen);
  }

  const handleCloseWindow = () => {
    dispatch(resetOrder());
    closeEditorCallcenter();
    closeOrderAll();
  };

  const handleSubmitOpenModalCouriers = () => {
    getCouriers();
    updateModalCourirers('isOpen', true);
  }

  const handleSubmitPreparing = () => {
    orderUpdate(order, "preparing", isKitchen);
  }

  const handleSubmitForDelivery = () => {
    orderUpdate(order, "for_delivery", isKitchen);
  }

  const handleSubmitCompleted = () => {
    orderUpdate(order, "closed", isKitchen);
  }

  const openAndCloseModalSaveAddress = () => {
    setIsOpenModalSaveEditAddress(prev => !prev);
  }

  const handlerupdateAdressServer = () => {
    updateAdressServer(setIsOpenModalSaveEditAddress, setIsLoadModalSaveEditAddress, handleCloseWindow);
  }

  const handlePrints = async () => {
    await getOrderPrint();
    await handlePrint();
  }

  useEffect(() => {
    setTab(isAdmin ? ((order.status === "rejected") ? 5 : 2) : ((order.status === "rejected") ? 5 : 1));
  }, [order.status]);

  useEffect(() => {
    if (tab === 3) {
      order.client.id && getClientsExtensive(order.client.id);
    }
  }, [tab]);

  useEffect(() => {
    cleanFormReason();
  }, [order.id]);

  const renderTabList = () => {
    return (
      isAdmin ? (
        <TabList>
          <TabItem>
            <TabButton isActive={tab === 2} onClick={() => setTab(2)}>
              Позиції
            </TabButton>
          </TabItem>
          <TabItem>
            <TabButton isActive={tab === 1} onClick={() => setTab(1)}>
              Доставка
            </TabButton>
          </TabItem>
          <TabItem>
            <TabButton isActive={tab === 3} onClick={() => setTab(3)}>
              Оплати
            </TabButton>
          </TabItem>
        </TabList>
      ) : (
        <TabList>
          {
            order.status == "rejected" && (
              <TabItem>
                <TabButton isActive={tab === 5} onClick={() => setTab(5)}>
                  Відміна
                </TabButton>
              </TabItem>
            )
          }
          <TabItem>
            <TabButton isActive={tab === 1} onClick={() => setTab(1)}>
              Доставка
            </TabButton>
          </TabItem>
          <TabItem>
            <TabButton disabled={isActiveTabPosition(order, isSelfPickup)} isActive={tab === 2} onClick={() => setTab(2)}>
              Позиції
            </TabButton>
          </TabItem>
          <TabItem>
            <TabButton disabled={isActiveTabPayment(order)} isActive={tab === 3} onClick={() => setTab(3)}>
              Оплати
            </TabButton>
          </TabItem>
          {(order.id > 0) && (
            <TabItem>
              <TabButton isActive={tab === 4} onClick={() => setTab(4)}>
                Статус
              </TabButton>
            </TabItem>
          )
          }
        </TabList>
      )
    );
  };

  const openOrderAll = () => {
    setIsOpenOrderAll(true);
  }

  const closeOrderAll = () => {
    setIsOpenOrderAll(false);
  }

  const handlerGetOneOrder = async (id) => {
    getCallcenterOrderItem(id, { isNew: true });
  }

  return (
    <>
      {
        isOpen && (
          <BgModal />
        )
      }

      <Wrapper theme={theme} isOpen={isOpen}>
        <Header>
          <div style={{ color: "#fff" }}>Замовлення №{order?.id}</div>

          <CloseButton onClick={handleCloseWindow}>
            <CloseIcon style={{ fontSize: 36 }} />
          </CloseButton>
        </Header>

        {
          !isAdmin && isArray(promotions) && (tab === 2) && promotions.length > 0 && (
            <ActionsFixed>
              <span>* Доступні акції:</span>
              {
                promotions.map((item, index) => {
                  let name = item.name;

                  if ((promotions.length - 1) !== index) {
                    name += ", ";
                  }

                  return name;
                })
              }
            </ActionsFixed>
          )
        }

        {renderTabList()}

        <Block>
          {
            (tab === 2) && <TabOrder key={'2'} isEdit={isEdit} isAdmin={isAdmin} />
          }

          <div style={{ display: `${(tab === 3) ? "block" : "none"}` }}>
            <TabPayments key={'3'} isEdit={isEdit} isPaymentOk={isPaymentOk} />
          </div>

          {
            (tab === 4) && <TabHistory key={'4'} />
          }

          {
            (tab === 5) && <TabReject key={'5'} rejected={order.reason_rejected} />
          }

          {
            (tab === 1) && <TabDelivery
              key={'1'}
              isEdit={isEdit}
              isKitchen={isKitchen}
              isEditDeliver={isEditDeliver}
              openAndCloseModalSaveAddress={openAndCloseModalSaveAddress}
              openOrderAll={openOrderAll}
              isOpenOrder={isOpenOrderAll}
              isMap={isMap}
              iskeyEdit={iskeyEdit}
              isSelfPickup={isSelfPickup}
              isDeliveryOnlyPicup={isDeliveryOnlyPicup}
            />
          }
        </Block>

        {
          (order.status != "rejected") && (
            <Menu>
              {
                isEdit ? (
                  <>
                    {
                      (order.status === "draft") && isPaymentOk && (
                        <Item>
                          <ButtonIcon
                            onClick={handleSubmit}
                            title={textBtnSubmit}
                            colorBg="green"
                            icon={<SaveIcon />}
                          />
                        </Item>
                      )
                    }
                    {
                      order.status === "new" && (
                        <Item>
                          <ButtonIcon
                            onClick={handleSubmit}
                            title='На кухню'
                            colorBg="green"
                            icon={<ScheduleSendIcon />}
                          />
                        </Item>
                      )
                    }

                    {
                      (order.status === "new" || order.status === "cooking") && (
                        <Item>
                          <ButtonIcon
                            onClick={handleSubmit}
                            title="Зберегти"
                            colorBg="green"
                            icon={<SaveIcon />}
                          />
                        </Item>
                      )
                    }
                    {
                      (order.status !== "draft") && (
                        <Item>
                          <ButtonIcon
                            onClick={() => SendAnOrderByTelegram(setIsLoadBtnTelegram)}
                            colorBg="blue"
                            title='Telegram кухня'
                            label="Відправити в телеграм на кухню"
                            isLoad={isLoadBtnSendTelegram}
                            icon={<TelegramIcon />}
                          />
                        </Item>
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      (order.status == "preparing") && (
                        <Item>
                          <ButtonIcon
                            onClick={handleSubmitOpenModalCouriers}
                            title={activeCourier ? "Змінити кур'єра" : "Обрати кур'єр"}
                            icon={<DeliveryDiningIcon />}
                          />
                        </Item>
                      )
                    }
                    {
                      (order.status === "cooking") && (
                        <Item>
                          <ButtonIcon
                            colorBg="green"
                            onClick={handleSubmitPreparing}
                            title="Упакувати"
                            icon={<ScheduleSendIcon />}
                          />
                        </Item>
                      )
                    }
                    {
                      (order.status === "preparing") && (
                        <Item>
                          <ButtonIcon
                            colorBg="green"
                            onClick={handleSubmitForDelivery}
                            title="Доставити"
                            icon={<ScheduleSendIcon />}
                          />
                        </Item>
                      )
                    }
                    {
                      isAdmin && (
                        <Item>
                          <ButtonIcon
                            colorBg="#3e73aa"
                            onClick={handlePrints}
                            icon={<LocalPrintshopIcon />}
                          />
                        </Item>
                      )
                    }
                  </>
                )
              }

              {
                (order.status === "for_delivery" || order.status === "on_way" || order.status === "delivered" || order.status === "pending") && (
                  <Item>
                    <ButtonIcon
                      colorBg="green"
                      onClick={handleSubmitCompleted}
                      title="Завершити"
                      icon={<ScheduleSendIcon />}
                    />
                  </Item>
                )
              }

              {
                (order.status == "pending") && (
                  <Item>
                    <ButtonIcon
                      onClick={handleSubmit}
                      title="Готувати"
                      colorBg="green"
                      icon={<SaveIcon />}
                    />
                  </Item>
                )
              }

              <Item>
                <ButtonIcon
                  onClick={handleCloseOrder}
                  title='Скасувати'
                  colorBg="red"
                  icon={<CancelIcon />}
                />
              </Item>
            </Menu>
          )
        }
        {
          loadingsModal && (
            <LoadingModal />
          )
        }
        {
          isOpenOrderAll && (
            <OrderAll
              isThereMap={tab > 2}
              closeOrderAll={closeOrderAll}
              handlerGetOneOrder={handlerGetOneOrder}
            />
          )
        }
      </Wrapper>

      <ModalReason
        data={reason}
        dataObj={reasonData}
        isOpen={isOpenModalReason}
        isLoad={isLoadModalReason}
        updateFormReason={updateFormReason}
        handleReject={handleReject}
        handleCloseModal={handleCloseModal}
        order={order}
        kitchens={[...[{ title: '-', code: null, status: 1 }], ...kitchens]}
      />

      <ModalListOfCouriers
        isKitchen={true}
        activeCourier={activeCourier}
        setActiveCourier={setActiveCourier}
      />

      <ModalDelete
        title={'Попередження'}
        text={`Ви впевнені, що хочете змінити адрес ?`}
        isOpen={isOpenModalSaveEditAddress}
        isLoad={isLoadModalSaveEditAddress}
        funDelete={handlerupdateAdressServer}
        updateModalDelete={openAndCloseModalSaveAddress}
      />
    </>
  );
};

const ActionsFixed = styled.div`
  color: #fff;
  width: 100%;
  padding: 0 10px 20px 10px;
  span {
    color: ${(p) => p.theme.accentColor};
    padding-right: 10px;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  right: ${(p) => (p.isOpen ? "0" : "-1000px")};
  top: 0;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  padding-bottom: 30px;
  background-color: ${(p) => p.theme.background};
  transition: 200ms right;
  
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px 20px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const TabList = styled.ul`
  display: flex;
  width: 100%;
  padding-right: 5px;
  border-bottom: 1px solid ${(p) => p.theme.lightAccentColor};
`;

const TabItem = styled.li`
  margin: 0;
`;

const TabButton = styled.button`
  padding: 8px 15px;
  background-color: ${(p) =>
    p.isActive ? p.theme.backgroundActive : "transparent"};
  border-bottom: 4px solid
    ${(p) => (p.isActive ? p.theme.accentColor : "transparent")};
  border-radius: 10px 10px 0 0;
  color: ${(p) => (p.isActive ? p.theme.accentColor : p.theme.secondaryColor)};
  font-family: Rubik, sans-serif;
  font-size: 16px;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;  
  &:disabled {
    opacity: 0.6;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
    color: ${(p) => p.theme.accentColor};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  margin-bottom: 46px;
  flex: 1 1 auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Menu = styled.ul`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    padding: 0px 5px;
    min-height: 31px;
  }
`;

const Item = styled.li`
  margin: 0 10px 10px 0;
  &:last-child {
    margin-right: 0;
  }
`;

const BgModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
  z-index: 1000;
`;

export default React.memo(EditorCallcenter);
