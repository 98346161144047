import React from "react";
import styled, { useTheme } from "styled-components";

export const TextInput = ({
  title,
  value,
  onChange,
  onFocus = () => { },
  width,
  isTitle = true,
  isStyle = false,
  disabled = false,
  onKeyDown,
  type="text",
  error = "",
  max = 300,
  min = 0,
  isTextCenter=false,
}) => {
  const theme = useTheme();

  return (
    <Wrapper theme={theme} width={width} disabled={disabled}>
      {
        isTitle && <Title isStyle={isStyle}>{title}</Title>
      }
      <Field
        isStyle={isStyle}
        value={value}
        placeholder={title}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        onKeyDown={onKeyDown}
        type={type}
        error={error.length > 0}
        max={max}
        min={min}
        isTextCenter={isTextCenter}
      />
      {
        error.length > 0 && (
          <Error>{error}</Error>
        )
      }

    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: ${(p) => p.width};
  margin: ${(p) => !p.isStyle ? '3px 0' : 0};
  padding-top: ${(p) => !p.isStyle ? '4px' : 0};
`;

const Error = styled.p`
  color: red;
  padding-top: 4px;
`;

const Title = styled.span`
  margin-left: 4px;
  border-radius: 3px;
  padding-bottom: 5px;
  color: ${(p) => p.isStyle ? "#000" : p.theme.secondaryColor};
  font-size: 14px;
  display: block;
`;

const Field = styled.input`
  width: 100%;
  margin-top: ${(p) => !p.isStyle ? '5px' : 0};
  padding: 8px;
  height: ${(p) => !p.isStyle ? "auto" : "42px"};
  background-color:  ${(p) => !p.isStyle ? p.theme.backgroundLight : "#FFFFFF"};
  border: ${(p) => p.error ? p.theme.inputBorderError : (!p.isStyle ? p.theme.inputBorder : p.theme.inputBorderDE)};
  border-radius: 8px;
  color: ${(p) => !p.isStyle ? p.theme.secondaryColor : "#000"};
  font-size: ${(p) => !p.isStyle ? "18px" : "14px"};
  font-family: Rubik;
  outline: none;
  text-align: ${(p) => p.isTextCenter ? "center" : "left"};
  &:active,
  &:focus {
    border: 1px solid ${(p) => p.theme.accentColor};
  }
  &:disabled {
    pointer-events: none;
    // opacity: 0.6;
    // background: rgba(0, 0, 0, 0.2);
  }
`;
