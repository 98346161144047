import createSession from './createSession';
import orders from './orders';
import product from './product';
import user from './user';
import kitchen from './kitchen';
import restaurant from "./restaurant";
import city from "./city";
import location from "./location";
import suburb from "./suburb";
import role from "./role";
import promotion from "./promotion";
import promocode from "./promocode";
import reason from "./reason";
import resource from "./resource";
import payment from "./payment";
import promotionTypePickup from "./promotionTypePickup";
import pickup from "./pickup";
import typesOfDelivery from "./typesOfDelivery";
import blackReason from "./blackReason";
import complaint from "./complaint";
import complaintProcessingForm from "./complaintProcessingForm";
import telestat from "./telestat";

export default {
    createSession,
    user,
    product,
    orders,
    kitchen,
    restaurant,
    city,
    location,
    suburb,
    role,
    promotion,
    promocode,
    reason,
    resource,
    payment,
    promotionTypePickup,
    pickup,
    typesOfDelivery,
    blackReason,
    complaint,
    complaintProcessingForm,
    telestat,
};
