import React from "react";
import styled, { useTheme } from "styled-components";

const Pagination = ({
  onClick,
  currentPage,
  lastPage = 0,
}) => {
  const theme = useTheme();
  const newArray = [];

  for (let i = 0; i < lastPage; i++)
    newArray.push(i + 1);

  return (
    <Wrapper theme={theme}>
      {
        newArray.map((item, index) => (
          <Button
            onClick={() => onClick(item)}
            key={index}
            isActive={currentPage == item}
          >
            {item}
          </Button>
        ))
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
`;

const Button = styled.button`
  display: flex;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.4s;
  border: 1px solid ${(p)=> p.theme.accentColor};
  margin: 0 5px;
  cursor: pointer;
  color: ${(p)=> p.isActive ? p.theme.secondaryColor : p.theme.accentColor};
  background: ${(p)=> p.isActive ? p.theme.accentColor : p.theme.secondaryColor};
  &:hover {
    background: ${(p) => p.theme.accentColor};
    color: ${(p) => p.theme.secondaryColor};
  }
`;

export default Pagination;
