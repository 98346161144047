export const isMyKitchen = (user) => {
    return ["my"].indexOf(user.users_by_kitchen) != -1;
}

export const isMyUsersRole = (user) => {
    return ["my"].indexOf(user.available_users) != -1;
}

// -----

export const isEditCity = (user) => {
    return ["own", "certain", "none"].indexOf(user.kitchens_statistic_status) != -1;
}

export const isEditOwn = (user) => {
    return ["own"].indexOf(user.kitchens_statistic_status) != -1;
}

export const isEditCertain = (user) => {
    return ["certain"].indexOf(user.kitchens_statistic_status) != -1;
}

export const isEditNone = (user) => {
    return ["none"].indexOf(user.kitchens_statistic_status) == -1;
}

// sales

export const isEditCitySales = (user) => {
    return ["own", "certain", "none"].indexOf(user.kitchens_sales_status) != -1;
}

export const isEditOwnSales = (user) => {
    return ["own"].indexOf(user.kitchens_sales_status) != -1;
}

export const isEditCertainSales = (user) => {
    return ["certain"].indexOf(user.kitchens_sales_status) != -1;
}

export const isEditNoneSales = (user) => {
    return ["none"].indexOf(user.kitchens_sales_status) == -1;
}

export const isEditAllSales = (user) => {
    return ["all"].indexOf(user.kitchens_sales_status) !== -1;
}