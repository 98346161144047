import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as orderActions from "store/actions/order";
import * as ordersActions from "store/actions/orders";

import { default as OrdersList } from "./OrdersListObj";

function mapStateToProps(state) {
  return {
    idOrder: state.order.data.id,
    userData: state.user.user
  };
}

const mapDispatchToProps = {
  ...viewActions,
  ...orderActions,
  ...ordersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
