import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import SaveIcon from "@mui/icons-material/Save";
import styles from "./../scss/settings.module.scss";

import {
    categoriesMappedSelect,
    searchCategoriesId,
    arrayIdCategoriesFromCategor
} from "utils/mappers/categories";

import { validatePickup } from "utils/validation";

import { RADIO_SCHEME } from "constants/schemes";

const PickupPage = ({
    // state
    pickupForm,
    categories,
    isLoadPage,
    // dispatch
    updateFormPickup = () => { },
    saveFormPickup = () => { },
    getFormPickup = () => { },
}) => {
    const [errors, setErrors] = useState({
        sale_client: "",
        sale_employee: "",
    });

    useEffect(() => {
        getFormPickup();
    }, []);

    const onSubmit = () => {
        validatePickup({
            data: {
                ...pickupForm
            },

            onSuccess: async () => {
                saveFormPickup();

                setErrors({
                    sale_client: "",
                    sale_employee: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    return (
        <div className={styles.d_max_1023}>
            {
                isLoadPage ? (
                    <LoadingCircl />
                ) : (
                    <>
                        <div className={`${styles.d_grid} ${styles.col_2}`}>
                            <div className={`${styles.indentation} ${styles.border_right} ${styles.indentation_left}`}>
                                <div className={`${styles.title_row} ${styles.marg_bot}`}>Для клієнтів</div>
                                <TextInput
                                    title="% знижки"
                                    type="number"
                                    max={100}
                                    min={0}
                                    value={pickupForm.sale_client}
                                    isStyle={true}
                                    error={errors.sale_client}
                                    onChange={(e) => updateFormPickup("sale_client", e.target.value)}
                                />
                                <div className={styles.wr_drop}>
                                    <Select
                                        selectOptions={categoriesMappedSelect(categories)}
                                        selectedOption={searchCategoriesId(pickupForm.category_exclusion_client, categories)}
                                        valueKey='category_exclusion_client'
                                        label='Винятки категорій'
                                        isMulti
                                        mode={'primary'}
                                        onChange={({ value }) => updateFormPickup("category_exclusion_client", arrayIdCategoriesFromCategor(value))}
                                    />
                                </div>
                                <div className={styles.wr_drop}>
                                    <Radio
                                        colors={"#000"}
                                        items={RADIO_SCHEME}
                                        label="Активність"
                                        valueKey="status_client"
                                        value={pickupForm.status_client}
                                        onChange={(e) => updateFormPickup("status_client", +e.value.value)}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.indentation} ${styles.indentation_right}`}>
                                <div className={`${styles.title_row} ${styles.marg_bot}`}>Для співробітників</div>
                                <TextInput
                                    title="% знижки"
                                    type="number"
                                    max={100}
                                    min={0}
                                    value={pickupForm.sale_employee}
                                    isStyle={true}
                                    error={errors.sale_employee}
                                    onChange={(e) => updateFormPickup("sale_employee", e.target.value)}
                                />
                                <div className={styles.wr_drop}>
                                    <Select
                                        selectOptions={categoriesMappedSelect(categories)}
                                        selectedOption={searchCategoriesId(pickupForm.category_exclusion_employee, categories)}
                                        valueKey='category_exclusion_employee'
                                        label='Винятки категорій'
                                        isMulti
                                        mode={'primary'}
                                        onChange={({ value }) => updateFormPickup("category_exclusion_employee", arrayIdCategoriesFromCategor(value))}
                                    />
                                </div>
                                <div className={styles.wr_drop}>
                                    <Radio
                                        colors={"#000"}
                                        items={RADIO_SCHEME}
                                        label="Активність"
                                        valueKey="status_employee"
                                        value={pickupForm.status_employee}
                                        onChange={(e) => updateFormPickup("status_employee", +e.value.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.row_btn_tow}>
                            <div className={styles.item_btn}>
                                <ButtonIcon
                                    onClick={onSubmit}
                                    title='Зберегти'
                                    colorBg="green"
                                    icon={<SaveIcon />}
                                />
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}

PickupPage.propTypes = {
    updateFormPickup: PropTypes.func.isRequired,
    saveFormPickup: PropTypes.func.isRequired,
    getFormPickup: PropTypes.func.isRequired,
};

export default PickupPage;