import styled, { useTheme } from "styled-components";

import { InputSearch } from "components/ui-kit/inputs/InputSearch";

import ButtonIcon from "components/ui-kit/ButtonIcon";

import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const Header = ({
    name = '',
    handlerOpenModalAdd = () => { },
}) => {
    const theme = useTheme();
    return (
        <Wr theme={theme}>
            <Left>
                <Label>Всі замовлення: {name}</Label>
            </Left>
            {/* <Right>
                <WrSearch>
                    <InputSearch
                        width={216}
                        placeholder="Search for food, coffe, etc.."
                    />
                </WrSearch>
                <ButtonIcon
                    title='Фільтр'
                    icon={<FilterAltIcon />}
                    colorBg="#9D9D9D"
                />
                <WrBtn>
                    <ButtonIcon
                        onClick={handlerOpenModalAdd}
                        title='Створити'
                        icon={<AddIcon />}
                    />
                </WrBtn>
            </Right> */}
        </Wr>
    )
}

const Wr = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    align-items: center;    
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: ${(p) => p.theme.color25};
`;

const WrSearch = styled.div`
    margin: 0 24px;
`;

const Right = styled.div`
    display: flex;
    align-items: center;
`;

const WrBtn = styled.div`
    margin-left: 24px;
`;

const TextOR = styled.div`
`;