import React, { Component } from "react";
import { Switch } from "react-router";
import { Router, Route } from "react-router-dom";

import "assets/styles/index.scss";

import * as ROUTES from "constants/routes";
import history from "constants/history";
import AppRoute from "routes/AppRoute";

import CommonLayout from "components/layouts/CommonLayout";

import Login from "pages/Login";
import CallcenterPage from "pages/Callcenter";
import CallcenterPageClosed from "pages/Callcenter/Closed";
import CallcenterPageRejected from "pages/Callcenter/Rejected";
import KitchenPage from "pages/Kitchen";
import CookPage from "pages/Cook";
import Courier from "pages/Courier";
import StatisticPage from "pages/Statistic";
import ReportsPage from "pages/Reports";
import SalesPage from "pages/Sales";
import UsersPage from "pages/Users";
import ProductPage from "pages/Product";
import ProductsPage from "pages/Products";
import Clientspage from "pages/Clients";
import ClientOrders from "pages/ClientOrders";
import Settings from "pages/Settings";
import NoMatch from "pages/NoMatch";

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <AppRoute
            path={ROUTES.LOGIN}
            component={Login}
            exact
            isPublic
            checkSession
          />
          <AppRoute
            path={ROUTES.ROOT}
            component={CallcenterPage}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.CALLCENTER}
            component={CallcenterPage}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.CALLCENTER_CLOSED}
            component={CallcenterPageClosed}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.CALLCENTER_REJECTED}
            component={CallcenterPageRejected}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.STATISTICS}
            component={StatisticPage}
            layout={CommonLayout}
          />
          <AppRoute
            path={ROUTES.REPORTS}
            component={ReportsPage}
            layout={CommonLayout}
          />
          <AppRoute
            path={ROUTES.STATISTICS_SALES}
            component={SalesPage}
            layout={CommonLayout}
          />
          <AppRoute
            path={ROUTES.USERS}
            component={UsersPage}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.PRODUCTS}
            component={ProductsPage}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.PRODUCT}
            component={ProductPage}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.KITCHEN}
            component={KitchenPage}
            layout={CommonLayout}
          />
          <AppRoute
            path={ROUTES.COOK}
            component={CookPage}
            layout={CommonLayout}
          />
          <AppRoute
            path={ROUTES.CLIENTS}
            component={Clientspage}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.COURIER}
            component={Courier}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.CLIENT_ORDERS}
            component={ClientOrders}
            layout={CommonLayout}
            exact
          />
          <AppRoute
            path={ROUTES.SETTINGS}
            component={Settings}
            layout={CommonLayout}
          />
          <AppRoute
            path={"*"}
            component={NoMatch}
            layout={CommonLayout}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
