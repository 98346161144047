import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as locatiosPage } from './Locations';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        view,
        locations: settings.locations,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(locatiosPage);
