import { isArray } from "lodash";

export const productMappedSelect = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item.id, label: item.title_ua });
    });

    return mewArray;
};

export const productMappedSelectItem = (arr = [], iskeyValid = null) => {
    let mewArray = [];

    if (isArray(arr)) {
        arr?.map((item) => {
            if (!!iskeyValid) {
                item.product.category[iskeyValid] == 1 && mewArray.push({ value: item.product.id, label: item.product.title_ua });
            } else {
                mewArray.push({ value: item.product.id, label: item.product.title_ua });
            }
        });
    }

    return mewArray;
};

export const productSearchSelectItem = (arr = [], products = []) => {
    let mewArray = [];

    arr?.map((item) => {
        products.map((el) => {
            if (el.product.id === item) {
                mewArray.push({ value: el.product.id, label: el.product.title_ua });
            }
        });
    });

    return mewArray;
}

export const productFilterId = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push(item.value);
    });

    return mewArray;
}

export const productMappedDropdown = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item.id, title: item.title_ua });
    });

    return mewArray;
};

export const searchProductId = (arr = [], products = []) => {
    let mewArray = [];

    arr?.map((item) => {
        products.map((el) => {
            if (el.id == item) {
                mewArray.push({ value: el.id, label: el.title_ua });
            }
        });
    });

    return mewArray;
}



