import React from "react";
import styled, { useTheme } from "styled-components";

export const ClearButton = ({
  title,
  onClick,
  isActive,
  fontSize = "16px",
  isTab = false,
  disabled = false,
}) => {
  const theme = useTheme();

  return (
    <Wrapper
      theme={theme}
      onClick={onClick}
      isActive={isActive}
      isTab={isTab}
      disabled={disabled}
    >
      <Title fontSize={fontSize}>{title}</Title>
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  padding: ${(p) => p.isTab ? "6px 4px" : "8px"} ;
  border-radius: 8px;
  color: ${(p) => p.theme.accentColor};
  background-color: ${(p) =>
    p.isActive ? p.theme.backgroundActive : "transparent"};
  transition: background-color 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const Title = styled.span`
  font-family: Rubik, sans-serif;
  font-size: ${(p) => !!p.fontSize ? p.fontSize : "16px"}
  letter-spacing: 0.01em;
`;
