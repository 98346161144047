import React, { useState } from "react";
import styled, { useTheme } from "styled-components";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { tableClientOrders } from "constants/tables";

const OrdersList = ({
  data,
  openEditorCallcenter,
  getCallcenterOrderItem,
}) => {
  const theme = useTheme();

  const handleOpenEditor = async (item) => {
    await getCallcenterOrderItem(item.id);
    await openEditorCallcenter(item);
  };

  const renderHeader = () => {
    const HTMLelement = tableClientOrders.map((item, index) => {
      const { title, width } = item;
      return (
        <HeadCell key={index} style={{ width }}>
          {title}
        </HeadCell>
      );
    });

    return <HeadRow>{HTMLelement}</HeadRow>;
  };

  const renderBody = () => {
    return data.map((item, index) => {
      let floor = item.address.floor ? `, кв. ${item.address.floor}` : '';

      return (
        <Row key={index} onClick={() => handleOpenEditor(item)}>
          <Cell>{item.id}</Cell>
          <Cell>{item.client.phone}</Cell>
          <Cell>{`${item.address.street}, ${item.address.house_number} ${floor}`}</Cell>
          <Cell>{item.kitchen.title}</Cell>
          <Cell>{item.created_at.split("T")[0]}</Cell>
        </Row>
      );
    });
  };

  return (
    <Wrapper theme={theme}>
      <Table>
        <Thead>{renderHeader()}</Thead>
        <Tbody>{renderBody()}</Tbody>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.section`
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const HeadRow = styled.tr``;

const Row = styled.tr`
  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.accentColor};
    background-color: ${(p) => p.theme.maskColor};
  }
`;

const HeadCell = styled.th`
  padding: 3px;
  background-color: ${(p) => p.theme.lightAccentColor};
  border: 2px solid ${(p) => p.theme.lightAccentColor};
  color: ${(p) => p.theme.secondaryColor};
  font-weight: 400;
`;

const Cell = styled.td`
  padding: 5px;
  border: 2px solid ${(p) => p.theme.maskColor};
`;

export default React.memo(OrdersList);
