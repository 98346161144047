import api from "../../apiSingleton";

import {
    SALES_GET_LIST,
    SALES_UPDATE_FILTER
} from "../constants/sales";

import {
    updadeLoadPageClients,
} from './view';

import {
    addNotification
} from './notifications';

import { getRangeDate } from "../../utils/helpers/date";
import { filterListNames } from "utils/helpers/sales";


export function postCashalotCreate({
    data,
    setIsLoad = () => { },
    handleCleanForm = () => { },
}) {
    return async (dispatch, state) => {
        setIsLoad(true);

        let res = await api.sales.postCashalotCreate(data);

        if (res?.error === 1) {
            await dispatch(addNotification({ war: [res.message] }, 'warn'));
        } else {
            await dispatch(addNotification({ war: [res.message] }));
            handleCleanForm();
        }

        setIsLoad(false);
    }
}

export function getCashalotGetOrderById({
    params,
    setIsLoad = () => { },
    setDataRes = () => { },
}) {
    return async (dispatch, state) => {
        setIsLoad(true);

        let res = await api.sales.cashalotGetOrderById(params);

        if (res.data?.error === 1) {
            await dispatch(addNotification({ war: ['Помилка'] }, 'warn'));
        } else if (!!res.data?.id) {
            setDataRes(res.data);
        }

        setIsLoad(false);
    }
}

export function getSalesList() {
    return async (dispatch, state) => {
        const { sales: {
            filter
        }
        } = state();

        let paramsDate = getRangeDate(filter.date);

        let finalParams = { ...paramsDate };

        !!filter.kitchen_code && (finalParams.kitchen_code = filter.kitchen_code);
        (!!filter.cashier_name && filter.cashier_name != "-") && (finalParams.cashier_name = filter.cashier_name);
        (!!filter.payment_type && filter.payment_type != "-") && (finalParams.payment_type = filterListNames(filter.payment_type));

        dispatch(updadeLoadPageClients(true));

        let res = await api.sales.getSalesList(finalParams);

        if (res?.data?.error) {
            await dispatch(addNotification({ war: ["По запиту нічого не знайдено"] }, 'warn'));
            await dispatch({
                type: SALES_GET_LIST,
                payload: [],
            });
        } else {
            await dispatch({
                type: SALES_GET_LIST,
                payload: res.data,
            });
        }

        dispatch(updadeLoadPageClients(false));
    };
}

let statusRepeat = false;
export function downloadSalesExel(stateLoda = () => { }) {
    return async (dispatch, state) => {
        const { sales: {
            filter
        }
        } = state();

        let paramsDate = getRangeDate(filter.date);

        let finalParams = { ...paramsDate };

        !!filter.kitchen_code && (finalParams.kitchen_code = filter.kitchen_code);
        (!!filter.cashier_name && filter.cashier_name != "-") && (finalParams.cashier_name = filter.cashier_name);
        (!!filter.payment_type && filter.payment_type != "-") && (finalParams.payment_type = filterListNames(filter.payment_type));

        await stateLoda(true);

        const res = await api.sales.downloadSalesExcel(finalParams);

        if (res.status == "Ok") {
            const link = document.createElement('a');

            link.href = res.data.file;
            link.setAttribute(
                'download',
                `statistics.xlsx`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            await stateLoda(false);
        } else {
            if (!statusRepeat) {
                setTimeout(() => {
                    dispatch(downloadExcel());
                    statusRepeat = true;
                }, 2000);
            } else {
                await stateLoda(false);
            }
        }
    }
}

export const updateFilterSales = (item, data) => {
    return async (dispatch) => {
        await dispatch({
            type: SALES_UPDATE_FILTER,
            payload: { item, data }
        });
    }
}

export function postCheckCmc({
    checkId = null,
    setIsLoad = () => { },
    onCloseModalTable = () => { },
}) {
    return async (dispatch, state) => {
        if (!checkId) {
            dispatch(addNotification({ war: ['Чек не вибран'] }, 'warn'));
            return;
        }

        setIsLoad(true);

        let res = await api.sales.postCmc(checkId);

        if (res.data?.error == 1) {
            dispatch(addNotification({ war: ['Помилка'] }, 'warn'));
            setIsLoad(false);
            return;
        }

        if (res.data?.error == 0) {
            setIsLoad
            dispatch(addNotification({ war: [res.data.message] }));
            setIsLoad(false);
            onCloseModalTable();
            return;
        }

        setIsLoad(false);
    }
}

export function getCashalotCheck({
    checkId = null,
    setIsLoad = () => { },
    setPrintCheck = () => { },
    onCloseModalTable = () => { },
}) {
    return async (dispatch, state) => {
        setIsLoad(true);

        let res = await api.sales.getCheckCashalot(checkId);

        if (res?.data.error == 0) {
            setPrintCheck({ show: true, data: res.data.array_check })
            onCloseModalTable();
        }

        if (res?.data.error == 1) {
            dispatch(addNotification({ war: ['Помилка'] }, 'warn'));
        }

        setIsLoad(false);
    }
}

