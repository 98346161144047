import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { isArray } from "lodash";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Table from "components/ui-kit/Table";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import { ReasonEdit } from "./ReasonEdit";
import ModalDelete from "components/modals/ModalDelete";

import AddIcon from "@mui/icons-material/Add";
import styles from "../../scss/settings.module.scss";

import { tableReasons } from "constants/tables";

const Reasons = ({
    // state
    view,
    blackList,
    // dispatch
    updateFormReason,
    getReasonsList,
    getReasonId,
    createReason,
    updateReasonId,
    deleteItemReasonById
}) => {
    const [isEdit, setIsEdit] = useState({
        status: false,
        id: null,
    });

    const [deleteItem, setDeleteItem] = useState({
        isOpen: false,
        id: undefined,
        isLoad: false,
    });

    const {
        reasonsList,
        editFormReason,
    } = blackList;

    useEffect(() => {
        getReasonsList();
    }, []);

    const handlerEdit = async ({ id }) => {
        await setIsEdit({ id, status: true });
        await getReasonId(id);
    }

    const handlerCreate = () => {
        setIsEdit((prev) => ({ ...prev, ...{ status: true } }));
    }

    const handleSubmitClose = () => {
        setIsEdit((prev) => ({ ...prev, ...{ status: false } }));
    }

    const onSubmit = async () => {
        createReason(handleSubmitClose);
    }

    const onSubmitUpdate = () => {
        updateReasonId(handleSubmitClose);
    }

    const handleDelete = async ({ id }) => {
        setDeleteItem(prev => ({
            ...prev,
            isOpen: true,
            id
        }));
    }

    const closeModalDelete = () => {
        setDeleteItem({
            isOpen: false,
            id: undefined,
            isLoad: false,
        });
    }

    const onDeleteItem = () => {
        deleteItemReasonById(deleteItem.id, setDeleteItem);
    }

    return (
        <div className={styles.d_max_480}>
            {
                !isEdit.status && (
                    <div className={styles.wr_head}>
                        <ButtonIcon
                            onClick={handlerCreate}
                            title='Додати'
                            icon={<AddIcon />}
                            positionIcon="left"
                            disabled={isEdit.status}
                        />
                    </div>
                )
            }
            {
                (isEdit.status && !view.isLoadPage) && (
                    <ReasonEdit
                        data={editFormReason}
                        view={view}
                        onSubmit={onSubmit}
                        handleSubmitClose={handleSubmitClose}
                        onSubmitUpdate={onSubmitUpdate}
                        updateFormReason={updateFormReason}
                    />
                )
            }
            <div className={styles.wr_row}>
                {
                    !view?.isLoadPage ? (
                        (isArray(reasonsList) && (reasonsList.length != 0)) ? (
                            <Table
                                onClick={handlerEdit}
                                onDelete={handleDelete}
                                data={reasonsList}
                                scheme={tableReasons}
                                isStictyhead={true}
                            />
                        ) : (
                            <div>Пусто...</div>
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </div>

            <ModalDelete
                title={'Видалити ? '}
                text={`Ви підтверджуєте видалення ?`}
                isOpen={deleteItem.isOpen}
                isLoad={deleteItem.isLoad}
                funDelete={onDeleteItem}
                onCloseModal={closeModalDelete}
            />
        </div>
    )
}

Reasons.propTypes = {
    view: PropTypes.object.isRequired,
    blackList: PropTypes.object.isRequired,
    updateFormReason: PropTypes.func.isRequired,
    getReasonsList: PropTypes.func.isRequired,
    getReasonId: PropTypes.func.isRequired,
    createReason: PropTypes.func.isRequired,
    updateReasonId: PropTypes.func.isRequired,
    deleteItemReasonById: PropTypes.func.isRequired
}

export default Reasons;