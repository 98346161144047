import { useEffect, useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import SaveIcon from "@mui/icons-material/Save";
import styles from "./../scss/settings.module.scss";

import { validateTelestat } from "utils/validation";

const Telestat = ({
    // state
    telestatData,
    isLoadPage,
    // dispatch
    updateFormTelestat = () => { },
    saveTelestat = () => { },
    getFormTelestat = () => { },
}) => {
    const [errors, setErrors] = useState({
        link: "",
        token: "",
    });
    const [loadBtn, setLoadBtn] = useState(false);

    useEffect(() => {
        getFormTelestat();
    }, []);

    const onSubmit = () => {
        validateTelestat({
            data: {
                ...telestatData
            },

            onSuccess: async () => {
                saveTelestat(setLoadBtn);

                setErrors({
                    link: "",
                    token: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    return (
        <div className={styles.d_max_480}>
            {
                isLoadPage ? (
                    <LoadingCircl />
                ) : (
                    <div>
                        <div className={`${styles.indentation} ${styles.indentation_left}`}>
                            <TextInput
                                title="Link"
                                value={telestatData.link}
                                isStyle={true}
                                rror={errors.link}
                                onChange={(e) => updateFormTelestat("link", e.target.value)}
                            />
                             <TextInput
                                title="Link для аудіо"
                                value={telestatData.audio_link}
                                isStyle={true}
                                rror={errors.audio_link}
                                onChange={(e) => updateFormTelestat("audio_link", e.target.value)}
                            />
                            <TextInput
                                title="Токен"
                                value={telestatData.token}
                                isStyle={true}
                                error={errors.token}
                                onChange={(e) => updateFormTelestat("token", e.target.value)}
                            />
                        </div>
                        <div className={styles.row_btn_tow}>
                            <div className={styles.item_btn}>
                                <ButtonIcon
                                    onClick={onSubmit}
                                    title='Зберегти'
                                    colorBg="green"
                                    icon={<SaveIcon />}
                                    isLoad={loadBtn}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Telestat;