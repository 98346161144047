import { connect } from 'react-redux';

import * as settingsMagazineActions from 'store/actions/settignsMagazine';

import { default as PageMagazineComplaints } from './PageMagazineComplaints';

function mapStateToProps(state) {
    const {
        settingsMagazine: {
            complain: {
                list,
                filter,
            }
        },
        view
    } = state;

    return {
        list,
        filter,
        view,
    };
}

const mapDispatchToProps = {
    ...settingsMagazineActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageMagazineComplaints);
