import QRCode from "react-qr-code";

export const CheckCashalot = ({ data, linkQr  =''}) => {
    return (
        <>
            {
                data.map((el, id) => (
                    <div key={id}>{el}</div>
                ))
            }

            <div style={{ height: "auto", margin: "40px auto 0 auto", maxWidth: 180, width: "100%" }}>
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={linkQr}
                    viewBox={`0 0 256 256`}
                />
            </div>
        </>
    )
}