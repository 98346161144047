/*  camelcase */
import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as orderActions from "store/actions/order";
import * as listingsActions from "store/actions/listings";

import { default as CallcenterPage } from "./CallcenterPage";

function mapStateToProps(state) {
  const { user, view } = state;
  const {
    orders,
    searchValue,
    statusSearch,
    loadPage
  } = state.listings;

  return {
    user,
    orders,
    isRedactionOrder: view.isRedactionOrder,
    view,
    searchValue,
    statusSearch,
    loadPage,
    isOpenModal: state.view.modalCallCenter.isOpen,
  };
}

const mapDispatchToProps = {
  ...orderActions,
  ...viewActions,
  ...listingsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallcenterPage);
