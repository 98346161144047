import styles from "./styles.module.scss";

const ProcessDownload = ({ process = 0 }) => {
    return (
        <div className={styles.wr}>
            <div className={styles.row}>
                <div className={styles.process} style={{ width: `${process}%` }}></div>
            </div>
        </div>
    )
}

export default ProcessDownload;