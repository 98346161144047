import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isArray } from 'lodash';

import history from 'constants/history';
import { validateProduct } from 'utils/validation';
import Button from 'components/ui-kit/Button';
import Input from 'components/ui-kit/Input';
import Radio from 'components/ui-kit/Radio';
import Select from 'components/ui-kit/Select';
import Back from "components/ui-kit/Back";
import InputImage from "components/ui-kit/InputImage";
import { LoadingModal } from "components/loadingOrder/loading";
import styles from './ProductPage.module.scss';
import { RADIO_SCHEME } from "constants/schemes";

import {
    categoriesMappedSuncId,
    searchCategoriesSuncId
} from 'utils/mappers/categories';
import {
    restaurantsMappedLabel,
    searchRestaurantByCode
} from 'utils/mappers/restaurants';

function ProductPage({
    // states
    view,
    productTypes,
    restaurants,
    cities,
    updateProduct,
    formEditProduct,
    typesOfDeliveryList,
    // dispatch
    getProductId,
    updateFormProduct,
    updateFormProductTypePrice,
}) {
    const [fileImg, setFileImg] = useState(undefined);
    const [errors, setErrors] = useState({
        title_ua: '',
        description_ua: '',
    });

    useEffect(() => {
        const productId = history.location.pathname.split('/')[2];

        if (productId) {
            getProductId(productId);
        }
    }, []);

    const handleFileChange = (objectFile) => {
        setFileImg(objectFile);
    };

    const backs = () => {
        history.goBack();
    }

    const handleSave = () => {
        const formData = new FormData();
        const product = {
            ...formEditProduct
        };

        if (fileImg) {
            formData.append('image', fileImg, fileImg.name);
        }

        console.log("product: ", product);

        validateProduct({
            data: product,
            onSuccess: async () => {
                
                await updateProduct(product, (fileImg ? formData : undefined), backs);
            },
            onError: validationErrors => setErrors(validationErrors)
        });
    };

    const renderPrices = () => {
        return formEditProduct.prices?.map((item, index) => {
            const city = cities.find(el => el.sync_id == item.city_sync_id);

            const { uuid = 0, name = "", status = 0 } = city;

            if (!!!status)
                return (<></>);

            const { price, price_old, delivery_type_prices } = item;

            return (
                <div
                    key={uuid}
                    className={styles.pricesItem}
                >
                    <p className={styles.city}>{name}</p>

                    <div className={styles.pricesInputs}>
                        <Input
                            className={styles.input}
                            label='Ціни'
                            subLabel='(UAH)'
                            placeholder='Ціна'
                            value={`${price}`}
                            valueKey='price'
                            type='number'
                            onChange={props => updateFormProduct(props)}
                        />

                        <Input
                            className={styles.input}
                            label='Старая ціна'
                            subLabel='(UAH)'
                            placeholder='Старая ціна'
                            value={`${price_old}`}
                            valueKey='price_old'
                            type='number'
                            onChange={props => updateFormProduct(props)}
                        />

                        {
                            isArray(typesOfDeliveryList) && typesOfDeliveryList.map((item, indexPr) => {
                                if (item.code == "default") {
                                    return;
                                }

                                let price = null;

                                if (isArray(delivery_type_prices) && delivery_type_prices.length > 0) {
                                    delivery_type_prices.map((el) => {
                                        if (item.id === el.delivery_type_id) {
                                            price = el.price;
                                        }
                                    });
                                }

                                return (
                                    <Input
                                        key={indexPr}
                                        className={styles.input}
                                        label={`Ціна ${item.name}`}
                                        subLabel='(UAH)'
                                        placeholder='Ціна'
                                        value={price}
                                        valueKey='delivery_type_id'
                                        type='number'
                                        onChange={props => updateFormProductTypePrice(index, item.id, props.value)}
                                    />
                                )
                            })
                        }
                    </div>

                    <div className={styles.pricesRadioButtons}>
                        {index === 0 && (
                            <p className={styles.radioTitle}>
                                Активність
                                <span> (Видимість товару на сайті)</span>
                            </p>
                        )}

                        <div
                            className={
                                `${styles.radioButtonsItem} ${index === 0 && styles.firstRadio}`
                            }
                        >
                            <Radio
                                items={RADIO_SCHEME}
                                valueKey='is_active'
                                value={`${item.is_active}`}
                                onChange={props => updateFormProduct(props)}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={styles.container}>
            <Back />

            <div className={styles.header}>
                <InputImage
                    currentImage={formEditProduct?.image || ''}
                    setFileImage={handleFileChange}
                />

                <div className={styles.wrBtns}>
                    <Button
                        className={styles.buttons}
                        label='Сохранить'
                        onClick={handleSave}
                    />
                </div>
            </div>

            <div className={styles.body}>
                <div>
                    <Input
                        className={styles.input}
                        label='Назва'
                        placeholder='Назва товару'
                        value={formEditProduct?.title_ua || ""}
                        valueKey='title_ua'
                        error={errors.title_ua}
                        onChange={updateFormProduct}
                    />
                    <Select
                        className={styles.input}
                        onChange={(props) => updateFormProduct({ valueKey: props.valueKey, value: { name: props.value.label, sync_id: props.value.value } })}
                        selectOptions={categoriesMappedSuncId(productTypes)}
                        selectedOption={searchCategoriesSuncId((formEditProduct.category?.sync_id || null), productTypes)}
                        valueKey='category'
                        mode='primary'
                        label='Категорія'
                    />
                    <Input
                        className={styles.input}
                        label='Опис товару'
                        placeholder='Опис товару'
                        value={formEditProduct.description_ua || ''}
                        valueKey='description_ua'
                        error={errors.description_ua}
                        onChange={updateFormProduct}
                    />
                    <Input
                        className={styles.input}
                        label='Артикул'
                        subLabel='(Нередаговане поле)'
                        value={formEditProduct.article}
                        readOnly
                    />
                    <Input
                        className={styles.input}
                        label='Вес'
                        subLabel='(Нередаговане поле, Граммы)'
                        value={`${formEditProduct.weight}`}
                        readOnly
                    />
                    <Input
                        className={styles.input}
                        label='Тип веса'
                        subLabel='(Нередаговане поле)'
                        value={formEditProduct.weight_type}
                        readOnly
                    />
                    <Select
                        className={styles.input}
                        onChange={(props) => updateFormProduct({ valueKey: props.valueKey, value: props.value.value })}
                        selectOptions={restaurantsMappedLabel(restaurants)}
                        selectedOption={searchRestaurantByCode(formEditProduct.restaurant, restaurants)}
                        valueKey='restaurant'
                        mode='primary'
                        label='Ресторан'
                    />
                </div>
                <div>
                    {renderPrices()}
                </div>
            </div>
            {
                view.isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }
        </div>
    );
}

ProductPage.propTypes = {
    editedProduct: PropTypes.object,
    productTypes: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
    updateProduct: PropTypes.func.isRequired
};

ProductPage.defaultProps = {
    editedProduct: {}
};

export default ProductPage;
