import { connect } from 'react-redux';

import * as settingsActions from '../../store/actions/settings';
import { default as SettingsPage } from './Settings';

function mapStateToProps(state) {
    const { } = state;

    return {
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
