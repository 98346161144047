import { isArray } from "lodash";
import api from "../../apiSingleton";

import { GET_DAA_PRINT_CHECK } from "../constants/kitchen";

import { addNotification } from './notifications';

export function getDataPrintOrderAllKitchen() {
    return async (dispatch, state) => {
        let { user: { user: { kitchen_code } } } = state();
        const res = await api.kitchen.apiGetDataPrintOrderAllKitchen(kitchen_code);

        if (!res?.error) {
            await dispatch({
                type: GET_DAA_PRINT_CHECK,
                payload: res,
            });
        }

        if (!!res?.error) {
            let orders = (isArray(res?.orders) && res?.orders.join(", "));
            await dispatch(addNotification({ war: [`${res?.text || ''} ${orders}`] }, 'error'));
        }
    };
}


export async function getDailyReportsForKitchen(params, setLoad) {
    setLoad(true);
    const res = await api.kitchen.getDailyReportsForKitchen(params);
    setLoad(false);

    if (res?.status === "Ok")
        return res.data;

    return null;
}