import { isString } from "lodash";

export const hour = (value, current) => {
    if (isString(current)) {
        let arr = current.split(":");
        let st = "00";

        if (arr[1])
            st = arr[1];

        return `${value}:${st}`;
    }

    return `${value}:00`;
}

export const minute = (value, current) => {
    if (isString(current)) {
        let arr = current.split(":");
        let st = "00";

        if (arr[0])
            st = arr[0];

        return `${st}:${value}`;
    }

    return `00:${value}`;
}

export const getHour = (value) => {
    let arr = isString(value) ? value.split(":") : null;
    return arr ? arr[0] : '';
}

export const getMinute = (value) => {
    let arr = isString(value) ? value.split(":") : null;
    return arr ? arr[1] : '';
}