import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as KitchensPage } from './Kitchens';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        cities: [...[{ name_ua: 'Всі', sync_id: "all", status: 1 }], ...settings.cities],
        view,
        kitchens: settings.kitchens,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(KitchensPage);
