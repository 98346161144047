import produce from 'immer';

import {
    GET_MAGAZINE_COMPLAINT,
    UPDATE_FILTER_MAGAZINE_COMPLAINTS
} from "../constants/settignsMagazine";

const initialState = {
    complain: {
        list: [],
        filter: {
            date: [
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                }
            ]
        }
    }
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case UPDATE_FILTER_MAGAZINE_COMPLAINTS: {
            draft.complain.filter[payload.item] = payload.data;
            break;
        }
        case GET_MAGAZINE_COMPLAINT: {
            draft.complain.list = payload;
            break;
        }

        default:
            break;
    }
}, initialState);
