import Base from "./Base.js";

export default class StatisticAPI extends Base {
  getDeliveries(params) {
    return this.apiClient.get("olap/deliveries", params);
  }

  getDistances(params) {
    return this.apiClient.get("olap/distances", params);
  }

  downloadExcel(params) {
    return this.apiClient.get("olap/distances-excel", params);
  }

  statusRepeatSelf(params) {
    return this.apiClient.get("olap/employees-excel", params);
  }

  getOrders(params) {
    return this.apiClient.get("olap/orders", params);
  }

  getSelf(params) {
    return this.apiClient.get("olap/employees", params);
  }

  getRivews(params) {
    return this.apiClient.get("review", params);
  }

  downloadExcelReviews(params) {
    return this.apiClient.get("review-excel", params);
  }

  // complaints
  ComplaintsAll(params) {
    return this.apiClient.get("complaints", params);
  }

  downloadExcelComplaints(params) {
    return this.apiClient.get("complaints-excel", params);
  }

  setFromComplaints(data) {
    return this.apiClient.post("complaints", data);
  }

  setFromComplaintsProcessing(data) {
    return this.apiClient.post("complaints-process", data);
  }

  // end complaints
}