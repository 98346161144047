/*  camelcase */
import api from "../../apiSingleton";

import {
  CLEAR_EDITED_USER,
  CLEAR_USERS,
  UPDATE_FULL_USERS,
  UPDATE_USERS,
  UPDATE_USER_SEARCH_FORM,
  UPDATE_FORM_USER,
  GET_USER,
  UPDATE_FORM_USER_LICO_ID,
} from "../constants/users";

import {
  updadeLoadPageClients,
  updateModalDelete,
  closeAllModals,
  updateLoadModalUser
} from "./view";

import {
  addNotification
} from './notifications';
import { isString } from "lodash";

const STATUSES = {
  Активен: "active",
  Неактивен: "disabled",
};

function getRoleName(roles, role) {
  const { name } = roles.find((el) => el.title === role || el.name === role);

  return name;
}

export function getFullUsers() {
  return async (dispatch) => {
    const { data } = await api.users.get({ per_page: 500 });

    await dispatch({
      type: UPDATE_FULL_USERS,
      payload: { fullUsers: data },
    });
  };
}

export function getUsers(page = 1, finter = {}) {
  return async (dispatch, getState) => {
    const { users: { searchForm } } = getState();
    
    const q = searchForm?.phone.length > 0 ? searchForm?.phone : searchForm?.name;
    let params = { per_page: 30 };

    if (page > 0) {
      params.page = page;
    }

    if (q) {
      params = { ...params, ...{ q } }
    }

    params = { ...params, ...finter };

    await dispatch(updadeLoadPageClients(true));

    const data = await api.users.get(params);

    await dispatch({
      type: UPDATE_USERS,
      payload: { users: { ...data } },
    });

    await dispatch(updadeLoadPageClients(false));
  };
}

export function getUser(id) {
  return async (dispatch) => {
    dispatch(updateLoadModalUser(true));

    const { data } = await api.users.getUser(id);

    dispatch({
      type: GET_USER,
      payload: data,
    });

    dispatch(updateLoadModalUser(false));
  };
}

export function deleteUser(page, filMy = () => { }) {
  return async (dispatch, state) => {
    const { users: {
      editedUser: {
        id
      }
    } } = state();

    await dispatch(updateModalDelete('loadingsModal', true));
    await api.users.delete(id);
    await dispatch(updateModalDelete('isOpen', false));
    await dispatch(updateModalDelete('loadingsModal', false));
    dispatch(closeAllModals());
    await filMy();
  }
}

export function createUser({ user, onSuccess, onError, filMy = () => { } }) {
  return async (dispatch, getState) => {
    const payload = { ...user };
    const { roles } = getState().settings;
    const name = getRoleName(roles, user.role_name);

    delete payload.role_name;

    if (!user.iiko_id) {
      delete payload.iiko_id;
    }
    payload.role_name = name;

    const res = await api.users.create(payload);

    if (res.errors) {
      onError(res.errors);
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      onSuccess();
    }

    await filMy();
  };
}

export function editUser({ user, onSuccess, onError, id, filMy = () => { } }) {
  return async (dispatch, getState) => {
    try {
      const payload = { ...user };
      const { roles } = getState().settings;
      const name = getRoleName(roles, user.role_name);

      delete payload.role_name;

      payload.role_name = name;
      payload.status = STATUSES[payload.status] || user.status;

      const res = await api.users.edit(payload, id);

      if (res.errors) {
        onError(res.errors);
        await dispatch(addNotification(res.errors, 'error'));
      } else {
        onSuccess();
      }

      await filMy();
    } catch (error) {
      console.log("Edit user error", error);
    }
  };
}

export function resetTheAppCourier(setStateLoadBtn) {
  return async (dispatch, state) => {
    const {
      users: {
        editedUser: {
          id
        }
      }
    } = state();

    setStateLoadBtn(true);

    const res = await api.users.resetTheApp(id);

    if (isString(res?.status) && res.status.includes('Failed')) {
      await dispatch(addNotification({ suc: [res.message] }, 'error'));
    } else {
      await dispatch(addNotification({ suc: [res?.message || "Готово"] }));
    }

    setStateLoadBtn(false);
  }
}

export function deleteMultiUsers({ data, setLoadModal, filter, calback }) {
  return async (dispatch, state) => {
    setLoadModal(true);

    const res = await api.users.deleteSelectUsers({ ids: data });

    if (res?.errors) {
      await dispatch(addNotification({ suc: [res.message] }, 'error'));
    }

    if (!res?.errors) {
      await dispatch(addNotification({ suc: ["Видалено"] }));
      dispatch(getUsers(1, filter));
      calback();
    }

    setLoadModal(false);
  }
}

export function deactivateMultiUsers({ data, setLoadModal, filter, calback }) {
  return async (dispatch, state) => {
    setLoadModal(true);

    const res = await api.users.deactivateSelectUsers({ ids: data });

    if (res?.errors) {
      await dispatch(addNotification({ suc: [res.message] }, 'error'));
    }

    if (!res?.errors) {
      await dispatch(addNotification({ suc: ["Деактивовано"] }));
      dispatch(getUsers(1, filter));
      calback();
    }

    setLoadModal(false);
  }
}

export function clearEditedUser() {
  return {
    type: CLEAR_EDITED_USER,
  };
}

export function clearUsers() {
  return { type: CLEAR_USERS };
}

export function updateUserSearchForm(item, data) {
  return {
    type: UPDATE_USER_SEARCH_FORM,
    payload: { item, data },
  };
}

export function updateFormUser(item, data) {
  return {
    type: UPDATE_FORM_USER,
    payload: { item, data },
  };
}

export function updateFormUserLikoId(data) {
  return {
    type: UPDATE_FORM_USER_LICO_ID,
    payload: data,
  };
}