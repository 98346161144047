import { connect } from "react-redux";

import * as viewActions from "store/actions/view";

import { default as ModalFilterClients } from "./ModalFilterClients";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalfiilter.isOpen,
    };
}

const mapDispatchToProps = {
    ...viewActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterClients);
