import { useEffect, useState, useRef } from "react";

import Checkbox from "components/ui-kit/Checkbox";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { isArray } from "lodash";

export const RestorantAndKitchen = ({
    kitchens,
    restaurants,
    filterKitchen,
    filterRestorant,
    filterRestorantAll,
    filterKitchenAll,
    update,
    handlerSubmit,
}) => {
    const refWr = useRef(undefined);
    const [show, setShow] = useState(false);

    const handleOpen = () => {
        setShow(true);
    }

    const handleBtn = () => {
        handlerSubmit();
        setShow(false);
    }

    useEffect(() => {
        const handleClockWr = (e) => {
            if (show) {
                const withinBoundaries = e.composedPath().includes(refWr.current);

                if (!withinBoundaries) {
                    setShow(false);
                }
            }
        }

        !!document.body && document.body.addEventListener('mousedown', handleClockWr);

        return () => {
            !!document.body && document.body.addEventListener('mousedown', handleClockWr);
        }
    }, [show]);

    return (
        <div className="res-kit-wr" ref={refWr}>
            <div className="res-kit-wr__in" onClick={handleOpen}>рухня, кухняб кухня3, кухня4</div>
            {
                !!show && (
                    <div className="res-kit-modal">
                        <div className="res-kit-modal__main">
                            <div className="res-kit-modal__main_flex">
                                <div className="res-kit-modal__l">
                                    <p>Ресторан</p>
                                    <div className="res-kit-modal__w-lir">
                                        <ul className="list-r-k">
                                            <li className="list-r-k__item item_all" >
                                                <Checkbox isStyle={true} isMin={true} title="Всі" checked={filterRestorantAll == 'all'} onChecked={() => update("filterRestorantAll", 'all')} />
                                            </li>
                                            {
                                                isArray(restaurants) && restaurants.map(item => (
                                                    !!item.status && (
                                                        <li className="list-r-k__item" key={item.id}>
                                                            <Checkbox isStyle={true} isMin={true} title={item.name} checked={filterRestorant.includes(item.code)} onChecked={() => update("filterRestorant", item.code, true)} />
                                                        </li>
                                                    )
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="res-kit-modal__r">
                                    <p>Кухня</p>
                                    <div className="res-kit-modal__w-lir">
                                        <ul className="list-r-k">
                                            <li className="list-r-k__item item_all">
                                                <Checkbox isStyle={true} isMin={true} title="Всі" checked={filterKitchenAll == 'all'} onChecked={() => update("filterKitchenAll", 'all')} />
                                            </li>
                                            {
                                                isArray(kitchens) && kitchens.map(item => (
                                                    !!item.status && (
                                                        <li className="list-r-k__item" key={item.id}>
                                                            <Checkbox isStyle={true} isMin={true} title={item.title} checked={filterKitchen.includes(item.code)} onChecked={() => update("filterKitchen", item.code, true)} />
                                                        </li>
                                                    )
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="res-kit-modal__main_bot">
                                <ButtonIcon
                                    onClick={handleBtn}
                                    title="Ок"
                                    isMin={true}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}